/* eslint-disable max-len */
import React, { useEffect, useMemo, useState } from 'react';

import { useSelector, useDispatch } from 'react-redux';

import {
  BackArrow,
  BackButton,
  ButtonName,
  ComponentBox,
  Container,
  Header,
  HeaderBox,
  SubContainer,
  Subtitle,
  SubtitleLink,
  Title,
  InsuranceBanner,
  InsuranceItem,
  WelcomeContainer,
  WelcomeTextContainer,
  WelcomeText,
  WelcomeTextBold,
  WelcomeSmiley,
  InsuranceItemContainer,
} from './styles';
import {
  Aetna,
  ArrowLeftIcon,
  BlueCross,
  Cigna,
  DeltaDental,
  Guardian,
  Humana,
  Metlife,
  Principal,
  SmileyPink,
} from '../../assets/images';
import AddPaymentMethod from '../../features/AddPaymentMethod';
import AppointmentBooked from '../../features/AppointmentBooked';
import ChooseAppointment from '../../features/ChooseAppointment';
import ChooseAppointmentType from '../../features/ChooseAppointmentType';
import ChooseLocation from '../../features/ChooseLocation';
import DoYouHaveDentalInsurance from '../../features/DoYouHaveDentalInsurance';
import ExistingPatient from '../../features/ExistingPatient';
import Login from '../../features/Login';
import LoginDetails from '../../features/LoginDetails';
import MobileAppBanner from '../../features/MobileAppBanner';
import OneTimePassCode from '../../features/OneTimePassCode';
import PatientData from '../../features/PatientData';
import VerifyPhone from '../../features/VerifyPhone';
import { useInnerWidth } from '../../hooks/useInnerWidth';
import { setCurrentLoginFlowScreen } from '../../slices/loginSlice';
import * as strings from '../../utils/strings';
import DentCountdown from '../Countdown';

/**
 * FIXME: The switching logic and state management here is an inefficient substitute for a routing library. Unnecessary calls to setState() have been eliminated to reduce the amount of re-renders, but this needs to be refactored and a routing library implemented. (Jira ticket DEN-866 - Stripe Integration)
 */
interface ScreensInitialState {
  showLoginScreen: boolean;
  showChooseLocationScreen: boolean;
  showExistingPatientScreen: boolean;
  showOneTimePassScreen: boolean;
  showChooseAppointmentScreen: boolean;
  showPatientData: boolean;
  showAppointmentBookedScreen: boolean;
  showDoYoHaveDentalInsuranceScreen: boolean;
  showChooseAppointmentTypeScreen: boolean;
  verifyPhoneScreen: boolean;
  paymentScreen: boolean;
  showLoginDetailsScreen: boolean;
}

const screensInit: ScreensInitialState = {
  showLoginScreen: false,
  showChooseLocationScreen: false,
  showExistingPatientScreen: false,
  showOneTimePassScreen: false,
  showChooseAppointmentScreen: false,
  showPatientData: false,
  showAppointmentBookedScreen: false,
  showDoYoHaveDentalInsuranceScreen: false,
  showChooseAppointmentTypeScreen: false,
  verifyPhoneScreen: false,
  paymentScreen: false,
  showLoginDetailsScreen: false,
};

const WrapperSchedule = () => {
  const [screens, setScreens] = useState<ScreensInitialState>(screensInit);

  const {
    showLoginScreen,
    showChooseLocationScreen,
    showExistingPatientScreen,
    showOneTimePassScreen,
    showChooseAppointmentScreen,
    showPatientData,
    showAppointmentBookedScreen,
    showDoYoHaveDentalInsuranceScreen,
    showChooseAppointmentTypeScreen,
    verifyPhoneScreen,
    paymentScreen,
    showLoginDetailsScreen,
  } = screens;

  const { currentLoginFlowScreen } = useSelector(
    (state: RootState) => state.session,
  );

  const { userName } = useSelector((state: RootState) => state.userData);
  const { loggedIn } = useSelector((state: RootState) => state.session);

  const dispatch = useDispatch();
  const [shouldRestartCountdown, setShouldRestartCountdown] = useState(false);
  const [countdown, setCountdown] = useState<number>(0);

  const countdownScreens = [
    'patientDataScreen',
    'doYouHaveDentalInsuranceScreen',
    'verifyPhoneScreen',
    'paymentScreen',
  ];

  useEffect(() => {
    if (!countdownScreens.includes(currentLoginFlowScreen)) {
      setCountdown(0);
      setShouldRestartCountdown(true);
    } else if (
      shouldRestartCountdown &&
      currentLoginFlowScreen === 'patientDataScreen'
    ) {
      const count = Date.now() + 600000;
      setCountdown(count);
      setShouldRestartCountdown(false);
    }
  }, [currentLoginFlowScreen, shouldRestartCountdown]);

  useEffect(() => {
    if (currentLoginFlowScreen === 'loginScreen') {
      setScreens({
        showLoginScreen: true,
        showChooseLocationScreen: false,
        showExistingPatientScreen: false,
        showOneTimePassScreen: false,
        showChooseAppointmentScreen: false,
        showPatientData: false,
        showAppointmentBookedScreen: false,
        showDoYoHaveDentalInsuranceScreen: false,
        showChooseAppointmentTypeScreen: false,
        verifyPhoneScreen: false,
        paymentScreen: false,
        showLoginDetailsScreen: false,
      });
    }
    if (currentLoginFlowScreen === 'oneTimePassScreen') {
      setScreens({
        showLoginScreen: false,
        showChooseLocationScreen: false,
        showExistingPatientScreen: false,
        showOneTimePassScreen: true,
        showChooseAppointmentScreen: false,
        showPatientData: false,
        showAppointmentBookedScreen: false,
        showDoYoHaveDentalInsuranceScreen: false,
        showChooseAppointmentTypeScreen: false,
        verifyPhoneScreen: false,
        paymentScreen: false,
        showLoginDetailsScreen: false,
      });
    }
    if (currentLoginFlowScreen === 'existingPatientScreen') {
      setScreens({
        showLoginScreen: false,
        showChooseLocationScreen: false,
        showExistingPatientScreen: true,
        showOneTimePassScreen: false,
        showChooseAppointmentScreen: false,
        showPatientData: false,
        showAppointmentBookedScreen: false,
        showDoYoHaveDentalInsuranceScreen: false,
        showChooseAppointmentTypeScreen: false,
        verifyPhoneScreen: false,
        paymentScreen: false,
        showLoginDetailsScreen: false,
      });
    }
    if (currentLoginFlowScreen === 'patientDataScreen') {
      setScreens({
        showLoginScreen: false,
        showChooseLocationScreen: false,
        showExistingPatientScreen: false,
        showOneTimePassScreen: false,
        showChooseAppointmentScreen: false,
        showPatientData: true,
        showAppointmentBookedScreen: false,
        showDoYoHaveDentalInsuranceScreen: false,
        showChooseAppointmentTypeScreen: false,
        verifyPhoneScreen: false,
        paymentScreen: false,
        showLoginDetailsScreen: false,
      });
    }
    if (currentLoginFlowScreen === 'chooseAppointmentScreen') {
      setScreens({
        showLoginScreen: false,
        showChooseLocationScreen: false,
        showExistingPatientScreen: false,
        showOneTimePassScreen: false,
        showChooseAppointmentScreen: true,
        showPatientData: false,
        showAppointmentBookedScreen: false,
        showDoYoHaveDentalInsuranceScreen: false,
        showChooseAppointmentTypeScreen: false,
        verifyPhoneScreen: false,
        paymentScreen: false,
        showLoginDetailsScreen: false,
      });
    }
    if (currentLoginFlowScreen === 'appointmentBookedScreen') {
      setScreens({
        showLoginScreen: false,
        showChooseLocationScreen: false,
        showExistingPatientScreen: false,
        showOneTimePassScreen: false,
        showChooseAppointmentScreen: false,
        showPatientData: false,
        showAppointmentBookedScreen: true,
        showDoYoHaveDentalInsuranceScreen: false,
        showChooseAppointmentTypeScreen: false,
        verifyPhoneScreen: false,
        paymentScreen: false,
        showLoginDetailsScreen: false,
      });
    }
    if (currentLoginFlowScreen === 'doYouHaveDentalInsuranceScreen') {
      setScreens({
        showLoginScreen: false,
        showChooseLocationScreen: false,
        showExistingPatientScreen: false,
        showOneTimePassScreen: false,
        showChooseAppointmentScreen: false,
        showPatientData: false,
        showAppointmentBookedScreen: false,
        showDoYoHaveDentalInsuranceScreen: true,
        showChooseAppointmentTypeScreen: false,
        verifyPhoneScreen: false,
        paymentScreen: false,
        showLoginDetailsScreen: false,
      });
    }
    if (currentLoginFlowScreen === 'chooseAppointmentTypeScreen') {
      setScreens({
        showLoginScreen: false,
        showChooseLocationScreen: false,
        showExistingPatientScreen: false,
        showOneTimePassScreen: false,
        showChooseAppointmentScreen: false,
        showPatientData: false,
        showAppointmentBookedScreen: false,
        showDoYoHaveDentalInsuranceScreen: false,
        showChooseAppointmentTypeScreen: true,
        verifyPhoneScreen: false,
        paymentScreen: false,
        showLoginDetailsScreen: false,
      });
    }
    if (currentLoginFlowScreen === 'chooseLocationsScreen') {
      setScreens({
        showLoginScreen: false,
        showChooseLocationScreen: true,
        showExistingPatientScreen: false,
        showOneTimePassScreen: false,
        showChooseAppointmentScreen: false,
        showPatientData: false,
        showAppointmentBookedScreen: false,
        showDoYoHaveDentalInsuranceScreen: false,
        showChooseAppointmentTypeScreen: false,
        verifyPhoneScreen: false,
        paymentScreen: false,
        showLoginDetailsScreen: false,
      });
    }
    if (currentLoginFlowScreen === 'verifyPhoneScreen') {
      setScreens({
        showLoginScreen: false,
        showChooseLocationScreen: false,
        showExistingPatientScreen: false,
        showOneTimePassScreen: false,
        showChooseAppointmentScreen: false,
        showPatientData: false,
        showAppointmentBookedScreen: false,
        showDoYoHaveDentalInsuranceScreen: false,
        showChooseAppointmentTypeScreen: false,
        verifyPhoneScreen: true,
        paymentScreen: false,
        showLoginDetailsScreen: false,
      });
    }
    if (currentLoginFlowScreen === 'paymentScreen') {
      setScreens({
        showLoginScreen: false,
        showChooseLocationScreen: false,
        showExistingPatientScreen: false,
        showOneTimePassScreen: false,
        showChooseAppointmentScreen: false,
        showPatientData: false,
        showAppointmentBookedScreen: false,
        showDoYoHaveDentalInsuranceScreen: false,
        showChooseAppointmentTypeScreen: false,
        verifyPhoneScreen: false,
        paymentScreen: true,
        showLoginDetailsScreen: false,
      });
    }
    if (currentLoginFlowScreen === 'loginDetailsScreen') {
      setScreens({
        showLoginScreen: false,
        showChooseLocationScreen: false,
        showExistingPatientScreen: false,
        showOneTimePassScreen: false,
        showChooseAppointmentScreen: false,
        showPatientData: false,
        showAppointmentBookedScreen: false,
        showDoYoHaveDentalInsuranceScreen: false,
        showChooseAppointmentTypeScreen: false,
        verifyPhoneScreen: false,
        paymentScreen: false,
        showLoginDetailsScreen: true,
      });
    }
  }, [currentLoginFlowScreen]);

  const returnToLoginScreen = () => {
    if (currentLoginFlowScreen === 'loginScreen') {
      // if on login screen, back btn should nav to Dentologie homepage
      if (process.env.REACT_APP_DENTOLOGIE_HOMEPAGE) {
        window.location.href = process.env.REACT_APP_DENTOLOGIE_HOMEPAGE;
      }
      return;
    }
    dispatch(setCurrentLoginFlowScreen('loginScreen'));
    setScreens({
      showLoginScreen: true,
      showChooseLocationScreen: false,
      showExistingPatientScreen: false,
      showOneTimePassScreen: false,
      showChooseAppointmentScreen: false,
      showPatientData: false,
      showAppointmentBookedScreen: false,
      showDoYoHaveDentalInsuranceScreen: false,
      showChooseAppointmentTypeScreen: false,
      verifyPhoneScreen: false,
      paymentScreen: false,
      showLoginDetailsScreen: false,
    });
  };

  const renderInsuranceBanner = () => (
    <InsuranceBanner>
      <InsuranceItemContainer style={{ width: '16.5%', minWidth: 115 }}>
        <InsuranceItem src={BlueCross} style={{ height: 42 }} />
      </InsuranceItemContainer>
      <InsuranceItemContainer style={{ width: '9.4%', minWidth: 65 }}>
        <InsuranceItem src={Aetna} style={{ height: 28 }} />
      </InsuranceItemContainer>
      <InsuranceItemContainer style={{ width: '11%', minWidth: 77 }}>
        <InsuranceItem src={Cigna} style={{ height: 47 }} />
      </InsuranceItemContainer>
      <InsuranceItemContainer style={{ width: '18.5%', minWidth: 116 }}>
        <InsuranceItem src={DeltaDental} style={{ height: 27 }} />
      </InsuranceItemContainer>
      <InsuranceItemContainer style={{ width: '15%', minWidth: 94 }}>
        <InsuranceItem src={Guardian} style={{ height: 35 }} />
      </InsuranceItemContainer>
      <InsuranceItemContainer style={{ width: '10.86%', minWidth: 83 }}>
        <InsuranceItem src={Humana} style={{ height: 28 }} />
      </InsuranceItemContainer>
      <InsuranceItemContainer style={{ width: '13.1%', minWidth: 95 }}>
        <InsuranceItem src={Metlife} style={{ height: 35 }} />
      </InsuranceItemContainer>
      <InsuranceItemContainer style={{ width: '14.45%', minWidth: 105 }}>
        <InsuranceItem src={Principal} style={{ height: 50 }} />
      </InsuranceItemContainer>
    </InsuranceBanner>
  );

  const CountdownComponent = () =>
    useMemo(
      () => <DentCountdown date={countdown} />,
      [countdown, currentLoginFlowScreen],
    );

  return (
    <Container>
      <SubContainer>
        <HeaderBox>
          <BackButton onClick={returnToLoginScreen}>
            <BackArrow src={ArrowLeftIcon} />
            <ButtonName>{strings.BACK_TO_HOME}</ButtonName>
          </BackButton>
          <Header>
            <Title>{strings.SCHEDULE_YOUR_VISIT}</Title>
            <Subtitle>
              {strings.CANT_FIND_A_TIME}{' '}
              <SubtitleLink href={`tel:${strings.CANT_FIND_A_TIME_PHONE}`}>
                {strings.CANT_FIND_A_TIME_PHONE}
              </SubtitleLink>
            </Subtitle>
          </Header>
        </HeaderBox>
        {loggedIn &&
          !!userName &&
          !showAppointmentBookedScreen &&
          !verifyPhoneScreen &&
          !showLoginScreen && (
            <WelcomeContainer>
              <WelcomeTextContainer>
                <WelcomeText>{strings.HI_THERE}</WelcomeText>
                <WelcomeTextBold>{`${userName}!`}</WelcomeTextBold>
                <WelcomeSmiley src={SmileyPink} />
              </WelcomeTextContainer>
            </WelcomeContainer>
          )}
        <ComponentBox isMapView={showChooseLocationScreen}>
          {showLoginScreen && <Login />}
          {showLoginDetailsScreen && <LoginDetails />}
          {showOneTimePassScreen && (
            <OneTimePassCode
              backToLogin={() =>
                dispatch(setCurrentLoginFlowScreen('loginDetailsScreen'))
              }
            />
          )}
          {}
          {showExistingPatientScreen && <ExistingPatient />}
          {showChooseLocationScreen && <ChooseLocation />}
          {showChooseAppointmentScreen && <ChooseAppointment />}
          {showPatientData && (
            <PatientData
              returnToLogin={returnToLoginScreen}
              CountdownComponent={CountdownComponent}
            />
          )}
          {paymentScreen && (
            <AddPaymentMethod CountdownComponent={CountdownComponent} />
          )}
          {showAppointmentBookedScreen && <AppointmentBooked />}
          {showDoYoHaveDentalInsuranceScreen && (
            <DoYouHaveDentalInsurance CountdownComponent={CountdownComponent} />
          )}
          {showChooseAppointmentTypeScreen && <ChooseAppointmentType />}
          {verifyPhoneScreen && (
            <VerifyPhone CountdownComponent={CountdownComponent} />
          )}
        </ComponentBox>
        {!showAppointmentBookedScreen && renderInsuranceBanner()}
      </SubContainer>
    </Container>
  );
};

export default WrapperSchedule;
