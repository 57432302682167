import styled from 'styled-components';

import theme from '../../utils/theme';

interface OrangeCardProps {
  height: string;
  positionAbsoluteCss?: boolean;
  margin?: boolean;
  marginTop?: boolean | string;
  marginLeft?: boolean;
  width?: boolean;
}

export const CardContainer = styled.div<OrangeCardProps>`
  height: min-content;
  width: 296px;
  margin-left: 14px;
  border-radius: 9px;
  background-color: ${theme.templateColors.pink2};
  display: flex;
  align-items: flex-start;
  padding: 7px 10px 16px 11px;
  cursor: pointer;
  flex-direction: column;
  font-family: ${theme.fonts.GintoBlack};
  font-size: 10px;
  margin-top: ${({ marginTop }) => `${marginTop}`};
  box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.25);
  position: ${({ positionAbsoluteCss }) =>
    `${positionAbsoluteCss ? 'absolute' : 'relative'}`};

  @media (max-width: 915px) {
    position: ${({ positionAbsoluteCss }) =>
      `${positionAbsoluteCss ? 'absolute' : 'relative'}`};
    ${({ width }) =>
      `${width ? `width: 90%; max-width: 350px;` : 'width: 85%'}`};

    margin: 20px 0px 0px 0px;
  }
`;

export const FullWidthCardContainer = styled.div<OrangeCardProps>`
  height: min-content;
  width: 100%;
  max-width: 350px;
  border-radius: 9px;
  background-color: ${theme.templateColors.pink2};
  display: flex;
  align-items: flex-start;
  padding: 7px 10px 16px 11px;
  cursor: pointer;
  flex-direction: column;
  font-family: ${theme.fonts.GintoBlack};
  font-size: 10px;
  margin-top: ${({ marginTop }) => `${marginTop}`};
  box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.25);
  position: ${({ positionAbsoluteCss }) =>
    `${positionAbsoluteCss ? 'absolute' : 'relative'}`};

  @media (max-width: 900px) {
    position: ${({ positionAbsoluteCss }) =>
      `${positionAbsoluteCss ? 'absolute' : 'relative'}`};
    max-width: 100%;
  }
`;

export const Icon = styled.img`
  height: 15px;
  width: 15px;
  margin-right: 7px;
  margin-bottom: 3px;
`;

export const Title = styled.text`
  font-family: ${theme.fonts.GintoBlack};
  font-size: 12px;
  letter-spacing: 1.15px;
  color: ${theme.templateColors.pureWhite};
`;

export const TitleContainer = styled.div`
  flex-direction: row;
  margin-bottom: 5px;
  align-items: center;
`;

export const TitleSubtitle = styled(Title)`
  font-size: 10px;
  line-height: 10px;
  font-family: ${theme.fonts.GintoBlack};
  letter-spacing: 1.15px;
  padding-top: 4px;
`;
