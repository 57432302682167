import { AxiosResponse } from 'axios';

import refreshApi from '../../api/client';
import api from '../../api/methods';
import store from '../../redux/store';
import { setSelectedLocation } from '../../slices/locationsSlice';
import {
  setOTPToken,
  initialLoginState,
  setUserEmailOrPhone,
  setCurrentLoginFlowScreen,
  login,
} from '../../slices/loginSlice';
import { setUser } from '../../slices/userDataSlice';

interface RefreshParams {
  refreshToken: string;
}

// Login user be call with phone or email
const getOTP = async (params: any) => {
  try {
    const regex = /\((\d{3})\)-(\d{3})-(\d{4})/;
    const usernameData = params.username;
    let formattedParam = usernameData;
    if (usernameData && regex.test(usernameData)) {
      const transformedNumber =
        String('+1') + usernameData.replace(regex, '$1$2$3');
      formattedParam = transformedNumber;
    } else {
      formattedParam = usernameData;
    }
    let objectToSend = params;
    if (params?.method) {
      objectToSend = {
        username: formattedParam,
        method: params.method,
      };
    } else {
      objectToSend = { username: formattedParam };
    }
    const res = await api.post('/user/otp', objectToSend);
    if (
      res.data.with.message ===
      'Temporary Account Created: Please Confirm Account'
    ) {
      const otpToken = {
        ...initialLoginState.user?.otpToken,
        message: res.data.with.message,
        needToConfirmAccount: true,
      };
      store.dispatch(setOTPToken(otpToken));
      store.dispatch(setUserEmailOrPhone(formattedParam));
      store.dispatch(setCurrentLoginFlowScreen('oneTimePassScreen'));
      return res;
    }
    const {
      issued_at: issuedAt,
      message,
      session,
      challengeParameters: { USERNAME: username, challengeQuestion, sendType },
    } = res.data.with;
    const otpToken = {
      issuedAt,
      message,
      session,
      needToConfirmAccount: false,
      challengeParameters: {
        username,
        challengeQuestion,
        sendType,
      },
    };
    store.dispatch(setUserEmailOrPhone(formattedParam));
    store.dispatch(setOTPToken(otpToken));
    if (params?.verifyNewUser) {
      store.dispatch(setCurrentLoginFlowScreen('verifyPhoneScreen'));
    } else {
      store.dispatch(setCurrentLoginFlowScreen('oneTimePassScreen'));
    }

    const {
      fromNPFlowLocSelected,
      user: { isLogged },
    } = store.getState().session;

    // Setting selectedLocation will allow to populate ChooseAppt screen when user is login
    if (!isLogged && !fromNPFlowLocSelected) {
      store.dispatch(
        setSelectedLocation(store.getState().locations?.locationCards?.[0]),
      );
    }

    return res as any;
  } catch (err) {
    return err;
  }
};

const validateOTP = async (params: string) => {
  try {
    const unhappyPath = store.getState().session.user?.needToConfirmAccount;
    const dataToSend = {
      username: store.getState().session.user?.challengeParameters?.username,
      response: {
        session: store.getState().session.user?.session,
        answer: params,
      },
    };
    const dataToSendUnhappyPath = {
      username: store.getState().session.userEmailOrPhone,
      passcode: params,
    };
    const res = await api.post(
      unhappyPath ? '/user/otp/verifycognito' : '/user/otp/response',
      unhappyPath ? dataToSendUnhappyPath : dataToSend,
    );
    if (res.data.status === 'succeeded') {
      if (unhappyPath) {
        store.dispatch(setCurrentLoginFlowScreen('existingPatientScreen'));
      }
      if (!unhappyPath) {
        store.dispatch(
          setCurrentLoginFlowScreen('chooseAppointmentTypeScreen'),
        );
      }
    }
    const {
      issued_at: issuedAt,
      expires_in: expiresIn,
      access_token: accessToken,
      refresh_token: refreshToken,
      message,
    } = res.data.with;
    const otpToken = {
      issuedAt,
      expiresAt: Date.now() / 1000 + expiresIn,
      accessToken,
      refreshToken,
      message,
      otpToken: initialLoginState.user?.otpToken,
      idToken: '',
      isLogged: true,
    };
    store.dispatch(login(otpToken));
    return res as any;
  } catch (err) {
    return err;
  }
};

const patientMatch = async (params: any) => {
  const { lastName, year, month, day } = params;
  const dataToSend = {
    lastName: lastName,
    dateOfBirth: new Date(`${year}-${month}-${day}`)
      .toISOString()
      .split('T')[0],
  };
  const res = await api.post('/user/otp/patientmatch', dataToSend);
  if (res.data.status === 'succeeded') {
    store.dispatch(setCurrentLoginFlowScreen('existingPatientScreen'));
  }
  // failed status is being handle inside existingPatientScreen component

  return res;
};

// REFRESH TOKEN
export const refreshSession = async (params: RefreshParams) => {
  // NOTE: We might need to change this route and response management based on the API we are using
  const res = await refreshApi.post('/users/me/refreshToken', params);
  const {
    access_token: accessToken,
    refresh_token: refreshToken,
    id_token: idToken,
    expires_at: expiresAt,
  } = res?.data.with || {};
  const otpToken = {
    ...initialLoginState.user?.otpToken,
    accessToken,
    refreshToken,
    idToken,
    expiresAt,
  };
  store.dispatch(setOTPToken({ otpToken }));

  return res;
};

export default { getOTP, validateOTP, patientMatch };
