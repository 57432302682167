import styled from 'styled-components';

import theme from '../../utils/theme';

const { colors, templateColors } = theme;

interface CardProps {
  positionAbsoluteCss?: boolean;
  margin?: boolean;
  marginTop?: boolean;
  marginLeft?: boolean;
  showHoverState?: boolean;
  width?: boolean | string;
  previousScreen?: string;
  homepage?: boolean;
}

export const CardDescription = styled.p`
  font-size: 12px;
  font-family: ${theme.fonts.GintoRegular};
  margin: 10px;
  font-weight: 900;
  color: ${colors.black};
`;

export const CardSubTitle = styled.p<CardProps>`
  margin: 10px;
  font-family: ${theme.fonts.GintoRegular};
  color: ${colors.black};
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 1.15px;

  @media (max-height: 913px) {
    white-space: ${({ homepage }) =>
      `${homepage ? 'normal' : 'nowrap'}`}; /* Prevent text from wrapping */
    overflow-wrap: normal; /* Prevent word breaking */
    overflow-x: scroll; /* Allow horizontal scrolling */
    &::-webkit-scrollbar {
      width: 0; /* Remove the scrollbar width */
      height: 0; /* Remove the scrollbar height */
    }
  }

  @media only screen and (max-width: 767px) {
    margin-top: 0;
  }
`;

export const CardTitle = styled.h2`
  font-size: 16px;
  font-family: ${theme.fonts.GintoBold};
  font-weight: 700;
  margin: 10px;
  color: ${colors.black};
  padding-top: 10px;

  @media only screen and (max-width: 767px) {
    padding-top: 0;
    margin-top: 2px;
    margin-bottom: 8px;
  }
`;

export const CardWrapper = styled.div<CardProps>`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  border: 1px solid ${templateColors.lightGray2};
  box-shadow: 0px 0px 2px 2px rgba(88, 88, 88, 0.25);
  border-radius: 9px;
  padding: 7px;
  height: ${({ previousScreen }) =>
    `${previousScreen === 'chooseApptType' && '120px'}`};
  background: ${colors.cardTan};

  width: ${({ width }) => `${width ?? '85%'}`};
  position: ${({ positionAbsoluteCss }) =>
    `${positionAbsoluteCss ? 'relative' : 'relative'}`};
  border: ${({ showHoverState }) =>
    `${showHoverState && `3px solid ${theme.colors.ctaTeal}`}`};

  @media (max-height: 767px) {
    margin-top: ${({ marginTop }) => `${marginTop ? '0px' : '0px'}`};
    height: ${({ previousScreen }) =>
      `${previousScreen === 'chooseApptType' && 'unset'}`};
  }

  @media only screen and (min-width: 768px) and (max-width: 1024px) {
    /* CSS rules for tablet devices */
    width: 298px;
    height: 120px;
  }
`;

export const HomepageCardWrapper = styled.div<CardProps>`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  border: 1px solid ${templateColors.lightGray2};
  box-shadow: 0px 0px 2px 2px rgba(88, 88, 88, 0.25);
  border-radius: 9px;
  padding: 7px;
  height: ${({ previousScreen }) =>
    `${previousScreen === 'chooseApptType' && '120px'}`};
  background: ${colors.cardTan};

  position: ${({ positionAbsoluteCss }) =>
    `${positionAbsoluteCss ? 'relative' : 'relative'}`};

  &.hovered {
    border: ${({ showHoverState }) =>
      `${showHoverState && `2px solid ${theme.colors.ctaTeal}`}`};
    box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.25);
    border-radius: 9px;
    z-index: 90;
  }

  &:hover {
    border: ${({ showHoverState }) =>
      `${showHoverState && `2px solid ${theme.colors.ctaTeal}`}`};
    box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.25);
    border-radius: 9px;
    z-index: 90;
  }

  &.hoverMakerCliked {
    border: 2px solid ${theme.colors.ctaTeal};
    box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.25);
    border-radius: 9px;
    z-index: 90;
  }

  @media (min-height: 900px) {
    margin: ${({ previousScreen }) =>
      `${
        previousScreen === 'chooseApptType'
          ? '2% 5% 0px 0px'
          : '8px 0px 8px 0px'
      }`};
  }

  @media (max-height: 767px) {
    margin-top: ${({ marginTop }) => `${marginTop ? '0px' : '0px'}`};
    height: ${({ previousScreen }) =>
      `${previousScreen === 'chooseApptType' && 'unset'}`};
  }

  @media (max-height: 700px) {
    margin: ${({ previousScreen }) =>
      `${
        previousScreen === 'chooseApptType'
          ? '2% 0% 0px 0px'
          : ' 0px 0px 0px 0px'
      }`};
  }
`;

export const DescriptionIcon = styled.img`
  width: 18px;
  height: 18px;
`;

export const DescriptionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: -6px;
`;

export const DivisonLine = styled.div`
  border: 0.75px solid ${templateColors.tan3};
`;

export const QuestionIconWrapper = styled.a`
  @media only screen and (max-width: 767px) {
    margin-top: -10px;
  }
`;

export const QuestionRigthIcon = styled.img`
  width: 18px;
  height: 18px;
`;

export const StyledWarningIcon = styled.img`
  width: 20px;
  height: 20px;
  margin-top: 5px;

  @media (max-height: 767px) {
    margin-top: -8px;
  }
`;

export const TitleAndIconContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const TitlesContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const TitlesWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 24px;

  @media only screen and (max-width: 767px) {
    height: unset;
  }
`;
