import React, { useState, useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';

import {
  Container,
  DropdownButton,
  DropdownIcon,
  DropdownContent,
  DropdownItem,
  ToothIcon,
  LocationBox,
  Title,
  SelectedOptionBox,
  MapIcon,
  LocationName,
  MapIconBox,
} from './styles';
import { DownArrow, Tooth, MapPin } from '../../assets/images';
import { setSelectedAppointmentType } from '../../slices/appointmentSlice';
import {
  setSelectedLocation,
  setNewLocationDataWithAppt,
} from '../../slices/locationsSlice';
import {
  formatApptType,
  formatLocationName,
  formatSentence,
} from '../../utils/functions';
import { logGTMEvent } from '../../utils/gtm/gtmHelpers';
import { EventNames, PageType } from '../../utils/gtm/gtmTypes';

// FIXME: Refactor and use object destructuring. Add types where necessary.

type DropdownProps = {
  isLocationType?: boolean;
  options: any[];
  noMargin?: boolean;
  previousScreen?: string;
  apptType?: boolean;
};
const Dropdown = ({
  isLocationType,
  options,
  noMargin,
  previousScreen,
  apptType,
}: DropdownProps) => {
  const [windowSize, setWindowSize] = useState(window.innerWidth);

  const dispatch = useDispatch();

  const { currentLoginFlowScreen, isNewPatient } = useSelector(
    (state: RootState) => state.session,
  );
  const { locationDataWithAppt, selectedLocation, locationCards } = useSelector(
    (state: RootState) => state.locations,
  );
  const { selectedApptType, apptTypesOptionsBySelLocation } = useSelector(
    (state: RootState) => state.appointment,
  );
  const [selectedLocationName, setSelectedLocationName] = useState(
    selectedLocation?.title || selectedLocation?.name || '',
  );

  const selectedApptTypeTransformed = formatSentence(selectedApptType);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(
    isLocationType
      ? selectedLocation?.subtitle || selectedLocation?.address
      : selectedApptTypeTransformed,
  );

  const searchSelectedApptType = (selectedLocationInput: any) => {
    const filteredLocation = locationDataWithAppt.filter(
      (location: any) => location?.id === selectedLocationInput,
    );
    dispatch(setNewLocationDataWithAppt(filteredLocation));
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option: any) => {
    if (isLocationType) {
      const locationCard = locationCards.find(card => option.id === card.id);
      searchSelectedApptType(option?.id);
      dispatch(
        setSelectedLocation({
          ...option,
          title: formatLocationName(option?.locationName),
          subtitle: option?.address?.address1,
          fullAddress: option?.address,
          timeZone: locationCard?.timeZone,
          timeZoneString: locationCard?.timeZoneString,
        }),
      );
      // setting values for UI purpose
      setSelectedLocationName(option?.locationName);
      setSelectedOption(option?.address?.address1);

      logGTMEvent({
        event: isNewPatient
          ? EventNames.npChangeLocation
          : EventNames.epChangeLocation,
        eventData: {
          locationId: `${option.id}`,
        },
        pageName: currentLoginFlowScreen as PageType,
      });
    }

    if (apptType) {
      setSelectedOption(option.toUpperCase());
      dispatch(setSelectedAppointmentType(option.toUpperCase()));

      logGTMEvent({
        event: isNewPatient
          ? EventNames.npChangeApptType
          : EventNames.epChangeApptType,
        eventData: {
          apptType: option.toUpperCase(),
        },
        pageName: currentLoginFlowScreen as PageType,
      });
    }
    setIsOpen(false);
  };

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize(window.innerWidth);
    };
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  });

  const optionAddress = (option: any) =>
    `${formatLocationName(option?.locationName)} - ${
      option?.address?.address1
    }, ${option?.address?.city}, ${option?.address?.state} ${
      option?.address?.postalCode
    }`;

  // eslint-disable-next-line max-len
  const selectedAddress = `${selectedLocation?.fullAddress?.address1}, ${selectedLocation?.fullAddress?.city}, ${selectedLocation?.fullAddress?.state} ${selectedLocation?.fullAddress?.postalCode}`;

  return (
    <Container
      noMargin={noMargin}
      isLocationType={isLocationType}
      previousScreen={previousScreen}
    >
      <Title>{isLocationType ? 'Location' : ' Appointment type'}</Title>
      <DropdownButton onClick={toggleDropdown} isLocationType={isLocationType}>
        {isLocationType ? (
          <LocationBox>
            <MapIconBox>
              <MapIcon src={MapPin} />
            </MapIconBox>
            <LocationName>
              {formatLocationName(
                selectedLocation?.name || selectedLocationName,
              )}
            </LocationName>
            {windowSize < 900 && <DropdownIcon src={DownArrow} />}
          </LocationBox>
        ) : (
          <ToothIcon src={Tooth} />
        )}
        <SelectedOptionBox>
          {apptType ? formatSentence(selectedOption) : selectedAddress}
        </SelectedOptionBox>
        {!isLocationType && <DropdownIcon src={DownArrow} />}
        {isLocationType && windowSize > 900 && <DropdownIcon src={DownArrow} />}
      </DropdownButton>
      {isOpen && (
        <DropdownContent
          isLocationType={isLocationType}
          previousScreen={previousScreen}
        >
          {apptType
            ? apptTypesOptionsBySelLocation?.[0]?.types
                .filter(({ type }: any) => {
                  if (isNewPatient) {
                    return !type?.includes('RETURNING');
                  }
                  return !type?.includes('NEW');
                })
                .map((option: any) => (
                  <DropdownItem
                    key={option.type}
                    onClick={() => handleOptionClick(option.type)}
                  >
                    {formatSentence(option.type)}
                  </DropdownItem>
                ))
            : options?.map(option => (
                <DropdownItem
                  key={option.id}
                  onClick={() => handleOptionClick(option)}
                >
                  {optionAddress(option)}
                </DropdownItem>
              ))}
        </DropdownContent>
      )}
    </Container>
  );
};

export default Dropdown;
