/* eslint-disable no-nested-ternary */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable max-len */
import React, { useEffect, useRef, useState } from 'react';

import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import Calendar from 'react-calendar';
import { Oval } from 'react-loader-spinner';
import { useSelector, useDispatch } from 'react-redux';

import {
  getScheduleData,
  lockAppointment,
  bookAppointment,
} from './operations';
import {
  Container,
  Header,
  ButtonsContainer,
  ProgressBarContainer,
  AvailableTimesBox,
  AvailableTimeTitle,
  AvailableTimesWrapper,
  AvailableTimeCircle,
  HeaderBox,
  EmptyStateContainer,
  EmptyStateText,
  WrapperBox,
  CalendarBox,
  RightBox,
  LoaderContainer,
  LeftBox,
  DropdownContainer,
  AvailableTimeTitleBox,
  HeaderTitleBox,
  BackButtonBox,
  DropBox,
  LocalTimeDisclaimer,
  LocalTimeDisclaimerBox,
  Rotato,
  DropBox2,
} from './styles';
import { NextCalendarIcon, BackCalendarIcon } from '../../assets/images';
import BackButton from '../../components/BackButton';
import Button from '../../components/Button';
import Dropdown from '../../components/Dropdown';
import ErrorModal from '../../components/ErrorModal';
import ProgressBar from '../../components/ProgressBar';
import { useApi } from '../../hooks/useApi';
import {
  setSelectedAppoinmentTime,
  setSelectedAppoinmentDate,
  setSelectedRawDate,
} from '../../slices/appointmentSlice';
import {
  setCurrentLoginFlowScreen,
  setSkipChooseAppointmentType,
} from '../../slices/loginSlice';
import {
  getMonthAndDay,
  formatDate,
  transformApptTypeString,
  getEarliestAvailableDay,
} from '../../utils/functions';
import { logGTMEvent } from '../../utils/gtm/gtmHelpers';
import { EventNames, PageNames } from '../../utils/gtm/gtmTypes';
import * as strings from '../../utils/strings';
import theme from '../../utils/theme';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(customParseFormat);

const ChooseAppointment = () => {
  const dispatch = useDispatch();

  const { availablesDates, scheduleData } = useSelector(
    (state: RootState) => state.schedule,
  );
  const { selectedAppointmentTime, selectedRawDate, selectedApptType } =
    useSelector((state: RootState) => state.appointment);

  const { allLocations, selectedLocation } = useSelector(
    (state: RootState) => state.locations,
  );

  /**
   * FIXME: The properties of 'selectedLocation' differ from those of 'allLocations' objects in the Redux store. 'requiresCof' is a property on 'allLocations' objects, but not on the 'selectedLocation' object, which is currently a React component. React components--non-serializable values more generally--should not be stored in Redux, but refactoring the way 'selectedLocation' is stored on state is a significant undertaking here. For now, grabbing 'requiresCof' from 'allLocations' based on 'locationId' is a sufficient ad-hoc solution. (Jira ticket DEN-866 - Stripe Integration)
   */
  const { id: selectedLocationId } = selectedLocation;
  const {
    config: { requiresCof },
  } = allLocations.find((location: any) => location.id === selectedLocationId);

  const { isNewPatient, skipChooseAppointmentType } = useSelector(
    (state: RootState) => state.session,
  );

  const { request: getScheduleDataReq, loading: getScheduleDataLoading } =
    useApi(getScheduleData);

  const {
    request: lockAppointmentReq,
    error: lockApptError,
    successResponse: lockApptSucccesRes,
    loading: lockApptLoading,
  } = useApi(lockAppointment);

  const {
    request: bookAppointmentReq,
    error: bookApptError,
    successResponse: bookApptSucccesRes,
    loading: bookApptLoading,
  } = useApi(bookAppointment);

  const [openErrorModal, setOpenErrorModal] = useState<boolean>(false);
  const [selectedDate, setSelectedDate] = useState<any>(dayjs().toDate());
  const [selectedTime, setSelectedTime] = useState();
  const [selectedMonth, setSelectedMonth] = useState();
  const [selectedDateFormated, setSelectedDateFormated] = useState<string>();
  const [showPreviousButton, setShowPreviousButton] = useState(true);
  const [slots, setSlots] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const continueBtnRef = useRef<HTMLDivElement>(null);

  const NextIcon = <img src={NextCalendarIcon} alt="next calendar icon" />;

  const BackIcon = showPreviousButton ? (
    <Rotato src={NextCalendarIcon} alt="real back calendar icon" />
  ) : (
    <img src={BackCalendarIcon} alt="back calendar icon" />
  );

  const transformedDateArray = Object.keys(availablesDates).map(dateString =>
    dayjs(dateString).toDate(),
  );

  const handleSelectedTime = (time: any) => {
    setSelectedTime(time);
    dispatch(setSelectedAppoinmentTime(time));
    continueBtnRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const handleClickDay = (daySelected: any) => {
    handleSelectedTime('');
    setSelectedDate(daySelected);
    const dateFormated = getMonthAndDay(daySelected);
    setSelectedDateFormated(dateFormated);
    dispatch(setSelectedAppoinmentDate(dateFormated));
    // FIXME:  dispatching a Date object to redux (not serializable)
    dispatch(setSelectedRawDate(daySelected));
  };

  useEffect(() => {
    if (selectedApptType && selectedDate) {
      const formattedDate = dayjs(selectedDate).format('YYYY-MM-DD');
      const availabilities = scheduleData[formattedDate];
      setSlots(availabilities);
    }
  }, [selectedApptType, selectedDate, scheduleData, selectedApptType]);

  useEffect(() => {
    if (scheduleData) {
      const newSelectedDate = getEarliestAvailableDay(scheduleData);
      const parsed = dayjs(newSelectedDate, 'YYYY-MM-DD');
      setSelectedDate(parsed.toDate());
      const dateFormated = getMonthAndDay(parsed);
      dispatch(setSelectedAppoinmentDate(dateFormated));
      dispatch(setSelectedRawDate(parsed.format()));
    }
  }, [scheduleData]);

  const handleBackButton = () => {
    if (skipChooseAppointmentType) {
      dispatch(setCurrentLoginFlowScreen('chooseLocationsScreen'));
      dispatch(setSkipChooseAppointmentType(false));
    } else {
      dispatch(setCurrentLoginFlowScreen('chooseAppointmentTypeScreen'));
    }
  };

  const tileClassName = ({ date, view }: any) => {
    const currentDate = new Date();
    if (
      date.getFullYear() === currentDate.getFullYear() &&
      date.getMonth() === currentDate.getMonth()
    ) {
      setShowPreviousButton(false);
    } else {
      setShowPreviousButton(true);
    }
    if (
      selectedDate &&
      date.toDateString() === new Date(selectedDate).toDateString()
    ) {
      return 'selected' as any;
    }
    if (
      view === 'month' &&
      date.getFullYear() === currentDate.getFullYear() &&
      date.getMonth() === currentDate.getMonth() &&
      date.getDate() === currentDate.getDate()
    ) {
      return 'current-day';
    }
    return null;
  };

  const tileDisabled = ({ date }: any) => {
    // Disable dates from previous
    const now = new Date();
    const monthStart = new Date(now.getFullYear(), now.getMonth(), 1);

    if (date < monthStart) {
      return true;
    }
    // Check if date is in the transformedDateArray
    const hasEvent = transformedDateArray.find(
      d => new Date(d).toDateString() === date.toDateString(),
    );
    if (date < now && hasEvent) {
      return false;
    }
    if (hasEvent) {
      return false;
    }
    return true;
  };

  const onChangeMonth = ({ activeStartDate }: any) => {
    handleSelectedTime('');
    setSelectedMonth(activeStartDate);
  };

  const handleDisabledButton = () => {
    if (
      (selectedRawDate || selectedDateFormated) &&
      (selectedTime || selectedAppointmentTime)
    ) {
      return false;
    }
    return true;
  };

  const handleContinueButton = async () => {
    const { timeZone } = selectedLocation;
    // Format datetime object from global state as string (YYYY-MM-DD)
    const date = selectedDate.toISOString().split('T')[0];
    // Parse date in office's local timezone, format as ISO string
    const localDateTime = dayjs
      .tz(`${date} ${selectedTime}`, 'YYYY-MM-DD h:mmA', timeZone)
      .toISOString();
    // Lock appointment slot for new patients
    if (isNewPatient) {
      setIsLoading(true);
      try {
        const lockApptParams = {
          locationId: selectedLocation?.id,
          appointmentTime: localDateTime,
          appointmentType: transformApptTypeString(selectedApptType),
        };

        await lockAppointment(lockApptParams);

        logGTMEvent({
          event: EventNames.npApptSlotSelected,
          pageName: PageNames.chooseApptTime,
        });

        dispatch(setCurrentLoginFlowScreen('patientDataScreen'));
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        setOpenErrorModal(true);
        logGTMEvent({
          event: EventNames.npApptNoLongerAvail,
          pageName: PageNames.chooseApptTime,
        });
      }
      // Book appointment slot for existing patients (no COF required)
    } else {
      setIsLoading(true);
      try {
        logGTMEvent({
          event: EventNames.epApptSlotSelected,
          pageName: PageNames.chooseApptTime,
        });
        if (requiresCof) {
          dispatch(setCurrentLoginFlowScreen('paymentScreen'));
        } else {
          const bookApptParams = {
            locationId: selectedLocation?.id,
            appointmentTime: localDateTime,
            appointmentType: transformApptTypeString(selectedApptType),
          };

          await bookAppointment(bookApptParams);

          setIsLoading(false);

          logGTMEvent({
            event: EventNames.epCofSubmitted,
            pageName: PageNames.chooseApptTime,
          });

          logGTMEvent({
            event: EventNames.epApptBooked,
            pageName: PageNames.chooseApptTime,
          });

          dispatch(setCurrentLoginFlowScreen('appointmentBookedScreen'));
        }
      } catch (error) {
        setIsLoading(false);
        setOpenErrorModal(true);
        logGTMEvent({
          event: EventNames.npApptNoLongerAvail,
          pageName: PageNames.chooseApptTime,
        });
      }
    }
  };

  const refreshSchedule = async () => {
    // Populate Calendar month view with BE data, whenever user change the selected month
    const scheduleParams = {
      locationId: selectedLocation?.id,
      startDate: dayjs(selectedMonth).startOf('month').format('YYYY-MM-DD'),
      endDate: dayjs(selectedMonth).endOf('month').format('YYYY-MM-DD'),
      appointmentType: transformApptTypeString(selectedApptType),
    };
    await getScheduleDataReq(scheduleParams);
  };

  useEffect(() => {
    setSlots([]);
    refreshSchedule();
  }, [selectedMonth, selectedLocation, selectedApptType]);

  return (
    <Container>
      <BackButtonBox>
        <BackButton onClick={handleBackButton} />
      </BackButtonBox>

      <HeaderBox>
        <DropdownContainer>
          <DropBox>
            <Dropdown
              isLocationType
              options={allLocations}
              previousScreen="chooseAppt"
            />
          </DropBox>
          <DropBox2>
            <Dropdown
              isLocationType={false}
              apptType
              options={allLocations}
              previousScreen="chooseAppt"
            />
          </DropBox2>
        </DropdownContainer>
        <ProgressBarContainer>
          <ProgressBar initialValue={23} />
        </ProgressBarContainer>
      </HeaderBox>
      <WrapperBox>
        <LeftBox>
          <HeaderTitleBox>
            <Header>{strings.WHEN_WOULD_YOU_LIKE_TO_COME_IN}</Header>
          </HeaderTitleBox>
          <CalendarBox>
            <Calendar
              tileClassName={tileClassName}
              tileDisabled={tileDisabled}
              value={selectedDate}
              onActiveStartDateChange={onChangeMonth}
              onClickDay={handleClickDay}
              locale="en-US"
              maxDetail="month"
              minDetail="month"
              nextLabel={NextIcon}
              prevLabel={BackIcon}
              minDate={new Date()}
              showNeighboringMonth={false}
              showDoubleView={false}
            />
          </CalendarBox>
        </LeftBox>

        <RightBox>
          <AvailableTimesBox>
            <AvailableTimeTitleBox>
              <AvailableTimeTitle>
                {strings.AVAILABLE_TIMES_FOR}{' '}
                {formatDate(selectedRawDate || new Date())}
              </AvailableTimeTitle>
            </AvailableTimeTitleBox>
            <AvailableTimesWrapper>
              {getScheduleDataLoading ? (
                <LoaderContainer>
                  <Oval
                    height={60}
                    width={60}
                    color={theme.colors.ctaTeal}
                    ariaLabel="oval-loading"
                    secondaryColor={theme.colors.lightTeal}
                    strokeWidth={3}
                    strokeWidthSecondary={3}
                  />
                </LoaderContainer>
              ) : slots?.length ? (
                slots.map((item: any) => (
                  <AvailableTimeCircle
                    key={item}
                    onClick={() => handleSelectedTime(item)}
                    selected={selectedAppointmentTime === item}
                  >
                    {`${item?.slice(0, -2) || ''} ${item?.slice(-2) || ''}`}
                  </AvailableTimeCircle>
                ))
              ) : (
                <EmptyStateContainer>
                  <EmptyStateText>{strings.NO_AVAILABILITIES}</EmptyStateText>
                </EmptyStateContainer>
              )}
              <LocalTimeDisclaimerBox>
                <LocalTimeDisclaimer>
                  {`${strings.ALL_TIMES_LOCAL} ${selectedLocation.timeZoneString}`}
                </LocalTimeDisclaimer>
              </LocalTimeDisclaimerBox>
            </AvailableTimesWrapper>
          </AvailableTimesBox>
          <ButtonsContainer ref={continueBtnRef}>
            <Button
              label={
                isNewPatient || requiresCof
                  ? strings.CONTINUE
                  : strings.COMPLETE_BOOKING
              }
              isLoading={isLoading}
              onPress={handleContinueButton}
              disabled={handleDisabledButton()}
              mQueryCellphoneWidth="100%"
              width="95%"
            />
          </ButtonsContainer>
        </RightBox>
      </WrapperBox>
      <ErrorModal
        title={strings.OOPS}
        details={strings.SORRY_THAT_APPT_IS_NO_LONGER}
        open={openErrorModal}
        setOpen={setOpenErrorModal}
      />
    </Container>
  );
};

export default ChooseAppointment;
