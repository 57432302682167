/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';

import { StyledContainer, StyledTextField } from './styles';

interface OneLineTextInputProps {
  placeholder: string;
  error?: boolean;
  errorText?: string;
  value?: string;
  setValue?: (value: string) => void;
  maxLength?: number;
  onBlur?: () => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
}

const OneLineTextInput = ({
  placeholder,
  error = false,
  errorText = 'Incorrect entry',
  value = '',
  setValue = () => null,
  maxLength,
  onBlur,
  onKeyDown,
}: OneLineTextInputProps) => (
  <StyledContainer>
    <StyledTextField
      error={error}
      helperText={error ? errorText : ''}
      fullWidth
      value={value}
      label={placeholder}
      onChange={e => {
        setValue(e.target.value);
      }}
      onBlur={onBlur}
      inputProps={{ maxLength: maxLength }}
      onKeyDown={onKeyDown}
    />
  </StyledContainer>
);

export default OneLineTextInput;
