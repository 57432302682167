import styled from 'styled-components';
// eslint-disable-next-line import/no-unresolved
import theme from 'utils/theme';

export const CancelBoxButton = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;

export const CancelButton = styled.img`
  cursor: pointer;
`;

export const Detail = styled.div`
  font-family: ${theme.fonts.GintoMedium};
  font-weight: 500;
  text-align: center;
  font-size: 16px;
  color: ${theme.colors.black};
  line-height: 16px;
  letter-spacing: 1.15px;
  @media (max-width: 767px) {
    font-size: 13px;
  }
`;

export const TextBox = styled.div`
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  width: 100%;
  margin-bottom: 35px;
  margin-top: 15px;
`;

export const Title = styled.div`
  font-family: ${theme.fonts.GintoBlack};
  font-size: 18px;
  line-height: 18px;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 1.15px;
  color: ${theme.colors.black};
  padding-bottom: 10%;
`;
