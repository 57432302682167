import { CircularProgress } from '@material-ui/core';
import styled from 'styled-components';

import theme from '../../utils/theme';

interface ButtonProps {
  mode: 'primary' | 'secondary';
  disabled: boolean;
  height?: string;
  width?: string;
  mQueryCellphoneWidth?: string;
  marginTop?: string;
}

const getBackgroundColor = (
  mode: 'primary' | 'secondary',
  disabled: boolean,
) => {
  if (disabled) return theme.templateColors.tan3;
  if (mode === 'primary') return theme.colors.ctaTeal;
  return 'transparent';
};

const getFontColor = (mode: 'primary' | 'secondary', disabled: boolean) => {
  if (disabled) return theme.templateColors.tan4;
  if (mode === 'primary') return theme.colors.black;
  return theme.colors.ctaTeal;
};

export const ButtonContainer = styled.button<ButtonProps>`
  height: ${({ height }) => `${height}`};
  width: ${({ width }) => `${width}`};
  border-radius: ${({ height }) => `${height}`};
  background-color: ${({ mode, disabled }) =>
    getBackgroundColor(mode, disabled)};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  flex-direction: row;
  font-weight: 900;
  font-family: ${theme.fonts.GintoBlack};
  font-size: 18px;
  margin-top: ${({ marginTop }) => `${marginTop ?? '10px'}`};
  border: ${({ mode }) =>
    mode === 'secondary' ? `1px solid ${theme.colors.ctaTeal}` : 'none'};
  color: ${({ mode, disabled }) => getFontColor(mode, disabled)};
  @media (max-width: 767px) {
    width: ${({ mQueryCellphoneWidth }) => `${mQueryCellphoneWidth}`};
  }
`;

export const ProgressCircle = styled(CircularProgress)<ButtonProps>`
  height: 18px !important;
  width: 18px !important;
  color: ${({ mode, disabled }) => getFontColor(mode, disabled)} !important;
  margin-left: 10px;
  margin-bottom: 1px;
`;
