/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ScheduleProps {
  availablesDates: any;
  scheduleData: any;
}

export const initalScheduleState: ScheduleProps = {
  availablesDates: '',
  scheduleData: '',
};

const scheduleSlice = createSlice({
  name: 'scheduleSlice',
  initialState: initalScheduleState,
  reducers: {
    setAvailablesDates: (state, { payload }: PayloadAction<any>) => {
      state.availablesDates = payload;
    },
    setScheduleData: (state, { payload }: PayloadAction<any>) => {
      state.scheduleData = payload;
    },
  },
});
export default scheduleSlice.reducer;

export const { setAvailablesDates, setScheduleData } = scheduleSlice.actions;
