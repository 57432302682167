import styled from 'styled-components';

export const CardContainer = styled.div`
  margin-bottom: 20px;
  margin-right: 0px;

  @media only screen and (min-width: 768px) and (max-width: 1334px) {
    /* CSS rules for tablet devices */
    width: 100%;
    display: flex;
    zindex: 200;
    margin-right: 10px;
    margin-bottom: 20px;
  }

  @media (max-width: 767px) {
    flex-shrink: 0;
    width: 100%;
    cursor: pointer;
    z-index: 200;
    margin-bottom: 20px;
  }
`;

export const CarouselContainer = styled.div`
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  cursor: grab;
  user-select: none;
  height: auto;
  margin-top: 20px;
  margin-left: 20px;
  padding: 2px;
  padding-bottom: 250px;
  width: 100%;
  min-width: 350px;
  &::-webkit-scrollbar {
    width: 0; /* Remove the scrollbar width */
    height: 0; /* Remove the scrollbar height */
  }
  @media (max-width: 915px) {
    display: flex;
    overflow-x: auto;
    scroll-behavior: smooth;
    flex-direction: row;
    align-self: flex-end;
    -webkit-overflow-scrolling: touch;
    cursor: grab;
    user-select: none;
    height: 148px;
    margin-right: 0px;
    width: 100%;
    padding: 2px;
    padding-bottom: 0px;
    margin-top: 20px;
    margin-left: 10px;
    margin-bottom: 100px;
  }
  @media (max-width: 767px) {
    min-width: 0;
  }
`;
