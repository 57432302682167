const colors = {
  // Project colors
  activeBlue: '#2661A8',
  background: '#f5f5f5',
  backgroundTan: '#E7EBDB',
  baseText: '#3d3e44',
  black: '#292929',
  blue: '#1C63E8',
  boxShadow: '#b7b7b7',
  cardTan: '#F3F5EC',
  ctaTeal: '#6EC9AC',
  lightTeal: '#D0EDE4',
  darkGray: '#3d3e44',
  darkText: '#29292c',
  darkestGray: '#29292c',
  errorText: 'red',
  facebookBlue: '#3b5998',
  googleBlue: '#4c8bf5',
  gray: '#646464',
  linkGray: '#33393C',
  pink2: '#DF9275',
};

const templateColors = {
  // Template colors
  gray2: '#A2A2A2',
  gray5: '#4F4F4F',
  interactionMain: '#2C4251',
  interactionMainClear: '#2C425166',
  lightGray: '#DBDBDB',
  lightGray2: ' #ccc',
  lightWarmGray: '#f5f5f5',
  orange: '#E5C57A',
  pink: '#F2C4B3',
  pink2: '#DF9275',
  primary: '#cc0000',
  pureBlack: '#000000',
  pureWhite: '#FFFFFF',
  rfRed: '#cc0000',
  secondary: colors.darkGray,
  sectionBackground: '#f5f5f5',
  selected: '#cc0000',
  tan3: '#D3D2BE',
  tan4: '#ACAA7C',
  teal2: '#A1E5CF',
  teal3: '#B3EFDC',
  tertiary: '#DBDBDB',
  unselected: '#DBDBDB',
  white: '#E4E4E4',
  whiteClear: '#f8f8f888',
  warningBackground: '#df9275;',
};

const fonts = {
  DomaineDisplayRegular: 'DomaineDisplay-Regular',
  DomaineTextMedium: 'DomaineText-Medium',
  FuturaBold: 'Futura-Bold',
  Futura: 'Futura',
  GintoBlack: 'Ginto-Black',
  GintoBold: 'Ginto-Bold',
  GintoRegular: 'Ginto-Regular',
  GintoLight: 'Ginto-Light',
  GintoMedium: 'Ginto-Medium',
  GothamBlack: 'Gotham-Black',
  GothamBold: 'Gotham-Bold',
  GothamBookItalic: 'Gotham-BookItalic',
  GothamItalic: 'Gotham-Italic',
  GothamMedium: 'Gotham-Medium',
  GothamThin: 'Gotham-Thin',
  GothamThinItalic: 'Gotham-ThinItalic',
  GothamUltraItalic: 'Gotham-UltraItalic',
  GothamXLight: 'Gotham-XLight',
  GothamXLightItalic: 'Gotham-XLightItalic',
  PoppinsBold: 'Poppins-Bold',
  PoppinsRegular: 'Poppins-Regular',
  DomineRegular: 'Domine-Regular',
  DomineMedium: 'Domine-Medium',
  DomineBold: 'Domine-Bold',
  DomineSemiBold: 'Domine-SemiBold',
};

const theme = {
  colors,
  templateColors,
  fonts,
};

export default theme;
