import styled from 'styled-components';

import theme from '../../utils/theme';

type DropDownStyledProps = {
  isLocationType?: boolean;
  noMargin?: boolean;
  previousScreen?: string;
};

export const Container = styled.div<DropDownStyledProps>`
  position: relative;
  margin: ${({ noMargin }) => `${noMargin ? '0px' : '20px'}`};
  display: inline-block;
  max-width: 100%;
  background-color: ${theme.colors.cardTan};
  height: 50px;

  margin-right: ${({ isLocationType }) => `${!isLocationType && '5%'}`};

  @media (max-width: 900px) {
    margin-right: ${({ isLocationType }) => `${isLocationType && '0'}`};
    margin-bottom: ${({ isLocationType, previousScreen }) =>
      `${isLocationType && previousScreen === 'chooseAppt' && '40px'}`};
  }
  margin-left: 0px;
`;

export const DropdownButton = styled.button<DropDownStyledProps>`
  border: none;
  height: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  background-color: ${theme.colors.cardTan};
  font-family: ${theme.fonts.GintoRegular};
  font-weight: 400;
  font-size: 12px;
  line-height: 140.79%;
  text-align: center;
  letter-spacing: 1.15px;
  word-wrap: break-word;
  width: 100%;
  max-width: 600px;
  padding-left: 0px;
  @media (max-width: 900px) {
    flex-direction: ${({ isLocationType }) => `${isLocationType && 'column'}`};
    align-items: ${({ isLocationType }) =>
      `${isLocationType ? 'flex-start' : 'center'}`};
    margin-bottom: ${({ isLocationType }) => `${isLocationType && '40px'}`};
    margin-top: ${({ isLocationType }) => `${isLocationType && '-20px'}`};
    margin-right: 0;
  }
`;

export const DropdownContent = styled.ul<DropDownStyledProps>`
  position: absolute;
  top: 100%;
  background-color: ${theme.colors.cardTan};
  border: 0.5px solid ${theme.templateColors.gray2};
  left: 0;
  list-style-type: none;
  margin: ${({ isLocationType }) =>
    `${isLocationType ? '10px 0px 0px 31px;' : '3px 0px 0px 31px'}`};
  padding: ${({ isLocationType }) =>
    `${isLocationType ? '0px 0px 0px 0px;' : '9px 0px 0px 0px'}`};
  width: ${({ isLocationType }) => `${isLocationType ? '300px' : '165px'}`};
  box-shadow: 0px 4px 4px rgba(156, 156, 156, 0.25);
  z-index: ${({ isLocationType }) => `${isLocationType ? 50 : 40}`};

  @media (max-width: 900px) {
    width: ${({ isLocationType }) => `${isLocationType ? '200px' : '200px;'}`};
    margin-top: ${({ isLocationType }) => `${isLocationType && '10px;'}`};
  }
  @media only screen and (min-width: 901px) and (max-width: 1800px) {
    /* CSS rules for tablet devices */
    right: 0;
    margin-right: ${({ isLocationType }) => `${!isLocationType && '100px'}`};
  }
`;

export const DropdownIcon = styled.img`
  margin-left: 8px;
  width: 17px;
  height: 8px;
  @media (max-width: 900px) {
    margin-top: 3px;
  }
`;

export const DropdownItem = styled.li`
  padding: 4px;
  cursor: pointer;
  font-family: ${theme.fonts.GintoRegular};
  font-weight: 400;
  font-size: 14px;
  line-height: 140.79%;
  text-align: center;
  letter-spacing: 1.15px;
  border-top: 0.2px solid ${theme.templateColors.gray2};
  border-bottom: 0.2px solid ${theme.templateColors.gray2};
  width: 100%;
  padding: 10px 0px 10px 0px;

  &:hover {
    color: ${theme.colors.ctaTeal};
  }
  &:first-child {
    border-top: none; /* remove bottom border for the first option */
  }

  &:last-child {
    border-bottom: none; /* add top border for the last option */
  }
`;

export const LocationBox = styled.div`
  display: flex;
  flex-direction: row;
  margin-right: 10px;
  font-weight: 800;
  align-items: baseline;
  @media (max-width: 900px) {
    display: flex;
    flex-direction: row;
  }
`;

export const LocationName = styled.p`
  font-family: ${theme.fonts.GintoBold};
  font-weight: 700;
  font-size: 16px;
  color: ${theme.colors.black};
  line-height: 16px;
  display: flex;
  justify-content: flex-start;
  letter-spacing: 1.15px;
  align-items: baseline;
  padding-top: 15px;
`;

export const MapIcon = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 2px;
  margin-top: 5px;
  position: absolute;
`;

export const MapIconBox = styled.div`
  height: 20px;
  position: relative;
  margin-right: 30px;
`;

export const SelectedOptionBox = styled.div<DropDownStyledProps>`
  font-family: ${theme.fonts.GintoRegular};
  font-weight: 400;
  font-size: 15px;
  text-transform: capitalize;
  color: ${theme.colors.black};
  line-height: 14px;
  letter-spacing: 1.15px;
  text-align: left;
  width: auto;

  @media (max-width: 400px) {
    font-size: 13px;
  }
`;

export const Title = styled.p`
  font-family: ${theme.fonts.GothamThin};
  font-weight: 700;
  font-size: 12px;
  line-height: 140.79%;
  letter-spacing: 1.15px;
  text-transform: uppercase;
`;

export const ToothIcon = styled.img`
  width: 18px;
  height: 18px;
  margin-right: 8px;
`;
