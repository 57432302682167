import styled from 'styled-components';

import theme from '../../utils/theme';

const { colors } = theme;

export const BackArrow = styled.img`
  width: 25px;
  height: 25px;
  margin-right: 11px;
`;
export const BackButton = styled.div`
  display: flex;
  flex-direction: row;
  width: 250px;
  align-items: center;
  cursor: pointer;
`;

export const ButtonName = styled.p`
  font-family: ${theme.fonts.GintoRegular};
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 1.15px;
  margin-bottom: 0;

  @media (max-width: 767px) {
    font-size: 18px;
    font-size: 15px;
    line-height: 18px;
  }
`;
export const ButtonsContainer = styled.div`
  width: 100%;
  height: 57px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 767px) {
    flex-direction: column;
    height: 30%;
  }
`;
export const FormContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: 'left';
  align-items: left;
  margin-bottom: 12%;
  @media (max-width: 767px) {
    justify-content: 'center';
    align-items: center;
  }
`;
export const Header = styled.div`
  margin-top: 31px;
`;
export const HeaderBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: 'left';
  @media (max-width: 767px) {
    padding: 10%;
  }
`;
export const InputStyled = styled.input`
  width: 50%;
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
  border-bottom: 1px solid ${colors.black};
  font-family: ${theme.fonts.DomineRegular};
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 1.15px;
  background: ${colors.cardTan};
  outline: none;
  margin-top: 50px;
  @media (max-width: 767px) {
    width: 100%;
  }
`;
export const LoginBox = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: 73%;
  background: ${colors.cardTan};

  @media (max-width: 767px) {
    width: 100%;
    height: 73%;
  }
`;
export const LoginTitle = styled.p`
  font-family: ${theme.fonts.GintoRegular};
  font-weight: 700;
  font-size: 18px;
  line-height: 18px;
  letter-spacing: 1.15px;
`;
export const NewPatientButton = styled.div`
  background: ${colors.cardTan};
  box-shadow: 0px 0px 2px 2px rgba(88, 88, 88, 0.25);
  border-radius: 9px;
  width: 43%;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0;
  cursor: pointer;
  @media (max-width: 1300px) {
    width: 100%;
    margin-top: 10%;
  }
`;

export const NewPatientButtonTitle = styled.p`
  font-family: ${theme.fonts.GintoRegular};
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 1.15px;
  color: ${colors.black};
  margin-bottom: 0;
`;

export const OutsideContainer = styled.div`
  padding: 78px 15% 400px 14%;
  width: 100%;
  @media (max-width: 767px) {
    padding: 50px 7% 49px 8%;
  }
`;

export const ProgressBarContainer = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 30px;
  margin-top: -10px;
  @media (max-width: 767px) {
    margin-top: -10px;
  }
`;

export const Subtitle = styled.p`
  font-family: ${theme.fonts.GintoRegular};
  font-weight: 400;
  font-size: 18px;
  line-height: 18px;
  letter-spacing: 1.15px;
  margin-top: -13px;
  margin-bottom: 30px;
  @media (max-width: 767px) {
    font-size: 14px;
    line-height: 128.7%;
    margin-bottom: 0px;
  }
`;

export const Title = styled.p`
  color: ${colors.black};
  font-family: ${theme.fonts.DomineRegular};
  font-weight: 700;
  font-size: 50px;
  line-height: 140.79%;
  letter-spacing: 1.15px;
  @media (max-width: 767px) {
    font-size: 30px;
  }
`;
