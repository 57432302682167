/* eslint-disable react/button-has-type */
import React, { useState } from 'react';

import {
  PaymentElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';

import Button from '../../../components/Button';
import ErrorModal from '../../../components/ErrorModal';
import { setCurrentLoginFlowScreen } from '../../../slices/loginSlice';
import { transformApptTypeString } from '../../../utils/functions';
import { logGTMEvent } from '../../../utils/gtm/gtmHelpers';
import { EventNames, GTMEvent, PageNames } from '../../../utils/gtm/gtmTypes';
import * as strings from '../../../utils/strings';
import { bookAppointment } from '../../ChooseAppointment/operations';
import { PaymentMessage, BoxStyling, ButtonContainer } from '../styles';

const CheckoutForm = () => {
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();

  const { isNewPatient } = useSelector((state: RootState) => state.session);

  const [message, setMessage] = useState<string | null>(null);
  const [isSlotUnavailableError, setIsSlotUnavailableError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const lockedAppointmentId = useSelector(
    (state: RootState) => state.appointment?.lockApptData?.id,
  );
  const { selectedLocation } = useSelector(
    (state: RootState) => state.locations,
  );
  const { selectedRawDate, selectedApptType, selectedAppointmentTime } =
    useSelector((state: RootState) => state.appointment);

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    const { error, paymentIntent } = await stripe.confirmPayment({
      elements,
      redirect: 'if_required',
    });

    if (
      error &&
      (error.type === 'card_error' || error.type === 'validation_error')
    ) {
      const errorMessage = error?.message || '';
      setMessage(errorMessage);
    } else if (
      paymentIntent &&
      (paymentIntent.status === 'requires_capture' ||
        paymentIntent.status === 'succeeded')
    ) {
      const { timeZone } = selectedLocation;
      // Format datetime object from global state as string (YYYY-MM-DD)
      const date = new Date(selectedRawDate).toISOString().split('T')[0];
      // Parse date in office's local timezone, format as ISO string
      const localDateTime = dayjs
        .tz(`${date} ${selectedAppointmentTime}`, 'YYYY-MM-DD h:mmA', timeZone)
        .toISOString();
      const bookApptParams = {
        locationId: selectedLocation?.id,
        appointmentTime: localDateTime,
        appointmentType: transformApptTypeString(selectedApptType),
        lockedAppointmentId: lockedAppointmentId,
      };

      try {
        setIsLoading(true);
        await bookAppointment(bookApptParams);
        setIsLoading(false);
        const eventArray: GTMEvent[] = [
          {
            event: isNewPatient
              ? EventNames.npCofSubmitted
              : EventNames.epCofSubmitted,
            pageName: PageNames.payment,
          },
          {
            event: isNewPatient
              ? EventNames.npApptBooked
              : EventNames.epApptBooked,
            pageName: PageNames.payment,
          },
        ];

        eventArray.map(event => logGTMEvent(event));

        dispatch(setCurrentLoginFlowScreen('appointmentBookedScreen'));
      } catch (err) {
        setIsSlotUnavailableError(true);
        setIsLoading(false);
      }
    } else {
      setMessage('An unexpected error occurred.');
    }

    setIsLoading(false);
  };

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <PaymentElement id="payment-element" options={{ layout: 'tabs' }} />
      <ButtonContainer>
        {message && (
          <PaymentMessage id="payment-message">{message}</PaymentMessage>
        )}
        <BoxStyling />
        <Button
          id="submit"
          label="Continue"
          width="339px"
          mQueryCellphoneWidth="339px"
          disabled={false}
          isLoading={isLoading}
        />
      </ButtonContainer>
      <ErrorModal
        title={strings.OOPS}
        details={strings.SORRY_THAT_APPT_IS_NO_LONGER}
        open={isSlotUnavailableError}
        setOpen={setIsSlotUnavailableError}
      />
    </form>
  );
};

export default CheckoutForm;
