import React from 'react';

import { CancelButton, Detail, Title } from './styles';
import Button from '../Button';
import Modal from '../Modal';

interface AreYouSure {
  open: boolean;
  setOpen: (value: boolean) => void;
  title?: string;
  details?: string;
  acceptButton?: string;
  cancelButton?: string;
  onAccept: () => void;
  isLoading?: boolean;
}

const AreYouSure = ({
  open,
  setOpen,
  title = 'Just making sure...',
  details = '',
  acceptButton = 'Continue',
  cancelButton = 'Cancel',
  isLoading = false,
  onAccept,
}: AreYouSure) => (
  <Modal open={open} setOpen={setOpen}>
    <Title>{title}</Title>
    <Detail>{details}</Detail>
    <Button
      label={acceptButton}
      onPress={onAccept}
      mQueryCellphoneWidth="95%"
      isLoading={isLoading}
    />
    <CancelButton onClick={() => setOpen(false)}>{cancelButton}</CancelButton>
  </Modal>
);

export default AreYouSure;
