import styled from 'styled-components';

import theme from '../../utils/theme';

const { colors, templateColors } = theme;

export const AppointmentInformation = styled.div`
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  @media (max-width: 767px) {
    flex-direction: column;
  }
`;

export const AppointmentOnHold = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  align-content: center;
  border: 0.75px solid #d3d2be;
  border-radius: 26px;
  height: 40px;
  width: 270px;
`;

export const AppointmentPlaceholder = styled.p`
  margin-right: 10px;
  font-family: ${theme.fonts.GintoRegular};
  font-weight: 400;
  font-size: 14px;
  line-height: 140.79%;
  letter-spacing: 1.15px;
  margin-bottom: 0px;
`;

export const AppointmentTimer = styled.p`
  font-family: ${theme.fonts.GintoRegular};
  font-weight: 400;
  font-size: 14px;
  line-height: 140.79%;
  letter-spacing: 1.15px;
  color: ${theme.templateColors.pink2};
  margin-bottom: 0px;
`;

export const AppointmentTypeBox = styled.div`
  align-content: center;
  justify-content: center;
  margin-bottom: 12%;

  @media (max-width: 767px) {
    margin-top: 6%;
  }
`;

export const BoxStyling = styled.div`
  width: 60%;
  @media (max-width: 767px) {
    width: 1%;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 7%;
  margin-bottom: 7%;
  @media (max-width: 767px) {
    flex-direction: column;
    width: 100%;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
  height: 100%;
  padding: 2%;
  align-content: center;
  justify-content: center;
`;

export const DateAndTimeBox = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Description = styled.div`
  font-family: ${theme.fonts.GintoRegular};
  font-weight: 400;
  font-size: 14px;
  line-height: 140.79%;
  letter-spacing: 1.15px;
  margin-bottom: 0px;
  text-transform: capitalize;
  margin-top: 2px;

  @media (max-width: 767px) {
    font-size: 12px;
    margin-left: -3%;
  }
`;

export const DescriptionBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  @media (max-width: 767px) {
    flex-direction: column;
  }
`;

export const Header = styled.div``;

export const HeaderIcon = styled.img`
  width: 22px;
  height: 22px;
  margin-right: 10px;
  @media (max-width: 767px) {
    width: 18px;
    height: 18px;
    margin-right: 18px;
  }
`;

export const IconAndTitleBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  @media (max-width: 767px) {
    width: 100%;
  }
`;

export const IconAndTitleBoxLocation = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  @media (max-width: 767px) {
    width: 100%;
    margin-bottom: 3%;
  }
`;

export const LeftBox = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  width: 60%;
  height: 100%;
  @media (max-width: 767px) {
    flex-direction: column;
    width: 100%;
  }
`;

export const Location = styled.p`
  margin-right: 7px;
  margin-bottom: 0px;
  font-family: ${theme.fonts.GintoBold};
  font-weight: 800;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 1.15px;

  @media (max-width: 767px) {
    font-size: 12px;
    margin-left: -4%;
  }
`;

export const LocationBox = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 4%;

  @media (max-width: 767px) {
    margin-bottom: 8%;
  }
`;

export const ProgressBarContainer = styled.div`
  margin-top: 4%;
  margin-bottom: 4%;
`;

export const RightBox = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  width: 40%;
  height: 100%;

  @media (max-width: 767px) {
    flex-direction: column;
    width: 100%;
    margin-top: 13px;
    margin-bottom: 13px;
  }
`;

export const SeePlans = styled.p`
  margin-top: 5%;
  cursor: pointer;
  text-decoration: underline;
  font-family: ${theme.fonts.GintoRegular};
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  @media (max-width: 767px) {
    font-size: 14px;
  }
`;

export const Title = styled.p`
  font-family: ${theme.fonts.DomaineDisplayRegular};
  font-weight: 500;
  font-size: 30px;
  line-height: 140.79%;
  letter-spacing: 1.15px;

  &:after {
    content: 'dental insurance?';
    font-family: ${theme.fonts.DomineBold};
    font-weight: 900;
  }

  @media (max-width: 767px) {
    font-size: 22px;
  }
`;

export const TitleBox = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 5px;
  font-family: ${theme.fonts.GintoRegular};
  font-weight: 400;
  font-size: 12px;
  line-height: 140.79%;
  letter-spacing: 1.15px;
  text-transform: uppercase;
  margin-bottom: 2%;
`;

export const WarningBlueText = styled.div`
  background: ${theme.colors.blue};
  border-radius: 4px;
  font-family: ${theme.fonts.GintoRegular};
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 1.15px;
  color: ${theme.templateColors.white};
  height: 38px;
  width: 50%;
  display: flex;
  text-align: left;
  align-items: center;
  justify-content: center;
  align-content: center;
  @media (max-width: 767px) {
    width: 100%;
    font-size: 12px;
    height: 30px;
  }
`;

export const WarningRedText = styled.div`
  background: ${theme.templateColors.warningBackground};
  height: 38px;
  border-radius: 4px;
  font-family: ${theme.fonts.GintoRegular};
  font-weight: 400;
  font-size: 14px;
  line-height: 130.7%;
  letter-spacing: 1.15px;
  display: flex;
  text-align: left;
  align-items: center;
  justify-content: center;
  align-content: center;
  width: 60%;
  &:after {
    content: 'You will have to pay out of pocket.';
    font-weight: 900;
    margin-left: 5px;
  }
  @media (max-width: 767px) {
    width: 100%;
    font-size: 11px;
    padding-left: 5px;
    &:after {
      content: '';
    }
  }
`;

export const WarningRedText1 = styled.div`
  background: ${theme.templateColors.warningBackground};
  height: 38px;
  border-radius: 4px;
  font-family: ${theme.fonts.GintoRegular};
  font-weight: 400;
  font-size: 14px;
  line-height: 130.7%;
  letter-spacing: 1.15px;
  display: flex;
  text-align: left;
  align-items: center;
  justify-content: center;
  align-content: center;
  width: 90%;

  &:after {
    content: 'pay out of pocket.';
    font-weight: 900;
    margin-left: 5px;
  }
  @media (max-width: 767px) {
    width: 100%;
    font-size: 10px;
    padding-left: 5px;
    &:after {
      content: '';
    }
  }
`;
