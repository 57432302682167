import styled from 'styled-components';
// eslint-disable-next-line import/no-unresolved
import theme from 'utils/theme';

export const StyledButton = styled.button`
  font-family: ${theme.fonts.GintoBold};
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  text-decoration-line: underline;
  color: ${theme.colors.linkGray};
  text-decoration-thickness: 1px;
  background-color: transparent;
  margin: 10px 10px 20px 0px;
  border: none;
  align-self: flex-start;
  padding: 0px;
`;
