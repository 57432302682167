import styled from 'styled-components';
// eslint-disable-next-line import/no-unresolved
import theme from 'utils/theme';

export const CancelButton = styled.button`
  font-family: ${theme.fonts.GintoBold};
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  text-decoration-line: underline;
  color: ${theme.colors.black};
  background-color: ${theme.colors.cardTan};
  border: none;
  margin-top: 20px;
`;

export const Detail = styled.div`
  font-family: ${theme.fonts.GintoMedium};
  font-weight: 500;
  text-align: center;
  font-size: 16px;
  color: ${theme.colors.black};
  line-height: 16px;
  letter-spacing: 1.15px;
  margin-bottom: 30px;
  @media (max-width: 767px) {
    font-size: 13px;
  }
`;

export const Title = styled.div`
  font-family: ${theme.fonts.GintoBlack};
  font-size: 18px;
  line-height: 18px;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 1.15px;
  margin-top: 10px;
  margin-bottom: 30px;
  color: ${theme.colors.black};
`;
