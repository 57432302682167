import React, { useState, useEffect } from 'react';

import { Oval } from 'react-loader-spinner';
import { useDispatch, useSelector } from 'react-redux';

import ListLocations from './ListLocations';
import MapLocations from './MapLocations';
import { getAllLocations } from './operations';
import {
  Container,
  SearchBarBox,
  MapIcon,
  ListIcon,
  LocationsBox,
  SearchBarWrapper,
  TextInputContainer,
} from './styles';
import { MapLocationsIcon, ListLocationsIcon } from '../../assets/images';
import OneLineTextInput from '../../components/TextInput';
import { useInnerWidth } from '../../hooks/useInnerWidth';
import { LocationCard, setSelectedLocation } from '../../slices/locationsSlice';
import { setCurrentLoginFlowScreen } from '../../slices/loginSlice';
import { setSelectedMarkerName } from '../../slices/mapSlice';
import { logGTMEvent } from '../../utils/gtm/gtmHelpers';
import { EventNames, PageNames } from '../../utils/gtm/gtmTypes';
import theme from '../../utils/theme';
import { LoaderContainer } from '../ChooseAppointment/styles';

const ChooseLocation = () => {
  const [isMapIconActive, setIsMapIconActive] = useState<boolean>(true);
  const [isListIconActive, setIsListIconActive] = useState<boolean>(false);
  const [value, setValue] = useState<string>('');

  const innerWidth = useInnerWidth();

  const isMobile = innerWidth < 768;

  const { locationCards, allLocations, selectedLocation, loading } =
    useSelector((state: RootState) => state.locations);
  const { isNewPatient } = useSelector((state: RootState) => state.session);

  const dispatch = useDispatch();

  const handleMapIcon = () => {
    setIsMapIconActive(true);
    setIsListIconActive(false);
  };

  const handleListIcon = () => {
    setIsListIconActive(true);
    setIsMapIconActive(false);
  };

  const filterResults = (searchTerm: string, array: Array<LocationCard>) => {
    const filteredResults = array?.filter(
      (result: any) =>
        result?.name?.toLowerCase()?.includes(searchTerm?.toLowerCase()) ||
        result?.address?.toLowerCase()?.includes(searchTerm?.toLowerCase()) ||
        result?.zipCode?.toString()?.includes(searchTerm),
    );
    return filteredResults;
  };

  const filteredResults = filterResults(value, locationCards);

  const renderSearchIcons = () =>
    isListIconActive ? (
      <MapIcon onClick={handleMapIcon} src={MapLocationsIcon} />
    ) : (
      <ListIcon onClick={handleListIcon} src={ListLocationsIcon} />
    );

  const handleGoToNextScreen = (location: any) => {
    if (isNewPatient) {
      logGTMEvent({
        event: EventNames.npLocationSelected,
        eventData: {
          locationId: `${selectedLocation.id}`,
        },
        pageName: PageNames.chooseLocation,
      });
    }
    dispatch(setSelectedLocation(location));
    dispatch(setCurrentLoginFlowScreen('chooseAppointmentTypeScreen'));
  };

  useEffect(() => {
    if (!allLocations.length) {
      getAllLocations();
    } else if (!loading) {
      const firstLocation = locationCards[0]?.name || '';
      dispatch(setSelectedMarkerName(firstLocation));
      dispatch(setSelectedLocation(locationCards[0]));
    }
  }, [loading]);

  useEffect(() => {
    if (isMobile) {
      handleListIcon();
    }
  }, [innerWidth]);

  return (
    <Container>
      {loading ? (
        <LoaderContainer>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: theme.colors.cardTan,
              width: isMobile ? '80%' : 'unset',
              borderRadius: '10px',
              padding: isMobile ? '20px' : 'inherit',
            }}
          >
            <Oval
              height={120}
              width={120}
              color={theme.colors.ctaTeal}
              ariaLabel="oval-loading"
              secondaryColor={theme.colors.lightTeal}
              strokeWidth={3}
              strokeWidthSecondary={3}
            />
            <div style={{ marginTop: '20px' }}>Loading...</div>
          </div>
        </LoaderContainer>
      ) : (
        <>
          <SearchBarBox>
            <TextInputContainer>
              <SearchBarWrapper>
                <OneLineTextInput
                  placeholder="Search"
                  setValue={setValue}
                  value={value}
                />
              </SearchBarWrapper>
            </TextInputContainer>
            {innerWidth < 765 && renderSearchIcons()}
          </SearchBarBox>
          <LocationsBox>
            {isListIconActive ? (
              <ListLocations
                arrayData={filteredResults}
                goToNextScreen={handleGoToNextScreen}
              />
            ) : (
              <MapLocations arrayData={filteredResults} />
            )}
          </LocationsBox>
        </>
      )}
    </Container>
  );
};

export default ChooseLocation;
