import styled from 'styled-components';

import theme from '../../utils/theme';

const { fonts, templateColors, colors } = theme;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100vh;
  padding-top: 30px;
  padding-bottom: 30px;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 4px;
  background: ${templateColors.tan3};
`;

export const Title = styled.p`
  color: ${colors.black};
  padding-left: 10px;
  padding-right: 10px;
  font-family: ${theme.fonts.GintoBlack};
  font-weight: 900;
  font-size: 18px;
  line-height: 18px;
  margin-bottom: 0px;
  letter-spacing: 1.15px;
`;

export const SearchBarWrapper = styled.div`
  width: 100%;
  max-width: 298px;
  display: flex;
  align-content: left;
  margin-left: 0px;
  @media (max-width: 900px) {
    max-width: 100%;
  }
`;

export const TextInputContainer = styled.div`
  width: 100%;
  max-width: 100%;
  padding-left: 10px;
  padding-right: 10px;
  margin-left: 0px;
`;

export const LocationsBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: 'center';
  height: 100%;
  width: 100%;
  background: ${colors.backgroundTan};
`;

export const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: 'center';
  margin-top: 20px;
  height: 100%;
  width: 100%;
  max-width: 350px;
  @media (max-width: 900px) {
    max-width: 100%;
  }
`;

export const ListContainer = styled.div`
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  margin-top: 23px;
  flex-direction: column;
  justify-content: 'center';
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 0; /* Remove the scrollbar width */
    height: 0; /* Remove the scrollbar height */
  }
`;

export const OrangeCardContainer = styled.div``;
