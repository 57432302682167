import styled from 'styled-components';

import theme from '../../utils/theme';

const { colors } = theme;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: 'center';
  align-items: center;
  height: auto;
  width: 100%;
  background: ${colors.cardTan};
  @media (max-width: 767px) {
    background: ${colors.backgroundTan};
  }
`;

export const ListIcon = styled.img`
  width: 22px;
  height: 22px;
  cursor: pointer;
  bottom: 0; /* Position the child container at the bottom */
  right: 0;
  background: ${colors.cardTan};
  position: absolute;
  margin-right: 23px;
  margin-bottom: 9px;
  @media (max-width: 767px) {
    background: ${colors.backgroundTan};
  }
`;

export const LocationsBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: 'center';
  height: 100%;
  width: 100%;
  background: ${colors.backgroundTan};
  @media (max-width: 767px) {
    height: 100%;
  }
`;

export const MapIcon = styled.img`
  width: 22px;
  height: 22px;
  cursor: pointer;
  bottom: 0; /* Position the child container at the bottom */
  right: 0;
  position: absolute;
  margin-right: 23px;
  margin-bottom: 9px;
  @media (max-width: 767px) {
    background: ${colors.backgroundTan};
  }
`;
export const SearchBarBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  background: ${colors.cardTan};
  position: relative;
  @media (max-width: 767px) {
    margin-bottom: 2%;
    background: ${colors.backgroundTan};
  }
`;

export const SearchBarWrapper = styled.div`
  width: 298px;
  background: ${colors.cardTan};
  display: flex;
  align-content: left;
  margin-left: 0px;

  @media (max-width: 767px) {
    background: ${colors.backgroundTan};
    width: 100%;
    padding: 0px 24px 0px 24px;
  }
`;

export const TextInputContainer = styled.div`
  width: 100%;
  background: ${colors.cardTan};
  margin-left: 35px;

  @media (max-width: 767px) {
    background: ${colors.backgroundTan};
    margin-left: 0px;
  }
`;
