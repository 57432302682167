import React, { useEffect, useState, useRef } from 'react';

import dayjs from 'dayjs';
import { useDispatch } from 'react-redux';

import operations, { getProfile } from './operations';
import {
  Container,
  Header,
  LastNameForm,
  Title,
  BoldTitle,
  ProblemsTitle2,
  TitleBox,
  ProblemsTitlesWrapper,
  DateOfBirthForm,
  DateOfBirthFormWrapper,
  ButtonsContainer,
  InputStyled,
  DateInputStyled,
  ProblemsTitle,
  ProblemsSubTitle,
  ProblemsBox,
  ErrorString,
  ErrorBox,
} from './styles';
import Button from '../../components/Button';
import ErrorModal from '../../components/ErrorModal';
import { useApi } from '../../hooks/useApi';
import {
  setCurrentLoginFlowScreen,
  setIsNewPatient,
} from '../../slices/loginSlice';
import { isValidBirthday } from '../../utils/functions';
import * as strings from '../../utils/strings';

interface FormAnwersProps {
  lastName: string;
  month: number;
  day: number;
  year: number;
}

const ExistingPatient = () => {
  const [windowSize, setWindowSize] = useState(window.innerWidth);

  const [formAnwers, setFormAnwers] = useState<FormAnwersProps>({
    lastName: '',
    month: 0,
    day: 0,
    year: 0,
  });

  const dispatch = useDispatch();

  const inputMonthRef = useRef<any>(null);
  const inputDayRef = useRef<any>(null);
  const inputYearRef = useRef<any>(null);

  const [monthError, setMonthError] = useState<boolean>(false);
  const [dayError, setDayError] = useState<boolean>(false);
  const [yearError, setYearError] = useState<boolean>(false);

  const [showProblemsSign, setShowProblemsSign] = useState<boolean>(false);
  const [isDisabled, setIsDisabled] = useState<boolean>(true);

  const [isMultipleMatch, setIsMultipleMatch] = useState<boolean>(false);

  const {
    loading: patientMatchLoading,
    error: errorPatientMatch,
    request: patientMatchReq,
    successResponse: existingPatinentResponse,
    errorString,
  } = useApi(operations.patientMatch);

  const validateExistingPatient = () => {
    patientMatchReq(formAnwers);
  };

  const handleWeRHavingProblemsButton = () => {
    dispatch(setIsNewPatient(true));
    dispatch(setCurrentLoginFlowScreen('chooseLocationsScreen'));
  };

  useEffect(() => {
    if (errorPatientMatch && errorString === 'Unable to match patients') {
      setShowProblemsSign(true);
    } else if (
      errorPatientMatch &&
      errorString === 'Patient Match - Mulitple Records Found'
    ) {
      setIsMultipleMatch(true);
    }
  }, [errorPatientMatch, errorString]);

  useEffect(() => {
    if (existingPatinentResponse) {
      dispatch(setCurrentLoginFlowScreen('chooseAppointmentTypeScreen'));
    }
  }, [existingPatinentResponse]);

  useEffect(() => {
    const { lastName, month, day, year } = formAnwers;
    if (lastName !== '' && isValidBirthday(`${month}`, `${day}`, `${year}`)) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [formAnwers]);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize(window.innerWidth);
    };
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  });

  const currentYear = dayjs().format('YYYY');

  return (
    <Container>
      {showProblemsSign ? (
        <ProblemsBox>
          <ProblemsTitlesWrapper>
            <ProblemsTitle>
              {strings.WE_ARE_HAVING_PROBLEMS_FINDING_YOUR_INFO_PART_1}
            </ProblemsTitle>
            <ProblemsTitle2>
              {strings.WE_ARE_HAVING_PROBLEMS_FINDING_YOUR_INFO_PART_2}
            </ProblemsTitle2>
          </ProblemsTitlesWrapper>

          <ButtonsContainer>
            <Button
              label={strings.CONTINUE}
              onPress={handleWeRHavingProblemsButton}
              mQueryCellphoneWidth="100%"
              width="339px"
            />
          </ButtonsContainer>
          {windowSize < 900 ? (
            <a
              href="tel:+1-312-846-6752"
              style={{ textDecoration: 'none', color: 'inherit' }}
            >
              <ProblemsSubTitle>
                {strings.IF_YOU_THNK_THIS_IS_INCORRECT}
              </ProblemsSubTitle>
            </a>
          ) : (
            <ProblemsSubTitle>
              {strings.IF_YOU_THNK_THIS_IS_INCORRECT}
            </ProblemsSubTitle>
          )}
        </ProblemsBox>
      ) : (
        <>
          <Header>{strings.IN_ORDER_TO_LOG_YOU_IN}</Header>
          <LastNameForm>
            <TitleBox>
              <Title>{strings.WHAT_IS_YOUR}</Title>
              <BoldTitle>{strings.LAST_NAME_BOLD}</BoldTitle>
            </TitleBox>
            <InputStyled
              type="text"
              value={formAnwers.lastName}
              onChange={event =>
                setFormAnwers(prevState => ({
                  ...prevState,
                  lastName: event.target.value.slice(0, 28),
                }))
              }
              placeholder={strings.LAST_NAME}
            />
          </LastNameForm>

          <DateOfBirthFormWrapper>
            <TitleBox>
              <Title>{strings.WHAT_IS_YOUR}</Title>
              <BoldTitle>{strings.DATE_OF_BIRTH_BOLD}</BoldTitle>
            </TitleBox>
            <DateOfBirthForm>
              <ErrorBox>
                <DateInputStyled
                  type="number"
                  min={1}
                  max={12}
                  value={formAnwers.month || undefined}
                  onChange={e => {
                    const { value } = e.target;
                    const regex = /^([1-9]|1[0-2])$/;
                    if (!value.length) {
                      setMonthError(false);
                      setFormAnwers(prevState => ({
                        ...prevState,
                        month: Number(value.slice(0, 2)),
                      }));
                    } else {
                      setMonthError(!regex.test(value) ?? false);
                      setFormAnwers(prevState => ({
                        ...prevState,
                        month: Number(value.slice(0, 2)),
                      }));
                    }
                  }}
                  placeholder={strings.MONTH}
                  ref={inputMonthRef}
                />
                {monthError && (
                  <ErrorString>{strings.INVALID_INPUT}</ErrorString>
                )}
              </ErrorBox>
              <ErrorBox>
                <DateInputStyled
                  type="number"
                  min={1}
                  max={31}
                  value={formAnwers.day || undefined}
                  onChange={e => {
                    const { value } = e.target;
                    const regex = /^(([1-9]|[1-2][0-9])|30|31)$/;
                    if (!value.length) {
                      setDayError(false);
                      setFormAnwers(prevState => ({
                        ...prevState,
                        day: Number(value.slice(0, 2)),
                      }));
                    } else {
                      setDayError(!regex.test(value) ?? false);
                      setFormAnwers(prevState => ({
                        ...prevState,
                        day: Number(value.slice(0, 2)),
                      }));
                    }
                  }}
                  placeholder={strings.DAY}
                  ref={inputDayRef}
                />
                {dayError && <ErrorString>{strings.INVALID_INPUT}</ErrorString>}
              </ErrorBox>
              <ErrorBox>
                <DateInputStyled
                  type="number"
                  min={1900}
                  max={+currentYear}
                  value={formAnwers.year || undefined}
                  onChange={e => {
                    const { value } = e.target;
                    const regex =
                      /^(19[0-9]{2}|20[0-2][0-9]|20[3-9][0-9]|21[0-9]{2})$/;
                    if (!value.length) {
                      setYearError(false);
                      setFormAnwers(prevState => ({
                        ...prevState,
                        year: Number(value.slice(0, 4)),
                      }));
                    } else {
                      setYearError(!regex.test(value) ?? false);
                      setFormAnwers(prevState => ({
                        ...prevState,
                        year: Number(value.slice(0, 4)),
                      }));
                    }
                  }}
                  placeholder={strings.YEAR}
                  ref={inputYearRef}
                />
                {yearError && (
                  <ErrorString>{strings.INVALID_INPUT}</ErrorString>
                )}
              </ErrorBox>
            </DateOfBirthForm>
          </DateOfBirthFormWrapper>
          <ButtonsContainer>
            <Button
              isLoading={patientMatchLoading}
              label={strings.CONTINUE}
              onPress={validateExistingPatient}
              disabled={isDisabled}
              mQueryCellphoneWidth="100%"
              width="40%"
            />
          </ButtonsContainer>
          <ErrorModal
            open={isMultipleMatch}
            setOpen={setIsMultipleMatch}
            title="Multiple Records Found"
            details="We found multiple patient records containing your information. Please contact your Dentologie office for further assistance at 312-846-6752."
          />
        </>
      )}
    </Container>
  );
};

export default ExistingPatient;
