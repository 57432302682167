import React from 'react';

import { useSelector } from 'react-redux';
import { Routes as RoutesDom, Route, Navigate } from 'react-router-dom';

import CommonDesign from '../components/CommonDesign';
import HomePageWidget from '../components/HomePageWidget';
import Dashboard from '../features/Dashboard';
import * as routes from '../utils/routes';

export const ProtectedRoute = ({ children }: any) => {
  const signedIn = useSelector((state: RootState) => state.session.loggedIn);
  if (!signedIn) {
    // user is not authenticated
    return <Navigate to={routes.WRAPPER_SCHEDULE} />;
  }
  return children;
};

export const UnProtectedRoute = ({ children }: any) => {
  const signedIn = useSelector((state: RootState) => state.session.loggedIn);
  return children;
};

const Routes = () => (
  <RoutesDom>
    <Route
      path={routes.DASHBOARD}
      element={
        <ProtectedRoute>
          <Dashboard />
        </ProtectedRoute>
      }
    />
    <Route path={routes.HOMEPAGE_WIDGET} element={<HomePageWidget />} />
    <Route
      path={routes.LOCATIONS}
      element={
        <UnProtectedRoute>
          <CommonDesign />
        </UnProtectedRoute>
      }
    />
    <Route
      path={routes.WRAPPER_SCHEDULE}
      element={
        <UnProtectedRoute>
          <CommonDesign />
        </UnProtectedRoute>
      }
    />
  </RoutesDom>
);

export default Routes;
