/* eslint-disable import/no-anonymous-default-export */
import client from './client';

const get = (endpoint: string, params?: any) => client.get(endpoint, params);
const post = (endpoint: string, postData: any, params?: any) =>
  client.post(endpoint, postData, params);
const put = (endpoint: string, putData: any) =>
  client.put(`${endpoint}/${putData.id}`, post);

export default {
  get,
  post,
  put,
};
