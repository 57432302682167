/* eslint-disable no-shadow */

// ep = existing patient event, np = new patient event, gl = global event
export enum EventNames {
  epClick = 'ep_existing_patient_click',
  epOtpLoginComplete = 'ep_otp_login_complete',
  epLastNameDobSubmitted = 'ep_lastname_dob_submitted',
  epApptTypeSelected = 'ep_appt_type_selected',
  epApptSlotSelected = 'ep_appt_slot_selected',
  epCofSubmitted = 'ep_cof_submitted',
  epApptBooked = 'ep_appt_booked',
  epUserNotFound = 'ep_user_not_found',
  epChangeLocation = 'ep_change_location',
  epChangeApptType = 'ep_change_appt_type',
  glWidgetLocationClick = 'widget_location_click',
  glAddToCalendar = 'add_to_calendar',
  glDownloadAndroidClick = 'download_android_click',
  glDownloadIosClick = 'download_ios_click',
  glApptHoldExpired = 'appt_hold_expired',
  glApptTypeInfoClick = 'appt_type_info_click',
  glCancelPolicyClick = 'cancellation_policy_click',
  npClick = 'np_new_patient_click',
  npLocationSelected = 'np_location_selected',
  npApptTypeSelected = 'np_appt_type_selected',
  npApptSlotSelected = 'np_appt_slot_selected',
  npPersInfoSubmitted = 'np_personal_info_submitted',
  npInsSelected = 'np_do_you_have_insurance_selected',
  npInsSubmitted = 'np_do_you_have_insurance_submitted',
  npCofSubmitted = 'np_cof_submitted',
  npVerifyPhoneComplete = 'np_verify_phone_complete',
  npApptBooked = 'np_appt_booked',
  npChangeLocation = 'np_change_location',
  npChangeApptType = 'np_change_appt_type',
  npTakeMyInsurance = 'np_do_you_take_my_insurance',
  npLoginBtnClick = 'np_login_button_click',
  npSeeInsurancePlans = 'np_see_insurance_plans',
  npApptNoLongerAvail = 'np_appt_no_longer_available',
}

export enum PageNames {
  apptBooked = 'appointmentBookedScreen',
  chooseApptTime = 'chooseApptScreen',
  chooseApptType = 'chooseAppointmentTypeScreen',
  chooseLocation = 'chooseLocationsScreen',
  dentalInsurance = 'doYouHaveDentalInsuranceScreen',
  existingPatient = 'existingPatientScreen',
  login = 'loginScreen',
  loginDetails = 'loginDetailsScreen',
  otp = 'oneTimePassScreen',
  patientData = 'patientDataScreen',
  payment = 'paymentScreen',
  verifyPhone = 'verifyPhoneScreen',
  homepageWidget = 'homepageWidget',
  hubSpotLocationPage = 'hubSpotLocationPage',
}

export enum ApptNames {
  epCleaningAdult = 'EXISTING_PATIENT_CLEANING_ADULT',
  epCleaningChild = 'EXISTING_PATIENT_CLEANING_CHILD',
  epInvisalign = 'EXISTING_PATIENT_INVISALIGN',
  epEmergency = 'EXISTING_PATIENT_EMERGENCY',
  npCleaningAdult = 'NEW_PATIENT_CLEANING_ADULT',
  npCleaningChild = 'NEW_PATIENT_CLEANING_CHILD',
  npInvisalign = 'NEW_PATIENT_INVISALIGN',
  npEmergency = 'NEW_PATIENT_EMERGENCY',
}

type EventType = `${EventNames}`;
export type PageType = `${PageNames}`;

interface EventData {
  apptType: string;
  insuranceDesc: string;
  locationId: string;
  loginType: 'phone' | 'email';
  patientType: 'new' | 'existing';
}

export interface GTMEvent {
  event: EventType;
  eventData?: Partial<EventData>;
  pageName: PageType;
}
