import styled from 'styled-components';
// eslint-disable-next-line import/no-unresolved
import theme from 'utils/theme';

export const Background = styled.div`
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background-color: ${theme.colors.ctaTeal};
`;

export const Label = styled.label`
  font-size: 16px;
  letter-spacing: 1.15px;
  font-weight: 700;
  color: ${theme.templateColors.gray5};
  font-family: ${theme.fonts.GintoMedium};
  cursor: pointer;

  @media (max-width: 767px) {
    font-size: 12px;
    margin-left: 2%;
    width: 80%;
  }
`;
export const Radio = styled.button`
  padding: 0;
  width: 19px;
  height: 19px;
  border-radius: 50%;
  border: 1px solid ${theme.templateColors.gray2};
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;

  @media (max-width: 767px) {
    width: 14px;
    height: 14px;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  margin: 10px 0px 10px 0px;
  cursor: pointer;
`;
