import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import operations from './operations';
import {
  FormContainer,
  ProgressBarContainer,
  LoginTitle,
  ButtonsContainer,
  InputStyled,
  OutsideContainer,
} from './styles';
import Button from '../../components/Button';
import ErrorModal from '../../components/ErrorModal';
import ProgressBar from '../../components/ProgressBar';
import { useApi } from '../../hooks/useApi';
import {
  setApptTypesOptionsBySelLocation,
  setSelectedAppointmentType,
} from '../../slices/appointmentSlice';
import { setSelectedLocation } from '../../slices/locationsSlice';
import {
  setCurrentLoginFlowScreen,
  setIsNewPatient,
  setSkipChooseAppointmentType,
} from '../../slices/loginSlice';
import { filterLocationSelected } from '../../utils/functions';
import { logGTMEvent } from '../../utils/gtm/gtmHelpers';
import { EventNames, PageNames } from '../../utils/gtm/gtmTypes';
import * as strings from '../../utils/strings';
import operationsApptTypes from '../ChooseAppointmentType/operations';
import { getAllLocations } from '../ChooseLocation/operations';

const LoginDetails = () => {
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(true);
  const [contact, setContact] = useState<string>('');
  const [openErrorModal, setOpenErrorModal] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [loginError, setLoginError] = useState<boolean>(false);
  const [isPhone, setIsPhone] = useState<boolean>(false);
  const { allLocations, locationDataWithAppt, locationCards } = useSelector(
    (state: RootState) => state.locations,
  );
  const { loggedIn } = useSelector((state: RootState) => state.session);
  const { appointmentTypes } = useSelector(
    (state: RootState) => state.appointment,
  );
  const {
    loading: loginLoading,
    request: loginReq,
    error: loginErrorReq,
    statusCode,
  } = useApi(operations.getOTP);
  const dispatch = useDispatch();

  useEffect(() => {
    setOpenErrorModal(loginErrorReq);
    setLoginError(loginErrorReq);
  }, [loginErrorReq]);

  useEffect(() => {
    if (!openErrorModal) {
      setLoginError(false);
    }
  }, [openErrorModal]);

  const { request: getAllLocationsReq } = useApi(getAllLocations);

  const { request: getAppointmentTypesReq } = useApi(
    operationsApptTypes.getAppointmentTypes,
  );

  useEffect(() => {
    const regex =
      /^(?:\d{10}|\(\d{3}\)-\d{3}-\d{4}|[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/;
    const isContactValid = regex.test(contact);
    if (isContactValid) {
      setIsButtonDisabled(false);
    } else {
      setIsButtonDisabled(true);
    }
  }, [contact]);

  const handleNewPatienButton = () => {
    dispatch(setIsNewPatient(true));
    if (searchParams.get('isEmergency') === 'true') {
      dispatch(setCurrentLoginFlowScreen('chooseAppointmentScreen'));
    } else if (searchParams.get('locationId')) {
      dispatch(setCurrentLoginFlowScreen('chooseAppointmentTypeScreen'));
    } else {
      dispatch(setCurrentLoginFlowScreen('chooseLocationsScreen'));
    }
  };

  const handleInputChange = async (e: any) => {
    let inputContact = e.target.value;
    // restrict maximum length of input to 14 characters (i.e. formatted phone number length)
    let formattedContact = inputContact;
    // check if input is phone number format
    const phoneRegex = /^(\d{3})[- .]?(\d{3})[- .]?(\d{4})$/;
    const phoneInputContact = inputContact.replace(/["'()-]/g, '');
    if (phoneRegex.test(phoneInputContact)) {
      setIsPhone(true);
      if (phoneInputContact.length > 10) {
        inputContact = inputContact.slice(0, 10);
      }
      formattedContact = phoneInputContact
        .replace(/\D/g, '')
        .replace(/(\d{3})(\d{3})(\d{4})/, '($1)-$2-$3');
    } else {
      setIsPhone(false);
    }
    // check if input is email format
    const emailRegex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;
    if (emailRegex.test(inputContact)) {
      formattedContact = inputContact.toLowerCase();
    }
    setContact(formattedContact);
  };

  const handleLogin = () => {
    dispatch(setIsNewPatient(false));
    loginReq({
      username: contact,
      method: contact.includes('@') ? 'EMAIL' : 'SMS',
    }).then(error => {
      setOpenErrorModal(true);
    });
  };

  useEffect(() => {
    const regex =
      /^(?:\d{10}|\(\d{3}\)-\d{3}-\d{4}|[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/;
    const isContactValid = regex.test(contact);
    if (isContactValid) {
      setIsButtonDisabled(false);
    } else {
      setIsButtonDisabled(true);
    }
  }, [contact]);

  useEffect(() => {
    if (statusCode === 500) setOpenErrorModal(true);
    if (statusCode === 404) {
      logGTMEvent({
        event: EventNames.epUserNotFound,
        pageName: PageNames.loginDetails,
      });
    }
  }, [statusCode]);

  useEffect(() => {
    getAppointmentTypesReq();
    getAllLocationsReq();
  }, []);

  useEffect(() => {
    if (searchParams) {
      const locationId = searchParams.get('locationId');
      const isEmergency = searchParams.get('isEmergency');
      if (locationId && locationCards) {
        const selectedLocation = locationCards.filter(
          (item: any) => item.id === locationId,
        );
        if (selectedLocation) {
          const filteredAppts = filterLocationSelected(
            selectedLocation[0].name,
            locationDataWithAppt,
          );
          dispatch(setSelectedLocation(selectedLocation[0]));
          dispatch(setApptTypesOptionsBySelLocation(filteredAppts));
        }
        if (isEmergency === 'true') {
          dispatch(setSelectedAppointmentType('EMERGENCY'));
          dispatch(setSkipChooseAppointmentType(true));
        }
      }
    }
  }, [searchParams, allLocations, locationDataWithAppt]);

  useEffect(() => {
    if (loggedIn) {
      dispatch(setCurrentLoginFlowScreen('chooseLocationsScreen'));
    }
  }, [loggedIn]);

  return (
    <OutsideContainer>
      <ProgressBarContainer>
        <ProgressBar initialValue={2} />
      </ProgressBarContainer>
      <FormContainer>
        <LoginTitle>{strings.WELCOME_BACK}</LoginTitle>
        <InputStyled
          type="text"
          id="value"
          maxLength={isPhone ? 10 : 100}
          value={contact}
          placeholder={strings.PHONE_OR_EMAIL}
          onChange={handleInputChange}
        />
      </FormContainer>
      <ButtonsContainer>
        <Button
          label={strings.CONTINUE}
          onPress={handleLogin}
          disabled={isButtonDisabled}
          mQueryCellphoneWidth="100%"
          isLoading={loginLoading}
          width="51.25%"
          marginTop="0"
        />
      </ButtonsContainer>
      <ErrorModal
        open={openErrorModal}
        setOpen={setOpenErrorModal}
        title={loginError ? strings.INVALID_LOGIN : undefined}
        details={loginError ? strings.LOGIN_ERROR : undefined}
      />
    </OutsideContainer>
  );
};

export default LoginDetails;
