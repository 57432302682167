import styled from 'styled-components';

import theme from '../../utils/theme';

export const Body = styled.div`
  width: 76.8%;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-content: center;
  align-items: center;
  margin-top: 10%;

  @media (max-width: 1170px) {
    margin-top: 20%;
  }

  @media (max-width: 900px) {
    margin-top: 20%;
    flex-direction: column;
    width: 300px;
    max-width: 100%;
    margin-bottom: 20%;
  }

  @media (max-width: 767px) {
    width: 76.8%;
  }
`;

export const BodyText = styled.p`
  font-family: ${theme.fonts.DomaineTextMedium};
  font-size: 180%;
  flex: 1;
  line-height: 54px;
  max-width: 74.5%;
  letter-spacing: 1.15px;
  margin-top: -13.5%;

  @media (max-width: 1170px) {
    height: 80%;
  }

  @media (max-width: 900px) {
    font-size: 25px;
    height: 80%;
    width: 275px;
  }
`;

export const SmileImg = styled.img`
  @media (max-width: 1170px) {
    bottom: 0;
    right: 0;
    margin-bottom: -20%;
    margin-right: -10%;
  }

  @media (max-width: 900px) {
    bottom: 0;
    right: 0;
    margin-bottom: -20%;
    margin-right: -10%;
  }
`;

export const BoxButton = styled.div`
  @media (max-width: 1170px) {
    margin-top: 10%;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 20%;
  width: 86%;
  @media (max-width: 1170px) {
    flex-direction: column;
    justify-content: center;
    margin-top: 5%;
    margin-bottom: 15%;
    width: 340px;
    height: 15%;
  }
`;
export const Container = styled.div`
  height: 50vh;
  width: 83%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-content: center;

  @media (max-width: 1170px) {
    height: auto;
  }
`;

export const Header = styled.div`
  height: 20%;
  padding-top: 5%;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
`;

export const ProgressBarBox = styled.div`
  width: 783px;

  @media (max-width: 1170px) {
    width: 300px;
  }
`;

export const Span = styled.span`
  font-weight: bold;
  text-transform: capitalize;
  font-family: ${theme.fonts.GintoBlack};
`;

export const Span3 = styled.span`
  font-weight: bold;
  font-family: ${theme.fonts.GintoBlack};
  @media (max-width: 1170px) {
    position: absolute;
    left: 0;
    bottom: 0;
    margin-bottom: -11%;
    font-size: 30px;
    line-height: 54px;
    letter-spacing: 1.15px;
    margin-left: 5%;
  }
`;

export const Title = styled.p`
  font-family: ${theme.fonts.GintoRegular};
  font-weight: 400;
  font-size: 18px;
  line-height: 18px;
  letter-spacing: 1.15px;
  text-align: center;

  @media (max-width: 1170px) {
    font-size: 15px;
  }
`;
