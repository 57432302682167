/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';

import { AxiosError } from 'axios';
import dayjs from 'dayjs';
import { parsePhoneNumber } from 'libphonenumber-js';
import OtpInput from 'react-otp-input';
import { useSelector, useDispatch } from 'react-redux';

import operations from './operations';
import {
  FormContainer,
  ProgressBarContainer,
  FormTitle,
  FormSubtitle,
  ButtonsContainer,
  OtherWaysBox,
  OtherWaysOne,
  InputStyled,
  InputBox,
  ErrorOneTimePassCode,
  ErrorWrapper,
  OutsideContainer,
  AppointmentOnHold,
  AppointmentOnHoldBox,
} from './styles';
import BackButton from '../../components/BackButton';
import Button from '../../components/Button';
import ErrorModal from '../../components/ErrorModal';
import ProgressBar from '../../components/ProgressBar';
import { useApi } from '../../hooks/useApi';
import {
  setCurrentLoginFlowScreen,
  setOTPToken,
} from '../../slices/loginSlice';
import {
  transformApptTypeString,
  validateSixNumbers,
} from '../../utils/functions';
import { logGTMEvent } from '../../utils/gtm/gtmHelpers';
import { EventNames, GTMEvent, PageNames } from '../../utils/gtm/gtmTypes';
import * as strings from '../../utils/strings';
import { bookAppointment } from '../ChooseAppointment/operations';
import operationsLogin from '../Login/operations';

interface OneTimePassCodeProps {
  backToLogin?: () => void;
  CountdownComponent: () => JSX.Element | null;
}

const VerifyPhone: React.FC<OneTimePassCodeProps> = ({
  CountdownComponent = () => null,
}) => {
  const { userPhone } = useSelector((state: RootState) => state.userData);

  const [errorMessage, setErrorMessage] = useState<string>('');
  const [isError, setIsError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(true);
  const [errorOneTimePassCode, setErrorOneTimePassCode] =
    useState<boolean>(false);
  const [enteredCode, setEnteredCode] = useState('');
  const [sendCodeTo] = useState<string>(userPhone);

  const { userEmailOrPhone } = useSelector((state: RootState) => state.session);

  const { allLocations, selectedLocation } = useSelector(
    (state: RootState) => state.locations,
  );
  const { selectedRawDate, selectedApptType, selectedAppointmentTime } =
    useSelector((state: RootState) => state.appointment);
  const lockedAppointmentId = useSelector(
    (state: RootState) => state.appointment?.lockApptData?.id,
  );

  /**
   * FIXME: The properties of 'selectedLocation' differ from those of 'allLocations' objects in the Redux store. 'requiresCof' is a property on 'allLocations' objects, but not on the 'selectedLocation' object, which is currently a React component. React components--non-serializable values more generally--should not be stored in Redux, but refactoring the way 'selectedLocation' is stored on state is a significant undertaking here. For now, grabbing 'requiresCof' from 'allLocations' based on 'locationId' is a sufficient ad-hoc solution. (Jira ticket DEN-866 - Stripe Integration)
   */
  const { id: selectedLocationId } = selectedLocation;
  const {
    config: { requiresCof },
  } = allLocations.find((location: any) => location.id === selectedLocationId);

  const dispatch = useDispatch();

  const handleSetInputValue = (otp: any) => {
    setEnteredCode(otp);
    const areInputFieldsCompleted = validateSixNumbers(otp);

    setIsButtonDisabled(!areInputFieldsCompleted);
  };

  const handleBookAppointment = async () => {
    if (requiresCof) {
      dispatch(setCurrentLoginFlowScreen('paymentScreen'));
    } else {
      try {
        setIsLoading(true);

        const { timeZone } = selectedLocation;
        // Format datetime object from global state as string (YYYY-MM-DD)
        const date = new Date(selectedRawDate).toISOString().split('T')[0];
        // Parse date in office's local timezone, format as ISO string
        const localDateTime = dayjs
          .tz(
            `${date} ${selectedAppointmentTime}`,
            'YYYY-MM-DD h:mmA',
            timeZone,
          )
          .toISOString();

        const bookApptParams = {
          locationId: selectedLocation?.id,
          appointmentTime: localDateTime,
          appointmentType: transformApptTypeString(selectedApptType),
          lockedAppointmentId: lockedAppointmentId,
        };

        await bookAppointment(bookApptParams);

        const eventArray: GTMEvent[] = [
          {
            event: EventNames.npCofSubmitted,
            pageName: PageNames.verifyPhone,
          },
          {
            event: EventNames.npApptBooked,
            pageName: PageNames.verifyPhone,
          },
        ];

        eventArray.map(event => logGTMEvent(event));

        setIsLoading(false);

        dispatch(setCurrentLoginFlowScreen('appointmentBookedScreen'));
      } catch (error) {
        setIsLoading(false);
        setIsError(true);
        setIsButtonDisabled(true);
        setErrorMessage(
          'There was an error booking your appointment. Please try again.',
        );
      }
    }
  };

  const handleValidateOtp = async () => {
    if (enteredCode && userPhone) {
      try {
        setIsLoading(true);

        const phoneNumber = parsePhoneNumber(userPhone, 'US');
        const phoneNumberFormatted = phoneNumber.format('E.164');

        const verifyRequestParams = {
          phone: phoneNumberFormatted,
          enteredCode,
        };

        await operations.createVerify(verifyRequestParams);

        logGTMEvent({
          event: EventNames.npVerifyPhoneComplete,
          pageName: PageNames.verifyPhone,
        });

        setIsLoading(false);

        await handleBookAppointment();
      } catch (error: any) {
        setIsLoading(false);
        setIsButtonDisabled(true);
        if (error?.response?.data?.because === 'InvalidPasscode') {
          setErrorOneTimePassCode(true);
        } else {
          setIsError(true);
          setErrorMessage(
            'There was an error verifying your phone number. Please try again.',
          );
        }
      }
    }
  };

  const handleSendCodeAgain = async () => {
    setErrorOneTimePassCode(false);
    try {
      setIsLoading(true);
      await operationsLogin.getOTP({
        username: userEmailOrPhone,
        verifyNewUser: true,
      });
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setIsError(true);
      setErrorMessage(
        'There was an error requesting a new passcode. Please try again.',
      );
    }
  };

  const handleBackButton = () => {
    dispatch(setCurrentLoginFlowScreen('doYouHaveDentalInsuranceScreen'));
  };

  return (
    <OutsideContainer>
      <BackButton onClick={handleBackButton} />
      <AppointmentOnHoldBox>
        <AppointmentOnHold>
          {strings.APPOINTMENT_ON_HOLD}
          <CountdownComponent />
        </AppointmentOnHold>
      </AppointmentOnHoldBox>
      <ProgressBarContainer>
        <ProgressBar initialValue={67} />
      </ProgressBarContainer>
      <FormContainer>
        <FormTitle>{strings.VERY_YOUR_PHONE}</FormTitle>
        <FormSubtitle>
          {strings.WE_SENT_A_CODE_TO} {sendCodeTo}
        </FormSubtitle>
        <InputBox>
          <OtpInput
            value={enteredCode}
            onChange={handleSetInputValue}
            numInputs={6}
            renderInput={props => <InputStyled {...props} />}
            shouldAutoFocus={false}
          />
        </InputBox>
        {errorOneTimePassCode && (
          <ErrorWrapper>
            <ErrorOneTimePassCode>
              {strings.THE_CODE_YOU_ENTERED_IS_INVALID}
            </ErrorOneTimePassCode>
          </ErrorWrapper>
        )}
      </FormContainer>
      <OtherWaysBox>
        <OtherWaysOne onClick={handleSendCodeAgain}>
          {strings.DONT_SEE_A_CODE}
        </OtherWaysOne>
      </OtherWaysBox>
      <ButtonsContainer>
        <Button
          isLoading={isLoading}
          label={strings.CONTINUE}
          onPress={handleValidateOtp}
          disabled={isButtonDisabled}
          mQueryCellphoneWidth="100%"
          width="338px"
        />
      </ButtonsContainer>
      <ErrorModal
        open={isError}
        setOpen={setIsError}
        title="Something went wrong"
        details={errorMessage}
      />
    </OutsideContainer>
  );
};

export default VerifyPhone;
