/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable max-len */
import React, { useRef, useState, useEffect } from 'react';

import { InView } from 'react-intersection-observer';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

import { CarouselContainer, CardContainer } from './styles';
import { setSelectedLocation } from '../../slices/locationsSlice';
import { setCurrentLoginFlowScreen } from '../../slices/loginSlice';
import { setSelectedMarkerName } from '../../slices/mapSlice';
import { logGTMEvent } from '../../utils/gtm/gtmHelpers';
import { EventNames, PageNames } from '../../utils/gtm/gtmTypes';

const Carousel = ({
  children,
  getActiveCard,
  setActiveMarker,
  markerArray,
}: any) => {
  const carouselRef = useRef<any>(null);
  const [isDragging, setIsDragging] = useState(false);
  const [activeCardName, setActiveCardName] = useState(0);

  const mouseCoords = useRef({
    startX: 0,
    startY: 0,
    scrollLeft: 0,
    scrollTop: 0,
  });

  const dispatch = useDispatch();
  const { activeMarkerName } = useSelector((state: RootState) => state.map);
  const { selectedLocation } = useSelector(
    (state: RootState) => state.locations,
  );
  const { isNewPatient } = useSelector((state: RootState) => state.session);

  const isMobile = window.innerWidth <= 900;

  useEffect(() => {
    // Update the active card index when the activeMarkerName prop changes
    const cardIndex = children.findIndex(
      (child: any) => child.props.children.props.title === activeMarkerName,
    );
    setActiveCardName(cardIndex);
  }, [activeMarkerName, children]);

  useEffect(() => {
    // Scroll to the active card when its name changes when user selected a different map marker
    const activeCard = carouselRef.current.children[activeCardName];
    if (activeCard) {
      const containerWidth = carouselRef.current.clientWidth;
      const cardWidth = activeCard.clientWidth;
      const cardLeft =
        (activeCard?.offsetLeft ?? 0) - carouselRef.current.offsetLeft;
      const scrollLeftStart1 = carouselRef.current.scrollLeft;
      const scrollLeftEnd = Math.max(
        0,
        cardLeft - (containerWidth - cardWidth) / 2,
      );
      const deltaX = scrollLeftEnd - scrollLeftStart1;
      const scrollTo1 = activeCardName === 0 ? 0 : deltaX + scrollLeftStart1;

      if (isMobile) {
        carouselRef.current.scrollTo({
          left: scrollTo1,
          behavior: 'smooth',
        });
      } else {
        const containerHeight = carouselRef.current.clientHeight;
        const cardHeight = activeCard.clientHeight;
        const cardTop =
          (activeCard?.offsetTop ?? 0) - carouselRef.current.offsetTop;
        const cardBottom = cardTop + cardHeight;
        const scrollBottom = Math.max(0, cardBottom - containerHeight / 2);
        const scrollTo = activeCardName === 0 ? 0 : scrollBottom;

        carouselRef.current.scrollTo({
          top: scrollTo,
          behavior: 'smooth',
        });
      }
    }
  }, [activeCardName, isMobile]);

  return (
    <CarouselContainer ref={carouselRef}>
      {children?.map((child: React.ReactElement<any, string>, index: number) =>
        isMobile ? (
          <InView
            style={{ width: '100%' }}
            onChange={(inView, entry) => {
              if (inView) {
                const activeMarkerLocation = markerArray.find(
                  (marker: any) =>
                    marker?.name === child?.props?.children?.props?.title,
                )?.location;
                setActiveMarker(activeMarkerLocation);
                dispatch(
                  setSelectedMarkerName(child?.props?.children?.props?.title),
                );
                dispatch(setSelectedLocation(child?.props?.children?.props));
              }
            }}
            threshold={0.95}
          >
            <CardContainer
              key={uuidv4()}
              onClick={(e: any) => {
                e.preventDefault();
                getActiveCard(child);
                dispatch(setSelectedLocation(child?.props?.children?.props));
                dispatch(
                  setCurrentLoginFlowScreen('chooseAppointmentTypeScreen'),
                );

                if (isNewPatient) {
                  logGTMEvent({
                    event: EventNames.npLocationSelected,
                    eventData: {
                      locationId: `${child?.props?.children?.props?.id}`,
                    },
                    pageName: PageNames.chooseLocation,
                  });
                }
              }}
            >
              {child}
            </CardContainer>
          </InView>
        ) : (
          <CardContainer
            key={uuidv4()}
            onClick={(e: any) => {
              e.preventDefault();
              getActiveCard(child);
              dispatch(setSelectedLocation(child?.props?.children?.props));
              dispatch(
                setCurrentLoginFlowScreen('chooseAppointmentTypeScreen'),
              );

              if (isNewPatient) {
                logGTMEvent({
                  event: EventNames.npLocationSelected,
                  eventData: {
                    locationId: `${child?.props?.children?.props?.id}`,
                  },
                  pageName: PageNames.chooseLocation,
                });
              }
            }}
          >
            {child}
          </CardContainer>
        ),
      )}
    </CarouselContainer>
  );
};

export default Carousel;
