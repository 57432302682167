import styled from 'styled-components';

import theme from '../../utils/theme';

const { colors, templateColors } = theme;

type AppointmentProps = {
  selected?: boolean;
};

export const Rotato = styled.img`
  -webkit-transform: rotate(180deg); /* Chrome and other webkit browsers */
  -moz-transform: rotate(180deg); /* FF */
  -o-transform: rotate(180deg); /* Opera */
  -ms-transform: rotate(180deg); /* IE9 */
  transform: rotate(180deg);
`;

export const AppointmentString = styled.p`
  font-family: ${theme.fonts.GintoRegular};
  font-size: 13px;
  font-weight: 400;
  line-height: 10px;
  letter-spacing: -0.3px;
  margin: 0;
  margin-left: 8px;
  text-align: left;

  @media (max-width: 1300px) {
    font-size: 13px;
    margin-left: 8px;
  }
`;

export const AvailableTimeCircle = styled.div<AppointmentProps>`
  display: flex;
  border: 1px solid ${templateColors.tan3};
  border-radius: 45px;
  height: 34px;
  width: 106px;
  margin: 10px;
  text-align: center;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  align-items: center;
  justify-content: center;
  font-family: ${theme.fonts.GintoRegular};
  font-weight: ${({ selected }) => (selected ? 700 : 400)};
  font-size: 16px;
  line-height: 16px;
  letter-spacing: -0.3px;
  cursor: pointer;

  background: ${({ selected }) =>
    selected ? theme.colors.ctaTeal : colors.cardTan};
`;

export const AvailableTimeTitle = styled.p`
  font-family: ${theme.fonts.GintoBold};
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: -0.3px;
  text-transform: uppercase;

  @media (max-width: 1300px) {
    font-size: 13px;
    margin: 0px;
    margin-bottom: 3%;
  }
`;

export const AvailableTimeTitleBox = styled.div`
  width: 100%;
  padding-left: 17px;

  @media (max-width: 1300px) {
    width: 100%;
    padding-left: 7px;
  }
`;

export const AvailableTimesBox = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: left;
  flex-grow: 1;

  @media (max-width: 1300px) {
    width: 100%;
    height: 20%;
    align-items: center;
  }
`;

export const AvailableTimesWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
`;

export const BackButtonBox = styled.div`
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  justify-content: center;
  margin: 0px;
`;

export const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  align-self: flex-end;
  align-items: center;
  justify-content: center;
  align-self: flex-end;
  @media (max-width: 1300px) {
    flex-direction: column;
    width: 100%;
  }
`;

export const CalendarBox = styled.div`
  display: flex;
  width: 100%;
  max-width: 338px;
  margin-top: 28px;
  justify-content: center;
  align-items: left;
  flex-direction: column;

  .react-calendar {
    width: 100%;
    align-items: left;
    justify-content: left;
    margin: 0px;
  }

  .react-calendar__navigation {
    background: ${colors.cardTan};
    border: none;
    text-align: left;
    position: relative;
    width: 100%;
    margin-bottom: 15px;
    @media (max-width: 1300px) {
      width: auto;
      padding-left: 0px;
    }
  }

  .react-calendar__navigation__label {
    background: ${colors.cardTan};
    border: none;
    font-family: ${theme.fonts.GintoBold};
    font-weight: 400;
    color: ${theme.colors.black};
    font-size: 18px;
    line-height: 19.8px;
    letter-spacing: -0.3px;
  }

  // next button
  .react-calendar__navigation__next-button {
    background: ${colors.cardTan};
    border: none;
    align-items: right;
    right: 0;
    position: absolute;
    height: 20px;
    margin-right: 4px;
    margin-bottom: 30px;
    @media (max-width: 1300px) {
      margin-right: 17px;
    }
  }

  // prev button
  .react-calendar__navigation__prev-button {
    background: ${colors.cardTan};
    border: none;
    align-items: right;
    position: absolute;
    height: 20px;
    margin-bottom: 30px;
    right: 0;
    margin-right: 42px;
    @media (max-width: 1300px) {
      margin-right: 55px;
    }
  }

  .react-calendar__month-view__weekdays {
    text-decoration: none;
    font-family: ${theme.fonts.GintoRegular};
    font-weight: 400;
    font-size: 14px;
    line-height: 140.79%;
    letter-spacing: 1.15px;
    text-align: left;
    cursor: pointer;
    text-transform: uppercase;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 0px;
    display: flex;
    justify-content: flex-start;
  }

  .react-calendar__month-view__weekdays__weekday {
    cursor: pointer;
    text-align: center;

    [aria-label='Monday'] {
      cursor: pointer;
      text-decoration: none;
      text-align: left;
    }
    [aria-label='Tuesday'] {
      cursor: pointer;
      text-decoration: none;
    }
    [aria-label='Wednesday'] {
      cursor: pointer;
      text-decoration: none;
    }
    [aria-label='Thursday'] {
      cursor: pointer;
      text-decoration: none;
    }
    [aria-label='Friday'] {
      cursor: pointer;
      text-decoration: none;
    }
    [aria-label='Saturday'] {
      cursor: pointer;
      text-decoration: none;
    }
    [aria-label='Sunday'] {
      cursor: pointer;
      text-decoration: none;
    }
  }

  .react-calendar__tile {
    background: ${colors.cardTan};
    border: none;
    font-family: ${theme.fonts.GintoRegular};
    font-weight: 400;
    font-size: 18px;
    line-height: 140.79%;
    letter-spacing: 1.15px;
    text-align: center;
    height: 50px;
    border-radius: 50%;
    cursor: pointer;
    z-index: 3;
  }

  .react-calendar__tile:enabled {
    color: ${theme.colors.black};
  }

  .current-day {
    position: relative;
    justify-content: center;
    align-items: center;
  }

  .current-day::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 43px;
    height: 43px;
    border-radius: 50%;
    border: 1px solid #d3d2be;
  }

  .react-calendar__month-view__days {
    background: ${colors.cardTan};
    border: none;
    font-family: ${theme.fonts.GintoRegular};
    font-weight: 400;
    font-size: 14px;
    line-height: 140.79%;
    letter-spacing: 1.15px;
    text-align: right;
  }

  .selected {
    position: relative;
  }

  .selected:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 43px;
    height: 43px;
    border-radius: 50%;
    background: ${theme.colors.ctaTeal} !important;
    z-index: -3;
  }

  .react-calendar__navigation__prev2-button {
    display: none;
  }

  .react-calendar__navigation__next2-button {
    display: none;
  }

  @media (max-width: 1300px) {
    width: 100%;
    max-width: 100%;
    height: 400px;

    .react-calendar__navigation__arrow {
      margin-left: 150px;
      position: absolute;
      height: 20px;
      margin-bottom: 30px;
    }
    .react-calendar__navigation__prev-button {
      margin-left: 200px;
    }
  }
`;

export const CalendarEllipseImage = styled.img`
  width: 8px;
  height: 8px;
`;

export const Container = styled.div`
  display: flex;
  width: 100%;
  max-width: 1000px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 34px 34px 224px 34px;
  @media (max-width: 1300px) {
    width: 100%;
    height: fit-content;
    padding: 34px 34px 34px 34px;
  }
  @media (max-width: 390px) {
    width: 100%;
    height: fit-content;
  }
`;

export const DropBox = styled.div`
  flex: 2;
  width: 100%;
  @media (max-width: 1300px) {
    flex: unset;
    margin-bottom: 20px;
  }

  ul {
    position: absolute !important;
    width: 100% !important;
    margin: 10px 0px 0px 0px !important;
  }
`;

export const DropBox2 = styled.div`
  flex: 1;
  @media (max-width: 1300px) {
    max-width: 80%;
    flex: unset;
  }
  ul {
    position: absolute !important;
    width: 100% !important;
    margin: 10px 0px 0px 0px !important;
  }
`;

export const DropdownContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  margin-top: -5px;
  margin-bottom: 30px;
  @media (max-width: 1300px) {
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const EmptyStateContainer = styled.div`
  width: 100%;
  padding: 20px 0px;
  padding-left: 17px;

  @media (max-width: 1300px) {
    width: 100%;
    padding-left: 7px;
  }
`;

export const EmptyStateText = styled.p`
  font-family: ${theme.fonts.GintoRegular};
  color: ${theme.templateColors.gray2};
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: -0.3px;
  text-transform: uppercase;

  @media (max-width: 1300px) {
    font-size: 13px;
    margin: 0px;
    margin-bottom: 3%;
  }
`;

export const Header = styled.p`
  font-family: ${theme.fonts.GintoBold};
  text-align: left;
  font-weight: 400;
  font-size: 18px;
  line-height: 16px;
  letter-spacing: 1.15px;

  @media (max-width: 1300px) {
    width: 100%;
    padding-left: 5px;
  }
`;

export const HeaderBox = styled.div`
  display: flex;
  width: 100%;
  height: 30%;
  flex-direction: column;
  margin-top: 0px;
  @media (max-width: 1300px) {
    height: 3%;
    margin-top: -14px;
  }
`;

export const HeaderTitleBox = styled.div`
  display: flex;
  @media (max-width: 1300px) {
    width: 100%;
    margin-top: 5%;
    height: 30px;
  }
`;

export const LeftBox = styled.div`
  display: flex;
  width: 50%;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  @media (max-width: 1300px) {
    height: 50%;
    width: 100%;
  }
`;

export const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 20px 0px;
`;

export const LocalTimeDisclaimer = styled.p`
  font-family: ${theme.fonts.GintoRegular};
  font-weight: 500;
  font-size: 15px;
  line-height: 140.79%;
  letter-spacing: 1.15px;
  margin-right: 2%;

  color: ${theme.templateColors.gray2};

  @media (max-width: 1300px) {
    font-size: 13px;
    margin: 0px;
    margin-bottom: 3%;
  }
`;

export const LocalTimeDisclaimerBox = styled.div`
  width: 100%;
  padding-left: 17px;
  margin-top: 16px;

  @media (max-width: 1300px) {
    width: 100%;
    padding-left: 7px;
  }
`;

export const ProgressBarContainer = styled.div`
  display: flex;
  width: 100%;
  height: 15%;
  margin-bottom: 30px;
  margin-top: 0px;
  @media (max-width: 1300px) {
    margin-top: -10px;
  }
`;

export const RightBox = styled.div`
  display: flex;
  width: 50%;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  @media (max-width: 1300px) {
    height: 50%;
    width: 100%;
    justify-content: center;
    align-items: center;
  }
`;

export const Title = styled.p`
  font-family: ${theme.fonts.DomineRegular};
  letter-spacing: 1.15px;
  color: ${colors.black};
  font-weight: 500;
  font-size: 30px;
  line-height: 140.79%;
  letter-spacing: 1.15px;
  margin-right: 20px;
  @media (max-width: 1300px) {
    font-size: 23px;
    width: 100%;
    margin: 0px;
  }
`;

export const WrapperBox = styled.div`
  display: flex;
  width: 100%;
  height: 75%;
  flex-direction: row;
  @media (max-width: 1300px) {
    flex-direction: column;
    height: 97%;
  }
`;
