import React from 'react';

import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

import { Container, CardWrapper, CardContainer } from './styles';
import Card from '../../../components/Card';
import OrangeCard from '../../../components/OrangeCard';
import {
  setApptTypesOptionsBySelLocation,
  setSelectedAppointmentType,
} from '../../../slices/appointmentSlice';
import {
  LocationCard,
  setSelectedLocation,
} from '../../../slices/locationsSlice';
import {
  setCurrentLoginFlowScreen,
  setSkipChooseAppointmentType,
} from '../../../slices/loginSlice';
import {
  filterLocationSelected,
  findNextEmergency,
  formatLocationName,
  getFriendlyDate,
} from '../../../utils/functions';
import { logGTMEvent } from '../../../utils/gtm/gtmHelpers';
import {
  ApptNames,
  EventNames,
  GTMEvent,
  PageNames,
} from '../../../utils/gtm/gtmTypes';

interface ListLocationsProps {
  arrayData: Array<LocationCard>;
  goToNextScreen: (location: any) => void;
}

const ListLocations = ({ arrayData, goToNextScreen }: ListLocationsProps) => {
  const markerArray = arrayData || [];

  const dispatch = useDispatch();

  const { locationCards, locationNextCleaningData, locationDataWithAppt } =
    useSelector((state: RootState) => state.locations);

  const { isNewPatient } = useSelector((state: RootState) => state.session);

  const nextEmergency = findNextEmergency(
    locationNextCleaningData,
    locationCards,
  );

  const {
    timeZoneString: nextEmergencyTimeZoneString,
    nextEmergency: nextEmergencyTimeString,
  } = nextEmergency;

  const abbreviateTimeZone = (timeZoneString: string) => {
    if (timeZoneString) {
      const split = timeZoneString.split(' ');
      let result = '';
      split.map(item => {
        result += item.charAt(0);
        return null;
      });
      return result;
    }
    return timeZoneString;
  };

  const abbreviatedTimezone = abbreviateTimeZone(nextEmergencyTimeZoneString);
  const nextEmergencyDateTime = dayjs(nextEmergencyTimeString);
  const nextEmergencyDayFormatted = getFriendlyDate(nextEmergencyDateTime);
  const nextEmergencyTimeFormatted = nextEmergencyDateTime.format('h:mm A');

  const handleOrangeCardClick = () => {
    const filteredLocationSelected = filterLocationSelected(
      formatLocationName(nextEmergency?.locationName),
      locationDataWithAppt,
    );
    const location = markerArray.find(
      marker => marker.name === formatLocationName(nextEmergency.locationName),
    );
    dispatch(setSkipChooseAppointmentType(true));
    dispatch(setSelectedLocation(location));
    dispatch(setApptTypesOptionsBySelLocation(filteredLocationSelected));
    dispatch(setSelectedAppointmentType('EMERGENCY'));
    dispatch(setCurrentLoginFlowScreen('chooseAppointmentScreen'));

    if (isNewPatient) {
      // Backfill GTM event on emergency card click
      const eventArray: GTMEvent[] = [
        {
          event: EventNames.npLocationSelected,
          eventData: {
            locationId: `${location?.id}`,
          },
          pageName: PageNames.chooseLocation,
        },
        {
          event: EventNames.npApptTypeSelected,
          eventData: {
            apptType: ApptNames.npEmergency,
          },
          pageName: PageNames.chooseLocation,
        },
      ];

      eventArray.map(event => logGTMEvent(event));
    }
  };

  return (
    <Container>
      <OrangeCard
        title={
          markerArray?.length
            ? `Next Emergency: ${nextEmergencyDayFormatted} at ${nextEmergencyTimeFormatted} ${abbreviatedTimezone}`
            : 'No locations found'
        }
        onClick={handleOrangeCardClick}
        subtitle={formatLocationName(nextEmergency.locationName)}
        marginTop="1%"
        marginLeft
        width
        height="40px"
      />
      <CardWrapper>
        {arrayData?.map((loc: any) => (
          <CardContainer>
            <Card
              key={uuidv4()}
              id={loc.id}
              title={loc.name}
              description={
                isNewPatient
                  ? loc.nextNewPatientCleaning
                  : loc.nextReturningPatientCleaning
              }
              fullAddress={loc.fullAddress}
              subtitle={loc.address}
              displayWarningIcon={false}
              onClick={() => goToNextScreen(loc)}
              width="100%"
            />
          </CardContainer>
        ))}
      </CardWrapper>
    </Container>
  );
};

export default ListLocations;
