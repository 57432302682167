import { combineReducers, configureStore } from '@reduxjs/toolkit';
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import appointmentReducer from '../slices/appointmentSlice';
import locationsReducer from '../slices/locationsSlice';
import sessionReducer from '../slices/loginSlice';
import mapReducer from '../slices/mapSlice';
import scheduleReducer from '../slices/scheduleSlice';
import userDataReducer from '../slices/userDataSlice';

const rootReducer = combineReducers({
  session: sessionReducer,
  userData: userDataReducer,
  map: mapReducer,
  appointment: appointmentReducer,
  schedule: scheduleReducer,
  locations: locationsReducer,
});

// Configure persistence with whitelist
const persistConfig = {
  key: 'root',
  storage,
  whitelist: [],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

declare global {
  type RootState = ReturnType<typeof rootReducer>;
}

const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const persistor = persistStore(store);

export default store;
