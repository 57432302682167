import React from 'react';

import { Background, Label, Radio, Wrapper } from './styles';

interface RadioButtonProps {
  label: string;
  isSelected: boolean;
  onPress: () => void;
}

const RadioButton = ({ label, isSelected, onPress }: RadioButtonProps) => (
  <Wrapper onClick={onPress}>
    <Radio>{isSelected && <Background />}</Radio>
    <Label>{label}</Label>
  </Wrapper>
);

export default RadioButton;
