import React from 'react';

import {
  CancelButton,
  Detail,
  Title,
  CancelBoxButton,
  TextBox,
} from './styles';
import { CancelIcon } from '../../assets/images';
import * as strings from '../../utils/strings';
import Button from '../Button';
import Modal from '../Modal';

interface ErrorModal {
  open: boolean;
  setOpen: (value: boolean) => void;
  title?: string;
  details?: string;
  acceptButton?: string;
  cancelButton?: string;
  showContinue?: boolean;
  onContinue?: () => void;
  onCancel?: () => void;
  buttonText?: string;
}

const ErrorModal = ({
  open,
  setOpen,
  title = strings.SOMETHING_WENT_WRONG,
  details = strings.WE_ARE_SORRY_SOMETHING_WENT_WRONG,
  showContinue = false,
  onContinue = () => null,
  onCancel = () => null,
  buttonText,
}: ErrorModal) => (
  <Modal open={open} setOpen={setOpen}>
    <CancelBoxButton>
      <CancelButton
        onClick={() => {
          setOpen(false);
          onCancel();
        }}
        src={CancelIcon}
        alt={strings.CANCEL_ICON}
      />
    </CancelBoxButton>
    <TextBox>
      <Title>{title}</Title>
      <Detail>{details}</Detail>
    </TextBox>
    {showContinue && (
      <Button label={buttonText || 'Continue'} onPress={onContinue} />
    )}
  </Modal>
);

export default ErrorModal;
