import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface UserData {
  userName: string;
  userLastName: string;
  userEmail: string;
  userBirthDate: string;
  userPhone: string;
  preferredLocation: string;
  newUserFormData: {
    firstName: string;
    lastName: string;
    phoneNumber: string;
    email: string;
    dob: string;
    lockedSlotId: string;
    appointmentType: string;
  };
}

const initialUserData: UserData = {
  userName: '',
  userLastName: '',
  userEmail: '',
  userBirthDate: '',
  userPhone: '',
  preferredLocation: '',
  newUserFormData: {
    firstName: '',
    lastName: '',
    phoneNumber: '',
    email: '',
    dob: '',
    lockedSlotId: '',
    appointmentType: '',
  },
};

const userDataSlice = createSlice({
  name: 'userDataSlice',
  initialState: initialUserData,
  reducers: {
    setUserName: (state, { payload }: PayloadAction<any>) => {
      state.userName = payload;
    },
    setUserLastName: (state, { payload }: PayloadAction<any>) => {
      state.userLastName = payload;
    },
    setUserEmail: (state, { payload }: PayloadAction<any>) => {
      state.userEmail = payload;
    },
    setUserPhone: (state, { payload }: PayloadAction<any>) => {
      state.userPhone = payload;
    },
    setUserBirthDate: (state, { payload }: PayloadAction<any>) => {
      state.userBirthDate = payload;
    },
    setNewUserFormData: (
      state,
      { payload }: PayloadAction<UserData['newUserFormData']>,
    ) => {
      state.newUserFormData = payload;
    },
    setUser: (state, { payload }: PayloadAction<any>) => {
      state.userName = payload.firstName;
      state.userLastName = payload.lastName;
      state.userEmail = payload.emailAddress;
      state.userPhone = payload.phones[0].number;
      state.userBirthDate = payload.dateOfBirth;
      state.preferredLocation = payload.preferredLocation;
    },
  },
});
export default userDataSlice.reducer;

export const {
  setUserName,
  setUserLastName,
  setUserEmail,
  setUserPhone,
  setUserBirthDate,
  setNewUserFormData,
  setUser,
} = userDataSlice.actions;
