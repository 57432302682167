import React from 'react';

import { ButtonContainer, ProgressCircle } from './styles';

interface ButtonProps {
  isLoading?: boolean;
  label: string;
  mode?: 'primary' | 'secondary';
  onPress?: () => void;
  disabled?: boolean;
  height?: string;
  width?: string;
  mQueryCellphoneWidth?: string;
  marginTop?: string;
  id?: string;
}

const Button: React.FC<ButtonProps> = ({
  isLoading = false,
  label,
  mode = 'primary',
  onPress = () => null,
  disabled = false,
  height = '57px',
  width = '100%',
  mQueryCellphoneWidth = '0%',
  marginTop,
  id,
}) => (
  <ButtonContainer
    mode={mode}
    disabled={disabled}
    height={height}
    width={width}
    mQueryCellphoneWidth={mQueryCellphoneWidth}
    onClick={onPress}
    marginTop={marginTop}
    id={id}
  >
    {label}
    {isLoading && <ProgressCircle mode={mode} disabled={disabled} />}
  </ButtonContainer>
);

export default Button;
