/* eslint-disable max-len */
/* eslint-disable sort-exports/sort-exports */
import dayjs, { Dayjs } from 'dayjs';
import advanced from 'dayjs/plugin/advancedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isToday from 'dayjs/plugin/isToday';
import isTomorrow from 'dayjs/plugin/isTomorrow';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

import theme from './theme';
import { ToothLogoMarker } from '../assets/images';
import { LocationCard, NextAvailableLocation } from '../slices/locationsSlice';

const { templateColors } = theme;

dayjs.extend(isToday);
dayjs.extend(isTomorrow);
dayjs.extend(customParseFormat);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advanced);

/**
 * FIXME: RegEx and string manipulation are used far too frequently throughout this file. Refactor and implement libraries for working with the objects in question. Use dayJS for working with datetimes and libphonenumber.js for phone numbers rather than manipulating them manually.
 */

interface MonthMap {
  [key: string]: string;
}

export const convertTime = (time: string, timeZone: string) => {
  if (!time) {
    return null;
  }
  return new Date(time).toLocaleString('en-US', {
    timeZone: timeZone,
  });
};

const isSameDay = (date1: Date, date2: Date) =>
  date1.getDate() === date2.getDate() &&
  date1.getMonth() === date2.getMonth() &&
  date1.getFullYear() === date2.getFullYear();

export const nextDate = (dateInput: any) => {
  if (
    dateInput === null ||
    dateInput.includes('FAILED') ||
    dateInput.includes('Invalid Date')
  ) {
    return '';
  }
  // transform date for next cleaning date
  const inputDate = new Date(dateInput);
  const date = new Date(
    //TODO: Keep this in case we are reverting to users Time
    // inputDate.getTime() + inputDate.getTimezoneOffset() * 60000,
    inputDate.getTime(),
  );
  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(tomorrow.getDate() + 1);

  if (isSameDay(date, today)) return 'Today';
  if (isSameDay(date, tomorrow)) return 'Tomorrow';

  return new Intl.DateTimeFormat('en-US', {
    month: 'long',
    day: 'numeric',
  }).format(date);
};

export const transformDate = (inputDate: any) => {
  const date = new Date(inputDate); // convert input string to Date object
  const year = date.getFullYear(); // get full year (e.g. 2023)
  const month = String(date.getMonth() + 1).padStart(2, '0'); // get month (0-11) and convert to string with leading zero if needed (e.g. '05')
  const day = String(date.getDate()).padStart(2, '0'); // get day (1-31) and convert to string with leading zero if needed (e.g. '17')
  return `${year}-${month}-${day}`;
};

export const transformDateFormat = (dateString: string): string => {
  const parts = dateString.split('/');
  const transformedDate = `${parts[2]}-${parts[0]}-${parts[1]}`;
  return transformedDate;
};

export const transformMonth = (monthName: string) => {
  const monthMap: MonthMap = {
    January: '01',
    February: '02',
    March: '03',
    April: '04',
    May: '05',
    June: '06',
    July: '07',
    August: '08',
    September: '09',
    October: '10',
    November: '11',
    December: '12',
  };

  return monthMap[monthName];
};

export const transformPhoneNumber = (phoneNumber: string): string => {
  const transformedNumber = `+1${phoneNumber.replace(/\D/g, '')}`;
  return transformedNumber;
};

export const transformString = (str: string) =>
  str.replace(/_([A-Z])/g, ' $1').trim();

export const addDurationsToTypes = (data: any, array: any) => {
  const newArray: { name: any; supportedAppointmentTypes: any[] }[] = [];

  // map through open locations
  data?.forEach((loc: any) => {
    const supportedTypes = loc.config.supportedAppointmentTypes;
    const appointmentTypes: { type: any; duration: any; name: any }[] = [];

    // loop through supported types
    supportedTypes.forEach((type: any) => {
      const matchingType = array?.find((t: any) => t.type === type);
      if (matchingType) {
        appointmentTypes.push({
          type,
          duration: matchingType.length,
          name: matchingType.type.replace(/_/g, ' '),
        });
      }
    });

    // add location object to newArray
    newArray.push({
      name: loc.locationName,
      supportedAppointmentTypes: appointmentTypes,
    });
  });

  const convertArray = (newArrayResults: any) => {
    const newArrayTwo: {
      name: any;
      supportedAppointmentTypes: any[];
    }[] = [];

    newArrayResults.forEach((loc: any) => {
      const appointmentTypes: { type: any; duration: any; name: any }[] = [];

      loc?.supportedAppointmentTypes?.forEach((type: any) => {
        appointmentTypes.push({
          type: type.type,
          duration: type.duration,
          name: type.name,
        });
      });

      newArrayTwo.push({
        name: loc.name,
        supportedAppointmentTypes: appointmentTypes,
      });
    });

    return newArrayTwo;
  };

  return convertArray(newArray);
};

export const convertAllTimes = (
  arrayToChange: any,
  arrayWithTimezones: any,
) => {
  const newArray = arrayToChange.map((item: any) => {
    const tempItem = { ...item };
    const timeZone = arrayWithTimezones.filter(
      (arrItem: any) => arrItem.locationName === item.locationName,
    );
    tempItem.nextNewPatientCleaning = convertTime(
      tempItem.nextNewPatientCleaning,
      timeZone.timeZone,
    );
    tempItem.nextReturningPatientCleaning = convertTime(
      tempItem.nextReturningPatientCleaning,
      timeZone.timeZone,
    );
    tempItem.nextEmergency = convertTime(
      tempItem.nextEmergency,
      timeZone.timeZone,
    );

    tempItem.timeZoneString = dayjs().tz(timeZone[0]?.timeZone).format('zzz');

    tempItem.next.map((nextItem: any, index: number) => {
      tempItem.next[index] = {
        type: nextItem.type,
        time: convertTime(nextItem.time, timeZone.timeZone),
      };
      return tempItem.next[index];
    });
    return tempItem;
  });
  return newArray;
};

export const convertApptType = (arr: any) =>
  arr.map((str: string) => {
    // replace underscores with spaces
    let modifiedStr = str.replace(/_/g, ' ').toLowerCase();

    // capitalize first letter of each word
    modifiedStr = modifiedStr.replace(/\b\w/g, l => l.toUpperCase());

    return modifiedStr;
  });

export const convertToUTC = (dateString: any, timeString: any) => {
  if (dateString && timeString) {
    const currentYear = new Date().getFullYear();
    const dateParts = dateString.split(' ');
    const month = transformMonth(dateParts[0]);
    let day = dateParts[1].replace(/\D/g, ''); // Remove non-digit characters from day
    if (day?.length === 1) {
      day = `0${day}`;
    }
    const timeParts = timeString.match(/\d+:\d+/);
    const time = timeParts ? timeParts[0] : '';
    const isPM = timeString.includes('PM');

    let hour = parseInt(time.split(':')[0], 10);
    if (isPM && hour !== 12) {
      hour += 12;
    } else if (!isPM && hour === 12) {
      hour = 0;
    }
    const dateTimeString = `${currentYear}-${month}-${day}T${hour
      .toString()
      .padStart(2, '0')}:${time.split(':')[1]}:00.000Z`;
    return dateTimeString;
  }
  return null;
};

export const filterLocationSelected = (
  selectedLocation: string,
  locationsArr: any[],
) => {
  if (locationsArr) {
    return locationsArr?.filter(
      (location: any) =>
        location.locationName === selectedLocation ||
        location.name === selectedLocation,
    );
  }
  return locationsArr;
};

export const filterOnlyNotErrorLocations = (locationsArr: any) =>
  locationsArr?.filter((location: any) => !location.error);

const getDaySuffix = (day: any) => {
  if (day >= 11 && day <= 13) {
    return 'th';
  }
  switch (day % 10) {
    case 1:
      return 'st';
    case 2:
      return 'nd';
    case 3:
      return 'rd';
    default:
      return 'th';
  }
};

export const filterOpenAndEnabledLocations = (locationsArr: any) =>
  locationsArr?.filter(
    (location: any) =>
      location.config?.locationStatus === 'OPEN' &&
      location.config?.scheduleEnabled,
  );

/* Each selected day is composed of a number of provider-operatory (i.e. "doctor/hygenist"-"dental suite") allocations. Each of those allocations contains an array of time slots during which that particular allocation can be booked for appointments.

The function below maps through each allocation to find the slots with a long enough duration to accommodate the selected appointment type. If the allocation's slot is long enough, the function calculates how many appointments of the same type can fit into it. It finds the start times for each of those appointments, formats them and, if they aren't already included, adds them to an array. */
export const findDailyAvailability = (
  selectedDate: string,
  calendarData: any,
  apptDuration: number,
) => {
  const transformedDate = transformDate(selectedDate);
  const selectedDay = calendarData[transformedDate];

  if (selectedDay) {
    const appointments = selectedDay.reduce((appts: any, allocation: any) => {
      allocation.slots.forEach((slot: any) => {
        const start = dayjs(slot.start, 'YYYY-MM-DD HH:mm:ss');
        const end = dayjs(slot.end, 'YYYY-MM-DD HH:mm:ss');

        const isEnoughTime = start.add(apptDuration, 'minutes') <= end;

        if (isEnoughTime) {
          const getSlotLength = (startDate: any, endDate: any) => {
            const startTime = new Date(startDate).getTime();
            const endTime = new Date(endDate).getTime();
            const durationInMs = endTime - startTime;
            const durationInMinutes = durationInMs / (1000 * 60);
            return durationInMinutes;
          };
          const slotLength = getSlotLength(start, end);

          const availableBookings = Math.floor(+slotLength / apptDuration);

          // eslint-disable-next-line no-plusplus
          for (let i = 0; i < availableBookings; i++) {
            const formattedStart = start.format('h:mmA');
            const apptTime = start
              .add(apptDuration * i, 'minutes')
              .format('h:mmA');
            if (i === 0 && !appts.includes(formattedStart)) {
              appts.push(formattedStart);
            } else if (!appts.includes(apptTime)) {
              appts.push(apptTime);
            }
          }
        }
      });

      return appts;
    }, []);

    const apptsSorted = appointments.sort((a: any, b: any) => {
      const timeA = new Date(
        `1970-01-01 ${a.toLowerCase().replace(/(am|pm)/g, ' $1')}`,
      );
      const timeB = new Date(
        `1970-01-01 ${b.toLowerCase().replace(/(am|pm)/g, ' $1')}`,
      );
      return timeA.getTime() - timeB.getTime();
    });

    return apptsSorted;
  }

  return [];
};

export const formatDate = (
  inputDate: string | number | Date,
  formatOptions?: Intl.DateTimeFormatOptions,
) => {
  if (
    typeof inputDate !== 'string' &&
    typeof inputDate !== 'number' &&
    Object.prototype.toString.call(inputDate) !== '[object Date]'
  )
    return ''; // date constructor type guard
  const date = new Date(inputDate);
  const options =
    formatOptions ||
    ({
      weekday: 'long',
      month: 'long',
      day: 'numeric',
    } as Intl.DateTimeFormatOptions);
  return date.toLocaleDateString('en-US', options);
};

export const formatSelectedApptTime = (
  dateTimeString: string,
  timeString: string,
) => {
  const dateOptions: Intl.DateTimeFormatOptions = {
    weekday: 'short',
    month: 'long',
    day: 'numeric',
  };

  const [hours, minutes, period] =
    timeString.match(/(\d+):(\d+)(AM|PM)/)?.slice(1) || [];
  const date = new Date(dateTimeString);

  if (hours && minutes && period) {
    let formattedHours = parseInt(hours, 10);
    const formattedMinutes = parseInt(minutes, 10);

    if (period === 'PM' && formattedHours < 12) {
      formattedHours += 12;
    } else if (period === 'AM' && formattedHours === 12) {
      formattedHours = 0;
    }

    date.setHours(formattedHours, formattedMinutes);
  }

  const formattedDate = date.toLocaleDateString('en-US', dateOptions);
  const formattedTime = date.toLocaleTimeString('en-US', {
    hour: 'numeric',
    minute: '2-digit',
    hour12: true,
  });

  return `${formattedDate} at ${formattedTime}`;
};

export const getAppointmentDuration = (
  appointmentType: string,
  appointmentTypes: any,
) =>
  appointmentTypes?.filter(
    (appointment: any) => appointment.type === appointmentType,
  );

export const getDayOfMonth = (dateString: string) => {
  const match = dateString.match(/\d+/);
  if (match) {
    return parseInt(match[0], 10);
  }
  return null;
};

export const getMonthAndDay = (dateString: any) => {
  const date = new Date(dateString);
  const formatter = new Intl.DateTimeFormat('en-US', {
    month: 'long',
    day: 'numeric',
  });
  const monthAndDay = formatter.format(date);
  const daySuffix = getDaySuffix(date.getDate());
  return monthAndDay.replace(/\d+/, match => `${match}${daySuffix}`);
};

const formatTime = (timestamp: string) => {
  if (timestamp === null || timestamp?.includes('FAILED')) {
    return '';
  }
  const date = new Date(timestamp);
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const ampm = hours >= 12 ? 'PM' : 'AM';
  const formattedHours = hours % 12 || 12;
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
  return `${formattedHours}:${formattedMinutes} ${ampm}`;
};

export const getMonthFromDate = (dateString: string) => {
  const dateWithoutSuffix = dateString.replace(/(st|nd|rd|th)/g, '');
  const date = new Date(`${dateWithoutSuffix} 2023`);
  const month = date.getMonth() + 1;
  return month;
};

export const formatLocationName = (name: string) => {
  if (name && name.includes('Dentologie- ')) {
    return name.replace('Dentologie- ', '');
  }
  return name;
};

export const matchApptTypeWithNextDate = (
  array1: any,
  array2: any,
  locationConfig: any[],
) => {
  const newArray = array1?.map((location: any) => {
    const configs = locationConfig.find(
      (config: any) => config.id === location.id,
    );
    const supportedTypes = configs.supportedAppointmentTypes;
    const types = location?.next
      .filter((availability: any) =>
        supportedTypes?.includes(availability.type),
      )
      .map((availability: any) => {
        const typeData = array2.find(
          (item: any) => item.type === availability.type,
        );
        return {
          type: availability.type,
          displayName: transformString(availability.type),
          nextCleaning:
            availability.time === null || availability?.time.includes('FAILED')
              ? 'Next available: See Schedule'
              : `Next available: ${nextDate(
                  availability?.time,
                )} at ${formatTime(availability?.time)}`,
          duration: typeData.length,
          staff: typeData.staff,
        };
      });
    return {
      locationName: formatLocationName(location.locationName),
      types: types,
    };
  });
  return newArray;
};

export const matchLocWithNextDate = (nextCleaningArr: any, locationsArr: any) =>
  nextCleaningArr?.map((obj: any) => {
    const location = locationsArr?.find(
      (loc: any) => loc.id === obj.locationId,
    );
    return {
      id: location?.id,
      name: location ? formatLocationName(location?.locationName) : '',
      address: location?.address.address1,
      zipCode: location.address.postalCode,
      fullAddress: location?.address,
      nextNewPatientCleaning:
        !obj?.nextNewPatientCleaning ||
        obj?.nextNewPatientCleaning.includes('FAILED')
          ? ''
          : ` Next Adult Cleaning: ${nextDate(obj?.nextNewPatientCleaning)}`,
      nextReturningPatientCleaning:
        !obj.nextReturningPatientCleaning ||
        obj?.nextReturningPatientCleaning.includes('FAILED')
          ? ''
          : ` Next Adult Cleaning: ${nextDate(
              obj?.nextReturningPatientCleaning,
            )}`,
      nextEmergency: obj?.nextNewPatientCleaning?.includes('FAILED')
        ? ''
        : `${nextDate(obj?.nextEmergency)} at ${formatTime(
            obj?.nextEmergency,
          )}`,
      icon: ToothLogoMarker,
      timeZone: location?.timeZone,
      timeZoneString: obj?.timeZoneString,
      location: {
        lat: parseFloat(location?.config?.coordinates?.lat),
        lng: parseFloat(location?.config?.coordinates?.long),
      },
    };
  });

type CalendarDay = Record<string, []>;

export const reshapeCalendarData = (data: CalendarDay) =>
  Object.keys(data)
    .filter(key => data[key as keyof typeof data]?.length)
    .reduce((acc: any, key) => {
      acc[key] = {
        selected: true,
        marked: true,
        dotColor: templateColors.tan4,
      };
      return acc;
    }, {});

export const transformApptTypeString = (input: string): string => {
  const transformedString = input.replace(/\s+/g, '_');
  return transformedString;
};

export const validateSixNumbers = (value: string) =>
  (typeof value === 'string' && /^\d{6}$/.test(value)) ?? false;

export const formatApptType = (apptType: string) =>
  apptType?.toLowerCase()?.replaceAll('_', ' ');

export const findTimeZone = (
  allLocations: LocationCard[],
  locationId: string,
) => {
  const locationWithTimezone = allLocations.find(
    location => location.id === locationId,
  );

  if (locationWithTimezone) {
    const { timeZone, timeZoneString } = locationWithTimezone;
    return { timeZone, timeZoneString };
  }
  return { timeZone: '', timeZoneString: '' };
};

export const findNextEmergency = (
  nextLocationList: NextAvailableLocation[],
  allLocations: LocationCard[],
) => {
  const currentDateTime = dayjs();

  if (nextLocationList) {
    const nextEmergency = nextLocationList
      // Locations where scheduleEnabled === false return 'FAILED'. Filter them out.
      .filter(location => location.nextEmergency !== 'FAILED')
      // Convert datetime string to dayjs object.
      .map(location => ({
        ...location,
        nextEmergency:
          location.nextEmergency === null
            ? null
            : dayjs(location.nextEmergency),
      }));
    // Dentrix sends all unbooked slots regardless of current time. Filter out ones before current time.
    // .filter(location => !location.nextEmergency.isBefore(currentDateTime));

    if (nextEmergency.length && nextEmergency !== null) {
      // Find the earliest one.
      const emerg = nextEmergency
        .filter(item => item.nextEmergency !== null)
        .reduce((acc, cur) =>
          acc &&
          acc.nextEmergency !== null &&
          acc.nextEmergency.isBefore(cur.nextEmergency)
            ? acc
            : cur,
        );

      // Timezone is not included on /locations/next response. Find it.
      const { timeZone, timeZoneString } = findTimeZone(
        allLocations,
        emerg.locationId,
      );

      return {
        ...emerg,
        timeZone,
        timeZoneString,
        nextEmergency: emerg?.nextEmergency?.toISOString(),
      };
    }
  }

  return {} as any;
};

export const getFriendlyDate = (date: Dayjs) => {
  if (date.isToday()) return 'Today';
  if (date.isTomorrow()) return 'Tomorrow';
  return date.format('MMMM D');
};

// Takes an array of appt times as returned by findDailyAvailability
export const filterPastApptTimes = (
  timeSlots: string[], // ['9:30AM', '10:30AM', 12:00PM ... ]
  selectedDate: string, // YYYY-MM-DD
) => {
  if (!selectedDate) return [];

  const currentTime = dayjs();
  return timeSlots
    .map(slot => dayjs(`${selectedDate} ${slot}`, 'YYYY-MM-DD h:mmA'))
    .filter(slot => slot.isAfter(currentTime))
    .map(slot => slot.format('h:mmA'));
};

export const getEarliestAvailableDay = (
  scheduleData: Record<string, Array<string>>,
) => {
  const earliest = Object.keys(scheduleData)
    .filter(date => scheduleData[date].length)
    .sort((date1, date2) => {
      const date1parsed = dayjs(date1, 'YYYY-MM-DD');
      const date2parsed = dayjs(date2, 'YYYY-MM-DD');
      return date1parsed.diff(date2parsed);
    })[0];

  if (!earliest) return dayjs().format('YYYY-MM-DD');

  return earliest;
};

export const isIsoDate = (str: string): boolean => dayjs.utc(str).isValid();

export const formatPatientCleaning = (str: string) => {
  if (str && str.includes('ADULT')) {
    return str.replace('ADULT', '(ADULT)');
  }
  if (str && str.includes('Adult')) {
    return str.replace('Adult', '(Adult)');
  }
  if (str && str.includes('CHILD')) {
    return str.replace('CHILD', '(CHILD)');
  }
  if (str && str.includes('Child')) {
    return str.replace('Child', '(Child)');
  }
  return str;
};

export const formatApptTypeName = (apptTypeName: string) => {
  if (apptTypeName.includes('RETURNING') || apptTypeName.includes('NEW')) {
    return formatPatientCleaning('TEETH CLEANING ADULT');
  }
  if (apptTypeName.includes('Returning') || apptTypeName.includes('New')) {
    return formatPatientCleaning('Teeth Cleaning Adult');
  }
  return formatPatientCleaning(apptTypeName);
};

export const formatSentence = (sentence: any) => {
  const words = sentence.toLowerCase().split('_');
  const capitalizedWords = words.map(
    (word: any) => word.charAt(0).toUpperCase() + word.slice(1),
  );
  return formatApptTypeName(capitalizedWords.join(' '));
};

export const isValidBirthday = (month: string, day: string, year: string) => {
  const isFutureDate = dayjs(`${month}/${day}/${year}`, 'M/D/YYYY') >= dayjs();

  const isValidDate = dayjs(
    `${month}/${day}/${year}`,
    'M/D/YYYY',
    true,
  ).isValid();

  if (+year >= 1900 && isValidDate && !isFutureDate) {
    return true;
  }

  return false;
};

export const isValidBirthdate = (dateString: string) => {
  // Check if the input is a valid date format
  if (!dayjs(dateString, 'MM/DD/YYYY', true).isValid()) {
    return false;
  }

  // Calculate the age based on the provided birthdate
  const birthdate = dayjs(dateString);
  const currentDate = dayjs();
  const age = currentDate.diff(birthdate, 'years');

  // Check if the age is within a reasonable range
  if (age < 0 || age > 110) {
    return false;
  }

  // If all checks pass, the birthdate is considered valid
  return true;
};
