/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface MapSlice {
  activeMarkerName: string;
  nextEmergencyTitle: string;
  nextEmergencyTime: string;
}

export const initialMapState: MapSlice = {
  activeMarkerName: '',
  nextEmergencyTitle: '',
  nextEmergencyTime: '',
};

const mapSlice = createSlice({
  name: 'mapSlice',
  initialState: initialMapState,
  reducers: {
    setSelectedMarkerName: (state, { payload }: PayloadAction<any>) => {
      state.activeMarkerName = payload;
    },
    setNextEmergencyTitle: (state, { payload }: PayloadAction<any>) => {
      state.nextEmergencyTitle = payload;
    },
    setNextEmergencyTime: (state, { payload }: PayloadAction<any>) => {
      state.nextEmergencyTime = payload;
    },
  },
});
export default mapSlice.reducer;

export const {
  setSelectedMarkerName,
  setNextEmergencyTitle,
  setNextEmergencyTime,
} = mapSlice.actions;
