export const ADD_PAYMENT_METHOD = 'Add your payment information';
export const ADD_A_CARD = 'Add a card';
export const ALL_TIMES_LOCAL =
  "*All times are local to the selected business' time zone,";
export const APPOINTMENT_AVAILABILITY = 'Appointment availability';
export const APPOINTMENT_ON_HOLD = 'Appointment on hold';
export const APPOINTMENT_TYPE = 'Appointment type';
export const ARE_YOU_SURE = 'Are you sure?';
export const AVAILABLE_TIMES_FOR = 'Available times for';
export const BACK = 'Back';
export const BACK_TO_HOME = 'Back to Home';
export const BOOK_AN_APPOINTMENT = 'BOOK AN APPOINTMENT';
export const BUT_NOT_WORRIES = 'But no worries!';
export const BY_CLICKING = 'By clicking continue you agree to our ';
export const CANCEL_ICON = 'Cancel Icon';
export const CANT_FIND_A_TIME = `Can't find a time that works for you? Give us a call at`;
export const CANT_FIND_A_TIME_PHONE = `312-846-6752`;
export const COF_NO_CHARGE =
  "But don't worry. You'll only be charged a small fee if you cancel your appointment within 24 hours.";
export const COMPONENTS = 'To Components world';
export const CONTINUE = 'Continue';
export const COMPLETE_BOOKING = 'Complete Booking';
export const DATE_OF_BIRTH_BOLD = 'date of birth?';
export const DATE_TIME = 'Date and Time';
export const DAY = 'Day';
export const DENTOLOGIE_URL = 'https://dentologie.com';
export const DENTOLOGIE_URL_INSURANCE = 'https://dentologie.com/insurance';
export const DENTOLOGIE_URL_TERMS = 'https://dentologie.com/terms-of-service';
export const DONT_SEE_A_CODE = 'Don’t see a code? Send it again';
export const DOWNLOAD_THE_DENTOLOGIE_MOBILE_APP =
  'Download the Dentologie mobile app! ';
export const DO_YOU_HAVE_DENTAL_INSURANCE = 'Do you have ';
export const EMAIL_ADDRESS = 'Email address';
export const EMAIL_ME_INSTEAD = 'Email me instead';
export const GET_PREPARED_FOR = 'Get prepared for your upcoming appointment';
export const GREAT_WE_ACCEPT_MOST_DENTAL_PLANS =
  'Great! We accept most dental PPO plans';
export const HAVE_YOU_VISITED = 'Have you been to Dentologie before?';
export const HI_THERE = 'Hi there,';
export const IF_YOU_THNK_THIS_IS_INCORRECT =
  'If you think this is incorrect, give us a call at ';
export const IM_AN_EXISTING_PATIENT = "I'm an existing patient";
export const IM_A_NEW_PATIENT = "I'm a new patient";
export const INVALID_INPUT = 'Invalid input';
export const INVALID_LOGIN = 'Invalid Login';
export const IN_ORDER_TO_LOG_YOU_IN =
  'In order to log you in, we need to verify some info';
export const IT_IS_ALL_GOOD = 'It’s all good!';
export const IT_IS_ALL_GOOD_MOBILE =
  'It’s all good! You will have to pay out of pocket.';
export const LAST_NAME = 'Last name';
export const LAST_NAME_BOLD = 'last name?';
export const LOCATION = 'Location';
export const LOGIN = 'Login';
export const LOGIN_ADMIN = 'Login Admin';
export const LOGIN_ERROR =
  "Sorry, the email or phone number you entered isn't registered with Dentologie. Please try again.";
export const LOGIN_WITH_A_ONE_TIME = 'Log in with a one-time code';
export const MONTH = 'Month';
export const MUST_BE_ADMIN =
  'You must be an administrator to log in to this site';
export const NEED_COF = 'We need to have a ';
export const NEXT_AVAILABLE_BUTTON = 'Next available';
export const NO_AVAILABILITIES =
  'No availabilities. Please select another day.';
export const NOT_FOUND = 'Not found';
export const NOT_SIGNED_IN = 'Not signed in';
export const OOPS = 'Oops!';
export const PASSWORD = 'Password';
export const PHONE_OR_EMAIL = 'Phone or Email';
export const PROGRESS_NOT_SAVED = 'Your progress will not be saved.';
export const SCHEDULE_YOUR_VISIT = 'Schedule your visit';
export const SEE_CANCELLATION = 'See our cancellation policy';
export const SEE_WHICH_PLANS_WE_ACCEPT = 'See which plans we accept';
export const SOMETHING_WENT_WRONG = 'Something Went Wrong';
export const SORRY_THAT_APPT_IS_NO_LONGER =
  'Sorry, that appointment is no longer available. Please go back and select a new appointment time.';
export const TELL_US_ABOUT_YOURSELF = 'Tell us about yourself';
export const TERMS_OF_SERVICE = 'Terms of Service';
export const TEXT_ME_INSTEAD = 'Text me instead';
export const THE_CODE_YOU_ENTERED_IS_INVALID =
  'The code you entered is invaild';
export const VALID_BIRTH_DATE = 'Please enter a valid birth date';
export const VALID_EMAIL = 'Please enter a valid email';
export const VALID_PHONE = 'Please enter a valid phone';
export const VERY_YOUR_PHONE = 'Verify your phone number';
export const WELCOME_ADMIN = 'Welcome Admin';
export const WELCOME_BACK =
  'Welcome back! Please enter your phone number or email';
export const WE_ARE_HAVING_PROBLEMS_FINDING_YOUR_INFO_PART_1 =
  'We’re having trouble finding your info...';
export const WE_ARE_HAVING_PROBLEMS_FINDING_YOUR_INFO_PART_2 =
  'We’ll get you set up right now';
export const WE_ARE_SORRY_SOMETHING_WENT_WRONG =
  'We are sorry, something went wrong. Please try again.';
export const WE_DONT_ACCEPT_MEDICARE =
  'Unfortunately we don’t accept Medicare or Medicaid. You will have to ';
export const WE_DONT_ACCEPT_MEDICAR_MOBILE =
  'Unfortunately we don’t accept Medicare or Medicaid. You will have to pay out of pocket.';
export const WE_SENT_A_CODE_TO = 'We sent a code to ';
export const WE_WILL_SEND_A_CODE =
  '*We will text a code to this phone number to verify your account';
export const WHAT_IS_YOUR = 'What is your';
export const WHAT_IS_YOUR_DATE_OF_BIRTH = 'What is your date of birth?';
export const WHAT_WOULD_YOU_LIKE_TO_COME_IN_FOR =
  'What would you like to come in for?';
export const WHEN_WOULD_YOU_LIKE_TO_COME_IN = 'When would you like to come in?';
export const YEAR = 'Year';
export const YES = 'Yes';
export const YOUR_ADMIN_EMAIL = 'Your admin email';
export const YOUR_APPOINTMENT_IS_BOOKED = 'Your appointment is booked!';
export const YOUR_EMAIL = 'We sent a code to your email address';
export const YOUR_PHONE_NUMBER = 'We sent a code to your phone number';
