import { GTMEvent } from './gtmTypes';

declare global {
  interface Window {
    dataLayer: Array<GTMEvent>;
  }
}

export const logGTMEvent = (gtmEvent: GTMEvent) => {
  try {
    window.dataLayer.push(gtmEvent);
  } catch (error) {
    console.warn('GTM event NOT logged: ', error);
  }
};
