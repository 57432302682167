import styled from 'styled-components';

import theme from '../../utils/theme';

const { fonts } = theme;

export const BackArrow = styled.img`
  width: 25px;
  height: 25px;
  margin-right: 7px;
  @media (max-width: 767px) {
    width: 18px;
    height: 18px;
  }
`;

export const BackButton = styled.div`
  display: flex;
  flex-direction: row;
  width: 250px;
  align-items: center;
  cursor: pointer;
`;

export const BackButtonWrapper = styled.div`
  width: 81%;
  height: 10%;
  margin-bottom: 28px;
  @media (max-width: 767px) {
    margin-top: 31px;
    margin-bottom: 28px;
  }
`;
export const BackPageButton = styled.div`
  display: flex;
  width: 250px;
  align-items: left;
  cursor: pointer;
`;

export const ButtonName = styled.p`
  font-family: ${fonts.GintoBold};
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 1.15px;
  margin-bottom: 0;
  margin-top: 1px;

  @media (max-width: 767px) {
    font-size: 16px;
    line-height: 18px;
  }
`;
