import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

import {
  Container,
  TextInputContainer,
  SearchBarWrapper,
  Title,
  ListContainer,
  CardWrapper,
  OrangeCardContainer,
} from './styles';
import operationsApptTypes from '../../features/ChooseAppointmentType/operations';
import { getAllLocations } from '../../features/ChooseLocation/operations';
import { useApi } from '../../hooks/useApi';
import { LocationCard } from '../../slices/locationsSlice';
import {
  setNextEmergencyTime,
  setNextEmergencyTitle,
} from '../../slices/mapSlice';
import { logGTMEvent } from '../../utils/gtm/gtmHelpers';
import { EventNames, PageNames } from '../../utils/gtm/gtmTypes';
import * as strings from '../../utils/strings';
import Card from '../Card';
import OrangeCard from '../OrangeCard';
import OneLineTextInput from '../TextInput';

const HomePageWidget = () => {
  const [value, setValue] = useState<string>('');

  const { locationCards } = useSelector((state: RootState) => state.locations);
  const dispatch = useDispatch();

  const handleSetInputValue = (inputValue: string) => {
    setValue(inputValue);
  };

  const filterResults = (searchTerm: string, array: Array<LocationCard>) => {
    const filteredResults = array?.filter(
      (result: any) =>
        result?.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        result?.address?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        result?.zipCode?.toString().includes(searchTerm),
    );
    // Selected location card
    if (filteredResults.length > 0) {
      dispatch(setNextEmergencyTitle(filteredResults?.[0]?.name));
      dispatch(setNextEmergencyTime(filteredResults?.[0]?.nextEmergency));
    }
    return filteredResults;
  };

  const filteredResults = filterResults(value, locationCards);

  const handleGoToNextScreen = (
    locationId: string | undefined,
    isEmergency: boolean,
  ) => {
    if (locationId) {
      window.open(
        `${process.env.REACT_APP_WEB_BASE_URL}?locationId=${locationId}&isEmergency=${isEmergency}`,
        '_blank',
      );
    }
  };

  const { request: getAllLocationsReq } = useApi(getAllLocations);

  const { request: getAppointmentTypesReq } = useApi(
    operationsApptTypes.getAppointmentTypes,
  );

  useEffect(() => {
    getAppointmentTypesReq();
    getAllLocationsReq();
  }, []);

  const { nextEmergencyTitle, nextEmergencyTime } = useSelector(
    (state: RootState) => state.map,
  );

  const nextEmergencyTimeValue = nextEmergencyTime
    ? `Next emergency: ${nextEmergencyTime}`
    : 'No emergency';

  return (
    <Container>
      <Title>{strings.BOOK_AN_APPOINTMENT}</Title>
      <TextInputContainer>
        <SearchBarWrapper>
          <OneLineTextInput
            placeholder="Search for location"
            setValue={handleSetInputValue}
            value={value}
          />
        </SearchBarWrapper>
      </TextInputContainer>
      {/* <div> */}
      <ListContainer>
        <OrangeCardContainer
          onClick={() => {
            handleGoToNextScreen(filteredResults?.[0]?.id, true);
            logGTMEvent({
              event: EventNames.glWidgetLocationClick,
              eventData: {
                locationId: `${filteredResults?.[0]?.id}`,
                apptType: 'emergency',
              },
              pageName: PageNames.homepageWidget,
            });
          }}
        >
          <OrangeCard
            title={
              filteredResults?.length > 0
                ? nextEmergencyTimeValue
                : 'No locations found'
            }
            subtitle={
              filteredResults?.length > 0
                ? nextEmergencyTitle || locationCards?.[0]?.name
                : ''
            }
            marginTop="1%"
            marginLeft
            width
            fullWidth
            height="40px"
          />
        </OrangeCardContainer>
        <CardWrapper>
          {filteredResults?.map(
            ({ id, name, address, nextNewPatientCleaning, fullAddress }) => (
              <Card
                key={uuidv4()}
                id={id}
                title={name}
                fullAddress={fullAddress}
                description={nextNewPatientCleaning}
                subtitle={address}
                displayWarningIcon={false}
                onClick={() => {
                  handleGoToNextScreen(id, false);
                  logGTMEvent({
                    event: EventNames.glWidgetLocationClick,
                    eventData: {
                      locationId: id,
                      apptType: 'regular',
                    },
                    pageName: PageNames.homepageWidget,
                  });
                }}
                width="100%"
                homePage
              />
            ),
          )}
        </CardWrapper>
      </ListContainer>
      {/* </div> */}
    </Container>
  );
};

export default HomePageWidget;
