import React, { useCallback, useRef, useState, useEffect } from 'react';

import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';

import {
  MapWrapper,
  CarouselBox,
  OrangeCardContainer,
  CardBox,
  LoadingContainer,
} from './styles';
import Card from '../../../components/Card';
import Carousel from '../../../components/Carousel';
import Marker from '../../../components/Marker';
import OrangeCard from '../../../components/OrangeCard';
import { useInnerWidth } from '../../../hooks/useInnerWidth';
import {
  setApptTypesOptionsBySelLocation,
  setSelectedAppointmentType,
} from '../../../slices/appointmentSlice';
import {
  LocationCard,
  setSelectedLocation,
} from '../../../slices/locationsSlice';
import {
  setCurrentLoginFlowScreen,
  setSkipChooseAppointmentType,
} from '../../../slices/loginSlice';
import { setSelectedMarkerName } from '../../../slices/mapSlice';
import {
  filterLocationSelected,
  findNextEmergency,
  formatLocationName,
  formatPatientCleaning,
  getFriendlyDate,
} from '../../../utils/functions';
import { logGTMEvent } from '../../../utils/gtm/gtmHelpers';
import {
  ApptNames,
  EventNames,
  GTMEvent,
  PageNames,
} from '../../../utils/gtm/gtmTypes';
import theme from '../../../utils/theme';

const containerStyleWebScreen = {
  height: '90vh',
  width: '100%',
};

const containerStyleMobileScreen = {
  height: '55vh',
  width: '100%',
};

const options = {
  mapId: '5840bea8015b890b',
  disableDefaultUI: true,
  clickeableIcons: false,
};

interface MapLocationProps {
  arrayData: Array<LocationCard>;
}

const MapLocations = ({ arrayData }: MapLocationProps) => {
  const innerWidth = useInnerWidth();
  const dispatch = useDispatch();

  const {
    locationCards,
    locationDataWithAppt,
    locationNextCleaningData,
    selectedLocation,
    loading,
  } = useSelector((state: RootState) => state.locations);

  const { isNewPatient } = useSelector((state: RootState) => state.session);

  const nextEmergency = findNextEmergency(
    locationNextCleaningData,
    locationCards,
  );

  const {
    timeZoneString: nextEmergencyTimeZoneString,
    nextEmergency: nextEmergencyTimeString,
  } = nextEmergency;

  const chicagoCenterLocation = {
    lat: 41.86653482480349,
    lng: -87.62136117427141,
  };
  const markerArray = arrayData || [];
  const cardArray = markerArray?.map((item: any) => (
    <CardBox
      key={item.id}
      style={{
        width: innerWidth > 420 ? '298px' : innerWidth * 0.7,
        marginRight: '20px',
      }}
    >
      <Card
        key={item.id}
        id={item.id}
        title={item?.name}
        description={
          isNewPatient
            ? item?.nextNewPatientCleaning
            : item?.nextReturningPatientCleaning
        }
        subtitle={item?.address}
        timeZoneString={item?.timeZoneString}
        timeZone={item?.timeZone}
        fullAddress={item?.fullAddress}
        displayWarningIcon={false}
        positionAbsoluteCss
        marginTop
        marginLeft
        width="100%"
        showQuestionIcon={false}
        showHoverState={item.id === selectedLocation?.id}
        previousScreen="chooseLocations"
        nextEmergency={item?.nextEmergency}
      />
    </CardBox>
  ));

  const mapRef = useRef<GoogleMap>();

  // We are setting this value in order to FOCUS the map
  const [activeMarkerLocation, setActiveMarkerLocation] = useState(
    markerArray.length ? arrayData?.[0].location : chicagoCenterLocation,
  );
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: `${process.env.REACT_APP_GOOGLE_API_KEY}`,
  });

  const onLoad = useCallback((map: any) => {
    mapRef.current = map;
  }, []);

  const focusOnMarker = () => {
    mapRef.current?.panTo(activeMarkerLocation);
  };

  const handleActiveMarker = (prop: any) => {
    setActiveMarkerLocation(prop.location);
    focusOnMarker();
  };

  const handleActiveCard = (prop: any) => {
    const markerTitle = prop?.props?.children?.props.title;

    const selectedNameInMarkerArr = arrayData?.filter(
      marker => marker.name === markerTitle,
    );
    dispatch(setSelectedMarkerName(markerTitle));
    // setting this value is necessary for Marker Component to know which card was focused by the user and then popup the corresponding marker in the map
    setActiveMarkerLocation(selectedNameInMarkerArr?.[0]?.location);
    focusOnMarker();
    return markerTitle;
  };

  const handleOrangeCardClick = () => {
    const filteredLocationSelected = filterLocationSelected(
      formatLocationName(nextEmergency?.locationName),
      locationDataWithAppt,
    );
    const loc = markerArray.find(
      location => location.id === nextEmergency?.locationId,
    );
    dispatch(setSkipChooseAppointmentType(true));
    dispatch(setSelectedLocation(loc));
    dispatch(setApptTypesOptionsBySelLocation(filteredLocationSelected));
    dispatch(setSelectedAppointmentType('EMERGENCY'));
    dispatch(setCurrentLoginFlowScreen('chooseAppointmentScreen'));

    if (isNewPatient) {
      // Backfill GTM event on emergency card click
      const eventArray: GTMEvent[] = [
        {
          event: EventNames.npLocationSelected,
          eventData: {
            locationId: `${loc?.id}`,
          },
          pageName: PageNames.chooseLocation,
        },
        {
          event: EventNames.npApptTypeSelected,
          eventData: {
            apptType: ApptNames.npEmergency,
          },
          pageName: PageNames.chooseLocation,
        },
      ];

      eventArray.map(event => logGTMEvent(event));
    }
  };

  useEffect(() => {
    if (activeMarkerLocation) {
      focusOnMarker();
    }
  }, [activeMarkerLocation]);

  // This style has to live here and not in css file because googlemap need css prop to be written as camel case format
  const mapWrapperStyle = {
    borderRadius: '13px',
    overflow: 'hidden',
    boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25) inset',
    background: `${theme.colors.cardTan}`,
    alignItems: 'center',
  };

  const abbreviateTimeZone = (timeZoneString: string) => {
    if (timeZoneString) {
      const split = timeZoneString.split(' ');
      let result = '';
      split.map(item => {
        result += item.charAt(0);
        return null;
      });
      return result;
    }
    return timeZoneString;
  };

  const abbreviatedTimezone = abbreviateTimeZone(nextEmergencyTimeZoneString);
  const nextEmergencyDateTime = dayjs(nextEmergencyTimeString);
  const nextEmergencyDayFormatted = getFriendlyDate(nextEmergencyDateTime);
  const nextEmergencyTimeFormatted = nextEmergencyDateTime.format('h:mm A');

  return isLoaded && !loading ? (
    <MapWrapper>
      <div style={mapWrapperStyle}>
        <GoogleMap
          zoom={13}
          center={
            markerArray.length
              ? arrayData?.[0]?.location
              : chicagoCenterLocation
          }
          mapContainerStyle={
            innerWidth > 767
              ? containerStyleWebScreen
              : containerStyleMobileScreen
          }
          options={options}
          onLoad={onLoad}
        >
          {markerArray?.length && (
            <Marker data={arrayData} getActiveMarker={handleActiveMarker} />
          )}

          <OrangeCardContainer
            onClick={markerArray.length ? handleOrangeCardClick : undefined}
          >
            <OrangeCard
              title={
                markerArray?.length
                  ? `Next Emergency: ${nextEmergencyDayFormatted} at ${nextEmergencyTimeFormatted} ${abbreviatedTimezone}`
                  : 'No locations found'
              }
              subtitle={formatLocationName(nextEmergency.locationName)}
              marginTop={innerWidth < 765 ? '10px' : '15px'}
              marginLeft
              width
            />
          </OrangeCardContainer>
          {cardArray?.length && (
            <CarouselBox>
              <Carousel
                getActiveCard={handleActiveCard}
                setActiveMarker={setActiveMarkerLocation}
                markerArray={markerArray}
              >
                {cardArray}
              </Carousel>
            </CarouselBox>
          )}
        </GoogleMap>
      </div>
    </MapWrapper>
  ) : (
    <LoadingContainer>Loading...</LoadingContainer>
  );
};

export default MapLocations;
