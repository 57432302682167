/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';

import 'react-toastify/dist/ReactToastify.css';

import { Amplify } from 'aws-amplify';
import TagManager from 'react-gtm-module';
import { BrowserRouter } from 'react-router-dom';

import Routes from './routes';

const tagManagerConfig = {
  gtmId: 'GTM-MLDPHC3',
};

TagManager.initialize(tagManagerConfig);

Amplify.configure({
  Auth: {
    region: process.env.REACT_APP_AWS_COGNITO_REGION,
    userPoolId: process.env.REACT_APP_AWS_COGNITO_USER_POOL,
    userPoolWebClientId: process.env.REACT_APP_AWS_COGNITO_CLIENT_ID,
  },
});

const App = () => (
  <BrowserRouter>
    <Routes />
  </BrowserRouter>
);

export default App;
