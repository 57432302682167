/* eslint-disable sort-exports/sort-exports */
import apiClient, { apiConfigURL } from '../../api/client';
import store from '../../redux/store';
import {
  setAllLocationsData,
  setAllLocationsDataWithAppt,
  setLocationsNextCleaningData,
  setLocationCards,
} from '../../slices/locationsSlice';
import {
  matchLocWithNextDate,
  matchApptTypeWithNextDate,
  filterOpenAndEnabledLocations,
  filterOnlyNotErrorLocations,
  convertAllTimes,
} from '../../utils/functions';

export const getLocationsNextCleaning = async () => {
  try {
    const res = await apiClient.get('/locations/next');
    const locationConfigRes = await apiConfigURL.get('/LocationConfig.JSON');
    const locationsArr = store.getState().locations.allLocations;
    const apptTypes = store.getState().appointment.appointmentTypes;
    const nextCleaningArr = res?.data?.with;
    const locationConfig = locationConfigRes?.data?.data;

    const filteredOnlyNotErrorLocations =
      filterOnlyNotErrorLocations(nextCleaningArr);
    try {
      const convertedTimeZones = convertAllTimes(
        filteredOnlyNotErrorLocations,
        locationsArr,
      );
      const matchedApptTypeWithNextDate = matchApptTypeWithNextDate(
        convertedTimeZones,
        apptTypes,
        locationConfig,
      );
      const matchedLocWithNextCleaning = matchLocWithNextDate(
        convertedTimeZones,
        locationsArr,
      );

      store.dispatch(setLocationCards(matchedLocWithNextCleaning));
      store.dispatch(setLocationsNextCleaningData(nextCleaningArr));
      store.dispatch(setAllLocationsDataWithAppt(matchedApptTypeWithNextDate));
    } catch (error) {
      console.error(`error in getLocationsNextCleaning`, error);
    }

    return res as any;
  } catch (err) {
    return err;
  }
};

export const getAllLocations = async () => {
  try {
    const res = await apiClient.get('/locations');
    const locationData = res?.data?.with;
    store.dispatch(setAllLocationsData(locationData));
    await getLocationsNextCleaning();

    // Filter to see only open and enabled location
    const filteredLocation = filterOpenAndEnabledLocations(locationData);
    store.dispatch(setAllLocationsData(filteredLocation));
    return res as any;
  } catch (err) {
    return err;
  }
};
