import React from 'react';

import { MarkerF } from '@react-google-maps/api';
import { useSelector, useDispatch } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

import { ToothLogoMarkerActive } from '../../assets/images';
import { setSelectedLocation } from '../../slices/locationsSlice';
import { setSelectedMarkerName } from '../../slices/mapSlice';

type MarkerProps = {
  location: google.maps.LatLng | google.maps.LatLngLiteral;
  icon: string | google.maps.Icon | google.maps.Symbol | undefined;
  name?: string;
};

const Marker = ({ data, getActiveMarker }: any) => {
  const dispatch = useDispatch();

  const { activeMarkerName } = useSelector((state: RootState) => state.map);

  return data?.map((marker: MarkerProps) => {
    const isActive = activeMarkerName === marker.name;
    return (
      <MarkerF
        key={uuidv4()}
        position={marker.location}
        icon={isActive ? ToothLogoMarkerActive : marker.icon}
        onClick={() => {
          dispatch(setSelectedMarkerName(marker.name));
          dispatch(setSelectedLocation(marker));
          getActiveMarker(marker);
        }}
      />
    );
  });
};

export default Marker;
