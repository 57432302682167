/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface LocationCard {
  id: string;
  name: string;
  address: string;
  zipCode: string;
  fullAddress: {
    address1: string;
    city: string;
    postalCode: string;
    state: string;
  };
  nextNewPatientCleaning: string;
  nextReturningPatientCleaning: string;
  nextEmergency: string;
  icon: string;
  timeZone: string;
  timeZoneString: string;
  location: {
    lat: number;
    lng: number;
  };
}

interface AvailabilityType {
  type: string;
  time: string;
  error: boolean;
}

export interface NextAvailableLocation {
  locationId: string;
  locationName: string;
  nextEmergency: string | null;
  nextCleaning: string;
  next: AvailabilityType[];
}

/**
 * FIXME: Add correct types. Typing this at all is pointless if types and initial values are set to 'any' and empty strings.
 */
interface LocationsProps {
  allLocations: any;
  locationDataWithAppt: any;
  selectedLocation: any;
  selectedLocationDataWithAppt: any;
  locationNextCleaningData: any;
  locationCards: LocationCard[];
  loading?: boolean;
}

export const initialLocationsState: LocationsProps = {
  allLocations: '',
  locationDataWithAppt: '',
  selectedLocation: '',
  selectedLocationDataWithAppt: '',
  locationNextCleaningData: '',
  locationCards: [],
  loading: true,
};

const locationsSlice = createSlice({
  name: 'locationsSlice',
  initialState: initialLocationsState,
  reducers: {
    setAllLocationsData: (state, { payload }: PayloadAction<any>) => {
      state.allLocations = payload;
      if (payload?.length > 0 && state?.locationCards?.length > 0) {
        state.loading = false;
      }
    },
    setAllLocationsDataWithAppt: (state, { payload }: PayloadAction<any>) => {
      state.locationDataWithAppt = payload;
    },
    setSelectedLocation: (state, { payload }: PayloadAction<any>) => {
      state.selectedLocation = payload;
    },
    setNewLocationDataWithAppt: (state, { payload }: PayloadAction<any>) => {
      state.selectedLocationDataWithAppt = payload;
    },
    setLocationsNextCleaningData: (
      state,
      { payload }: PayloadAction<NextAvailableLocation>,
    ) => {
      state.locationNextCleaningData = payload;
    },
    setLocationCards: (state, { payload }: PayloadAction<any>) => {
      state.locationCards = payload;
    },
  },
});
export default locationsSlice.reducer;

export const {
  setAllLocationsData,
  setAllLocationsDataWithAppt,
  setSelectedLocation,
  setNewLocationDataWithAppt,
  setLocationsNextCleaningData,
  setLocationCards,
} = locationsSlice.actions;
