import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import {
  Container,
  BackButtonBox,
  Title,
  CardWrapper,
  DropdownBox,
  ProgressBarContainer,
  CardBox,
  CardBoxMobile,
} from './styles';
import BackButton from '../../components/BackButton';
import Card from '../../components/Card';
import Dropdown from '../../components/Dropdown';
import ProgressBar from '../../components/ProgressBar';
import {
  setSelectedAppointmentType,
  setApptTypesOptionsBySelLocation,
} from '../../slices/appointmentSlice';
import { setSelectedLocation } from '../../slices/locationsSlice';
import { setCurrentLoginFlowScreen } from '../../slices/loginSlice';
import {
  filterLocationSelected,
  formatApptTypeName,
} from '../../utils/functions';
import { logGTMEvent } from '../../utils/gtm/gtmHelpers';
import { EventNames, PageNames } from '../../utils/gtm/gtmTypes';
import * as strings from '../../utils/strings';
import { getProfile } from '../ExistingPatient/operations';

const ChooseAppointmentType = () => {
  const dispatch = useDispatch();
  const [windowSize, setWindowSize] = useState(window.innerWidth);
  const [searchParam] = useSearchParams();

  const {
    allLocations,
    locationDataWithAppt,
    selectedLocation,
    locationCards,
  } = useSelector((state: RootState) => state.locations);
  const { isNewPatient, fromNPFlowLocSelected } = useSelector(
    (state: RootState) => state.session,
  );

  const { userName, preferredLocation } = useSelector(
    (state: RootState) => state.userData,
  );

  const handleNextScreen = (type: string) => {
    dispatch(setCurrentLoginFlowScreen('chooseAppointmentScreen'));
    dispatch(setSelectedAppointmentType(type));
    logGTMEvent({
      event: isNewPatient
        ? EventNames.npApptTypeSelected
        : EventNames.epApptTypeSelected,
      eventData: {
        apptType: type,
      },
      pageName: PageNames.chooseApptType,
    });
  };

  const locationDataWithApptToDisplay =
    filterLocationSelected(
      selectedLocation?.title || selectedLocation?.name,
      locationDataWithAppt,
    ) || locationDataWithAppt;

  const handleBackButton = () => {
    dispatch(setCurrentLoginFlowScreen('chooseLocationsScreen'));
  };

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize(window.innerWidth);
    };
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  });

  useEffect(() => {
    if (!userName && !isNewPatient) {
      getProfile();
    }
  }, []);

  useEffect(() => {
    if (
      preferredLocation &&
      !searchParam.get('locationId') &&
      !fromNPFlowLocSelected
    ) {
      const updatedPrefLocation = locationCards.find(
        (location: any) => location.id === preferredLocation,
      );
      dispatch(setSelectedLocation(updatedPrefLocation));
    }
  }, [preferredLocation]);

  useEffect(() => {
    const locationIdParam = searchParam.get('locationId');

    const locationToSelect = locationCards.find(
      location => location.id === locationIdParam,
    );

    if (locationIdParam && !isNewPatient && locationToSelect) {
      logGTMEvent({
        event: EventNames.epChangeLocation,
        eventData: {
          locationId: `${locationIdParam}`,
        },
        pageName: PageNames.hubSpotLocationPage,
      });
      dispatch(setSelectedLocation(locationToSelect));
    }
  }, []);

  useEffect(() => {
    // We are setting selected appt types options array for the selected location made by the user in the Choose Location Screen
    const filteredLocationSelected = filterLocationSelected(
      selectedLocation?.title || selectedLocation?.name,
      locationDataWithAppt,
    );
    dispatch(setApptTypesOptionsBySelLocation(filteredLocationSelected));
  }, [selectedLocation]);

  return (
    <Container>
      <BackButtonBox>
        <BackButton onClick={handleBackButton} />
      </BackButtonBox>
      <DropdownBox>
        <Dropdown
          isLocationType
          options={allLocations}
          noMargin
          previousScreen="chooseApptType"
        />
      </DropdownBox>
      <ProgressBarContainer>
        <ProgressBar initialValue={10} />
      </ProgressBarContainer>
      <Title>{strings.WHAT_WOULD_YOU_LIKE_TO_COME_IN_FOR}</Title>
      <CardWrapper>
        {locationDataWithApptToDisplay?.[0]?.types
          ?.filter((apptType: any) => {
            if (isNewPatient) {
              return !apptType?.displayName?.includes('RETURNING');
            }
            return !apptType?.displayName?.includes('NEW');
          })
          .map(
            (
              { duration, displayName, type, nextCleaning, id }: any,
              idx: number,
            ) =>
              windowSize < 900 ? (
                <CardBox key={idx.toString() + id}>
                  <Card
                    id={id}
                    title={formatApptTypeName(displayName)}
                    description={nextCleaning}
                    subtitle={duration}
                    showQuestionIcon
                    previousScreen="chooseApptType"
                    onClick={() => handleNextScreen(type)}
                    width={windowSize < 1300 ? '100%' : '298px'}
                    displayWarningIcon={displayName === 'EMERGENCY'}
                  />
                </CardBox>
              ) : (
                <CardBoxMobile key={idx.toString() + id}>
                  <Card
                    id={id}
                    title={formatApptTypeName(displayName)}
                    description={nextCleaning}
                    subtitle={duration}
                    showQuestionIcon
                    previousScreen="chooseApptType"
                    onClick={() => handleNextScreen(type)}
                    width="100%"
                    displayWarningIcon={displayName === 'EMERGENCY'}
                  />
                </CardBoxMobile>
              ),
          )}
      </CardWrapper>
    </Container>
  );
};

export default ChooseAppointmentType;
