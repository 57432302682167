import { apiConfigURL } from '../../api/client';
import store from '../../redux/store';
import { setAppointmentType } from '../../slices/appointmentSlice';

const getAppointmentTypes = async () => {
  const res = await apiConfigURL.get('/AppointmentConfig.JSON');
  const appointmentTypeData = res?.data?.data;
  store.dispatch(setAppointmentType(appointmentTypeData));

  return appointmentTypeData;
};

export default { getAppointmentTypes };
