import styled from 'styled-components';

import theme from '../../../utils/theme';

const { colors } = theme;

export const CardBox = styled.div``;

export const CarouselBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: 'flex-start';
  height: 100%;
  width: 100%;
  margin-top: 5px;
  background: ${colors.cardTan};
  margin-left: -9px;
  @media (max-width: 915px) {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: 'flex-end';
    margin-left: 0px;
  }
`;

export const LoadingContainer = styled.div`
  background-color: ${theme.colors.cardTan};
  padding: 40px;
`;

export const MapWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: 'center';
  height: 100%;
  width: 100%;
  padding: 20px;
  background: ${colors.cardTan};
  @media (max-width: 900px) {
    height: 100%;
    width: 100%;
  }
  @media (max-width: 767px) {
    background: ${colors.backgroundTan};
  }
`;

export const OrangeCardContainer = styled.div`
  @media (max-width: 915px) {
    width: 100%;
    display: flex;
    justify-content: center;
  }
`;
