/* eslint-disable import/no-unresolved */
import { Slider } from '@material-ui/core';
import { SmileyMint } from 'assets/images';
import styled from 'styled-components';
import theme from 'utils/theme';

export const StyledSlider = styled(Slider)`
  & .MuiSlider-thumb {
    background-image: url(${SmileyMint});
    width: 19px;
    height: 19px;
    margin-top: -8px;
    background-size: cover;
  }
  & .MuiSlider-track {
    color: ${theme.colors.ctaTeal};
  }
  & .MuiSlider-rail {
    border: 2px solid ${theme.templateColors.pureWhite};
  }
  & .MuiSlider-thumb.Mui-disabled {
    width: 19px;
    height: 19px;

    margin-top: -8px;
  }
`;
