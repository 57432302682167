import styled from 'styled-components';

import theme from '../../utils/theme';

export const BackButtonBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: 'center';
  align-items: left;
  width: 100%;
  margin: 0px;
`;

export const Button = styled.div`
  display: flex;
  padding: 20px;
  flex-direction: column;
  justify-content: 'center';
  align-items: center;
  height: 200px;
  width: 300px;
  font-size: 20px;
  cursor: pointer;
`;

export const CardBox = styled.div`
  margin-bottom: 20px;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

export const CardBoxMobile = styled.div`
  margin-right: 20px;
  display: flex;
  margin-bottom: 20px;
  width: 298px;
  height: 120px;
`;

export const CardWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: 35%;
  @media (max-width: 1300px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: auto;
    margin-right: 0px;
    width: 100%;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: 'center';
  align-items: left;
  height: 100%;
  width: 80%;
  padding: 40px 20px 40px 20px;
  background-color: ${theme.colors.cardTan};
  @media (max-width: 1300px) {
    padding: 40px;
    width: 100%;
  }
`;

export const DropdownBox = styled.div`
  margin-bottom: 5px;
  margin-top: 12px;
  width: 100%;
  @media (max-width: 1300px) {
    margin-top: 0px;
    margin-bottom: 40px;
  }
  ul {
    position: absolute !important;
    width: 100% !important;
    margin: 10px 0px 0px 0px !important;
  }
`;

export const ProgressBarContainer = styled.div`
  display: flex;
  width: 95%;
  height: 2%;
  margin-bottom: 30px;
  @media (max-width: 1300px) {
    width: 90%;
  }
`;

export const Title = styled.h1`
  font-family: ${theme.fonts.GintoRegular};
  font-weight: 400;
  font-size: 18px;
  line-height: 18px;
  letter-spacing: 1.15px;
  margin-bottom: 45px;
  @media (max-width: 1300px) {
    margin-bottom: 35px;
  }
`;
