import React from 'react';

import { StyledButton } from './styles';

interface LinkProps {
  onPress: () => void;
  text: string;
  disabled?: boolean;
}
const DentLink = ({ onPress, text, disabled = false }: LinkProps) => (
  <StyledButton onClick={onPress} disabled={disabled}>
    {text}
  </StyledButton>
);

export default DentLink;
