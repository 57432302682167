/* eslint-disable no-debugger */
import React, { useEffect, useState } from 'react';

import OtpInput from 'react-otp-input';
import { useSelector } from 'react-redux';

import operations from './operations';
import {
  FormContainer,
  ProgressBarContainer,
  FormTitle,
  FormSubtitle,
  ButtonsContainer,
  OtherWaysBox,
  OtherWaysOne,
  OtherWaysTwo,
  InputStyled,
  InputBox,
  ErrorOneTimePassCode,
  ErrorWrapper,
  OutsideContainer,
} from './styles';
import BackButton from '../../components/BackButton';
import Button from '../../components/Button';
import ErrorModal from '../../components/ErrorModal';
import ProgressBar from '../../components/ProgressBar';
import { useApi } from '../../hooks/useApi';
import { validateSixNumbers } from '../../utils/functions';
import * as strings from '../../utils/strings';

interface OneTimePassCodeProps {
  backToLogin?: () => void;
}

const OneTimePassCode: React.FC<OneTimePassCodeProps> = ({ backToLogin }) => {
  const { userEmailOrPhone } = useSelector((state: RootState) => state.session);
  const { selectedLocation } = useSelector(
    (state: RootState) => state.locations,
  );
  const { selectedApptType } = useSelector(
    (state: RootState) => state.appointment,
  );

  const [sendCodeTo, setSendCodeTo] = useState<string>(
    userEmailOrPhone.includes('@')
      ? userEmailOrPhone
      : `(${userEmailOrPhone.slice(2, 5)})-${userEmailOrPhone.slice(
          5,
          8,
        )}-${userEmailOrPhone.slice(8)}`,
  );
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(true);
  const [errorOneTimePassCode, setErrorOneTimePassCode] =
    useState<boolean>(false);
  const [enteredCode, setEnteredCode] = useState('');
  const [openErrorModal, setOpenErrorModal] = useState<boolean>(false);
  const [weSentACodeTo, setWeSentACodeTo] = useState<string>(
    `${strings.WE_SENT_A_CODE_TO} ${sendCodeTo}`,
  );
  const [emailMeOrTextMeInstead, setEmailMeOrTextMeInstead] = useState<string>(
    sendCodeTo.includes('@')
      ? strings.TEXT_ME_INSTEAD
      : strings.EMAIL_ME_INSTEAD,
  );

  const {
    statusMessage,
    loading: validateLoading,
    request: validateOTPReq,
    statusCode: validateOTPStatusCode,
    responseMessage: otpResponseMessage,
  } = useApi(operations.validateOTP);

  const otpErrorMessages = ['Incorrect Passcode', 'InvalidLogin'];

  const { request: getOTP, statusCode: getOTPstatusCode } = useApi(
    operations.getOTP,
  );

  const handleSetInputValue = (otp: any) => {
    setEnteredCode(otp);
    const areInputFieldsCompleted = validateSixNumbers(otp);

    setIsButtonDisabled(!areInputFieldsCompleted);
  };

  const handleValidateOtp = () => {
    if (enteredCode)
      validateOTPReq(enteredCode, selectedLocation, selectedApptType);
  };

  const handleSendCodeAgain = () => {
    setErrorOneTimePassCode(false);
    getOTP({ username: userEmailOrPhone });
  };

  const emailMeInstead = () => {
    setErrorOneTimePassCode(false);
    setWeSentACodeTo(
      weSentACodeTo.includes('@')
        ? strings.YOUR_PHONE_NUMBER
        : strings.YOUR_EMAIL,
    );
    setEmailMeOrTextMeInstead(
      emailMeOrTextMeInstead.includes('Email')
        ? strings.TEXT_ME_INSTEAD
        : strings.EMAIL_ME_INSTEAD,
    );
    getOTP({
      username: userEmailOrPhone,
      method: sendCodeTo.includes('@') ? 'SMS' : 'EMAIL',
    });
  };

  useEffect(() => {
    if (
      (statusMessage && statusMessage === 'failed') ||
      otpErrorMessages.includes(otpResponseMessage?.with?.message)
    ) {
      setErrorOneTimePassCode(true);
      setIsButtonDisabled(true);
      setEnteredCode('');
    }
    if (statusMessage && statusMessage === 'succeeded') {
      if (otpErrorMessages.includes(otpResponseMessage?.with?.message)) {
        setErrorOneTimePassCode(true);
        setIsButtonDisabled(true);
        setEnteredCode('');
      } else {
        setErrorOneTimePassCode(false);
      }
    }
  }, [statusMessage, otpResponseMessage]);

  useEffect(() => {
    if (
      (validateOTPStatusCode && validateOTPStatusCode >= 400) ||
      (getOTPstatusCode && getOTPstatusCode >= 400)
    ) {
      setOpenErrorModal(true);
      setIsButtonDisabled(true);
      setEnteredCode('');
    }
  }, [validateOTPStatusCode, getOTPstatusCode]);

  return (
    <OutsideContainer>
      <BackButton onClick={backToLogin} />
      <ProgressBarContainer>
        <ProgressBar initialValue={2} />
      </ProgressBarContainer>
      <FormContainer>
        <FormTitle>{strings.LOGIN_WITH_A_ONE_TIME}</FormTitle>
        <FormSubtitle>{weSentACodeTo}</FormSubtitle>
        <InputBox>
          <OtpInput
            value={enteredCode}
            onChange={handleSetInputValue}
            numInputs={6}
            renderInput={props => <InputStyled {...props} />}
            shouldAutoFocus={false}
          />
        </InputBox>
        {errorOneTimePassCode && (
          <ErrorWrapper>
            <ErrorOneTimePassCode>
              {strings.THE_CODE_YOU_ENTERED_IS_INVALID}
            </ErrorOneTimePassCode>
          </ErrorWrapper>
        )}
      </FormContainer>
      <OtherWaysBox>
        <OtherWaysOne onClick={handleSendCodeAgain}>
          {strings.DONT_SEE_A_CODE}
        </OtherWaysOne>
        <OtherWaysTwo onClick={emailMeInstead}>
          {emailMeOrTextMeInstead}
        </OtherWaysTwo>
      </OtherWaysBox>
      <ButtonsContainer>
        <Button
          isLoading={validateLoading}
          label={strings.CONTINUE}
          onPress={handleValidateOtp}
          disabled={isButtonDisabled}
          mQueryCellphoneWidth="100%"
          width="338px"
        />
      </ButtonsContainer>
      <ErrorModal open={openErrorModal} setOpen={setOpenErrorModal} />
    </OutsideContainer>
  );
};

export default OneTimePassCode;
