/* eslint-disable no-debugger */
import { AxiosResponse } from 'axios';
import isEmail from 'validator/lib/isEmail';

import api from '../../api/methods';
import store from '../../redux/store';
import {
  setOTPToken,
  initialLoginState,
  setUserEmailOrPhone,
  setCurrentLoginFlowScreen,
  login,
} from '../../slices/loginSlice';
import { setUser } from '../../slices/userDataSlice';
import { logGTMEvent } from '../../utils/gtm/gtmHelpers';
import { EventNames, PageNames } from '../../utils/gtm/gtmTypes';

export const otpErrorMessages = ['Incorrect Passcode', 'InvalidLogin'];

// Login user be call with phone or email
const getOTP = async (params: any) => {
  try {
    const regex = /\((\d{3})\)-(\d{3})-(\d{4})/;
    const usernameData = params.username;
    let formattedParam = usernameData;
    if (usernameData && regex.test(usernameData)) {
      const transformedNumber =
        String('+1') + usernameData.replace(regex, '$1$2$3');
      formattedParam = transformedNumber;
    } else {
      formattedParam = usernameData;
    }
    let objectToSend = params;
    if (params?.method) {
      objectToSend = {
        username: formattedParam,
        method: params.method,
      };
    } else {
      objectToSend = { username: formattedParam };
    }

    const res = await api.post('/user/otp', objectToSend);
    if (res.data.with.message === 'Please Confirm Account') {
      const otpToken = {
        ...initialLoginState.user?.otpToken,
        message: res.data.with.message,
        needToConfirmAccount: true,
      };
      store.dispatch(setOTPToken(otpToken));
      store.dispatch(setUserEmailOrPhone(formattedParam));
      store.dispatch(setCurrentLoginFlowScreen('oneTimePassScreen'));
      return res;
    }
    const {
      issued_at: issuedAt,
      message,
      session,
      challengeParameters: { USERNAME: username, challengeQuestion, sendType },
    } = res.data.with;
    const otpToken = {
      issuedAt,
      message,
      session,
      needToConfirmAccount: false,
      challengeParameters: {
        username,
        challengeQuestion,
        sendType,
      },
    };
    store.dispatch(setUserEmailOrPhone(formattedParam));
    store.dispatch(setCurrentLoginFlowScreen('oneTimePassScreen'));
    store.dispatch(setOTPToken(otpToken));

    return res as any;
  } catch (err) {
    return err;
  }
};

const validateOTP = async (
  params: any,
  selectedLocation: any,
  selectedApptType: any,
) => {
  try {
    const unhappyPath = store.getState().session.user?.needToConfirmAccount;
    const dataToSend = {
      username: store.getState().session.user?.challengeParameters?.username,
      response: {
        session: store.getState().session.user?.session,
        answer: params,
      },
    };
    const dataToSendUnhappyPath = {
      username: store.getState().session.userEmailOrPhone,
      passcode: params,
    };
    const res = await api.post(
      unhappyPath ? '/user/otp/verifycognito' : '/user/otp/response',
      unhappyPath ? dataToSendUnhappyPath : dataToSend,
    );
    if (res.data.status === 'succeeded') {
      if (otpErrorMessages.includes(res?.data?.with?.message)) {
        const { message, session } = res.data.with;
        const otpToken = {
          message,
          session,
        };
        store.dispatch(
          setOTPToken({
            ...(store.getState().session.user || {}),
            ...otpToken,
          }),
        );
        return res;
      }
      await api
        .get('/user/profile', {
          headers: {
            Authorization: `Bearer ${res.data.with.access_token}`,
          },
        })
        .then((res1: AxiosResponse) => {
          const profile = res1.data?.with;
          store.dispatch(setUser(profile));
          return res1;
        })
        .catch(error => error);
      const username = store.getState().session.userEmailOrPhone;
      if (unhappyPath) {
        store.dispatch(setCurrentLoginFlowScreen('existingPatientScreen'));
      }
      if (!unhappyPath) {
        if (selectedLocation && selectedApptType) {
          store.dispatch(setCurrentLoginFlowScreen('chooseAppointmentScreen'));
        } else {
          store.dispatch(
            setCurrentLoginFlowScreen('chooseAppointmentTypeScreen'),
          );
        }
        logGTMEvent({
          event: EventNames.epOtpLoginComplete,
          eventData: {
            loginType: isEmail(username) ? 'email' : 'phone',
          },
          pageName: PageNames.otp,
        });
      }
    }
    const {
      issued_at: issuedAt,
      expires_in: expiresIn,
      access_token: accessToken,
      refresh_token: refreshToken,
      message,
    } = res.data.with;
    const otpToken = {
      issuedAt,
      expiresAt: Date.now() / 1000 + expiresIn,
      accessToken,
      refreshToken,
      message,
      otpToken: initialLoginState.user?.otpToken,
      idToken: '',
      isLogged: true,
    };
    store.dispatch(login(otpToken));

    return res as any;
  } catch (err: any) {
    return err?.response || err;
  }
};

export default { getOTP, validateOTP };
