import styled from 'styled-components';

export const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: 'center';
  align-items: center;
  margin-top: 5%;
  margin-bottom: 40px;
  height: 100%;
  width: 100%;

  > div {
    max-width: 350px;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: 'center';
  align-items: center;
  height: 100%;
  width: 100%;
`;

export const CardContainer = styled.div`
  margin-bottom: 20px;
  width: 100%;
`;
