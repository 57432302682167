/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface LoginState {
  loggedIn: boolean;
  user: any;
  currentLoginFlowScreen: string;
  userEmailOrPhone: string;
  isNewPatient: boolean;
  skipChooseAppointmentType: boolean;
  fromNPFlowLocSelected: boolean;
}

export const initialLoginState: LoginState = {
  loggedIn: false,
  user: null,
  currentLoginFlowScreen: 'loginScreen',
  userEmailOrPhone: '',
  isNewPatient: false,
  skipChooseAppointmentType: false,
  fromNPFlowLocSelected: false,
};

const loginSlice = createSlice({
  name: 'loginSlice',
  initialState: initialLoginState,
  reducers: {
    login: (state, { payload }: PayloadAction<any>) => {
      state.loggedIn = true;
      state.user = payload;
    },
    signOut: state => {
      state.user = initialLoginState.user;
      state.loggedIn = false;
    },
    setOTPToken: (state, { payload }: PayloadAction<any>) => {
      state.user = payload;
    },
    setUserEmailOrPhone: (state, { payload }: PayloadAction<any>) => {
      state.userEmailOrPhone = payload;
    },
    setCurrentLoginFlowScreen: (state, { payload }: PayloadAction<any>) => {
      state.currentLoginFlowScreen = payload;
    },
    setIsNewPatient: (state, { payload }: PayloadAction<any>) => {
      state.isNewPatient = payload;
    },
    setSkipChooseAppointmentType: (state, { payload }: PayloadAction<any>) => {
      state.skipChooseAppointmentType = payload;
    },
    /** If user in new patient flow is found to have an existing account, setting this property will preserve their location choice after authenticating. */
    setFromNPFlowLocSelected: (state, { payload }: PayloadAction<boolean>) => {
      state.fromNPFlowLocSelected = payload;
    },
  },
});
export default loginSlice.reducer;

export const {
  login,
  setOTPToken,
  setCurrentLoginFlowScreen,
  setUserEmailOrPhone,
  signOut,
  setIsNewPatient,
  setSkipChooseAppointmentType,
  setFromNPFlowLocSelected,
} = loginSlice.actions;
