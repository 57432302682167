import styled from 'styled-components';
// eslint-disable-next-line import/no-unresolved
import theme from 'utils/theme';

interface ContainerProps {
  width: string;
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: ${({ width }) => `${width}`};
  background: ${theme.colors.cardTan};
  box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  outline: 0;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 30px 30px 40px 30px;
  @media (max-width: 767px) {
    width: 80%;
    top: 50%;
    left: 50%;
  }
`;

export const ModalBackdrop = {
  backgroundColor: 'rgba(217, 217, 217, 0.5)',
};
