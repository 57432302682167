import React from 'react';

import { Modal as MuiModal } from '@material-ui/core';

import { Container, ModalBackdrop } from './styles';

interface Modal {
  open: boolean;
  setOpen: (value: boolean) => void;
  width?: string;
  height?: string;
  children?: React.ReactNode;
}
const Modal = ({ open, setOpen, width = '500px', children }: Modal) => (
  <MuiModal
    open={open}
    onClose={() => setOpen(false)}
    BackdropProps={{ style: ModalBackdrop }}
  >
    <Container width={width}>{children}</Container>
  </MuiModal>
);

export default Modal;
