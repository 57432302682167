import styled from 'styled-components';

import theme from '../../utils/theme';

const { templateColors, colors } = theme;

export const AppointmentOnHold = styled.div`
  border: 0.75px solid #d3d2be;
  border-radius: 26px;
  width: 226px;
  height: 25px;
  padding: 7px 13px 6px 15px;
  font-family: ${theme.fonts.GintoLight};
  font-size: 12px;
  line-height: 12px;
  text-align: center;
  letter-spacing: 1.15px;
  color: ${theme.colors.black};
  margin-bottom: 30px;
`;

export const AppointmentOnHoldBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;
export const BackArrow = styled.img`
  width: 25px;
  height: 25px;
  margin-right: 11px;
`;
export const BackButton = styled.div`
  display: flex;
  flex-direction: row;
  width: 250px;
  align-items: center;
  cursor: pointer;
`;
export const ButtonsContainer = styled.div`
  width: 100%;
  height: 57px;
  display: flex;
  align-items: 'center';
  justify-content: center;
  @media (max-width: 767px) {
    flex-direction: column;
    width: 100%;
    height: 50%;
    margin-top: 15%;
    align-items: 'center';
    justify-content: center;
  }
`;
export const ErrorOneTimePassCode = styled.div`
  height: 40px;
  width: 338px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${templateColors.pink2};
  border-radius: 4px;
  font-family: ${theme.fonts.GintoRegular};
  font-weight: 475;
  font-size: 16px;
  line-height: 17px;
  letter-spacing: 1.15px;
  @media (max-width: 767px) {
    width: 100%;
  }
`;
export const ErrorWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  margin-top: 20px;
`;
export const FormContainer = styled.div`
  display: flex;
  width: 100%;
  height: 30%;
  flex-direction: column;
  justify-content: 'center';
  align-items: 'center';

  @media (max-width: 767px) {
    justify-content: 'center';
    align-items: center;
  }
`;
export const FormSubtitle = styled.p`
  font-family: ${theme.fonts.GintoRegular};
  font-weight: 400;
  font-size: 12px;
  line-height: 140.79%;
  text-align: center;
  align-items: 'center';
  justify-content: center;
  letter-spacing: 1.15px;
  padding-left: 5px;
  @media (max-width: 767px) {
    font-size: 16px;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
  }
`;

export const FormTitle = styled.p`
  font-family: ${theme.fonts.GintoBold};
  font-weight: 700;
  font-size: 18px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 1.15px;
  @media (max-width: 767px) {
    font-size: 16px;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
  }
`;
export const HeaderBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: 'left';
  @media (max-width: 767px) {
    padding: 10%;
  }
`;

export const InputBox = styled.div`
  display: flex;
  height: 40%;
  margin-top: 25px;
  justify-content: center;
  align-items: center;
`;
export const InputStyled = styled.input`
  width: 40px !important;
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
  text-align: center;
  border-bottom: 1px solid ${colors.black};
  font-family: ${theme.fonts.DomineRegular};
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 1.15px;
  background: ${colors.cardTan};
  outline: none;
  margin-left: 8px;
  margin-right: 8px;
  @media (max-width: 767px) {
    width: 100%;
    margin-top: 10%;
    margin-bottom: 5%;
  }
`;
export const OnePassCodeInputBox = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: 73%;
  background: ${colors.cardTan};
  @media (max-width: 767px) {
    width: 100%;
    height: 73%;
  }
`;
export const OtherWaysBox = styled.div`
  display: flex;
  width: 100%;
  margin-top: 32px;
  margin-bottom: 20px;
  height: 20%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  @media (max-width: 767px) {
    width: 100%;
  }
`;

export const OtherWaysOne = styled.p`
  font-family: ${theme.fonts.GintoBold};
  font-weight: 700;
  font-size: 16px;
  width: 320px;
  line-height: 16px;
  height: 10%;
  text-decoration-line: underline;
  cursor: pointer;
  @media (max-width: 767px) {
    width: 100%;
  }
`;

export const OtherWaysTwo = styled.p`
  font-family: ${theme.fonts.GintoBold};
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  width: 320px;
  text-decoration-line: underline;
  margin-top: 5%;
  cursor: pointer;
  @media (max-width: 767px) {
    width: 100%;
  }
`;

export const OutsideContainer = styled.div`
  padding: 34px 15% 192px 14%;
  width: 100%;
  @media (max-width: 767px) {
    padding: 0px 7% 76px 8%;
  }
`;

export const ProgressBarContainer = styled.div`
  display: flex;
  width: 100%;
  height: 5%;
  margin-bottom: 90px;
  @media (max-width: 767px) {
    margin-top: -10px;
    margin-bottom: 60px;
  }
`;

export const Subtitle = styled.p`
  font-family: ${theme.fonts.GintoRegular};
  font-weight: 400;
  font-size: 18px;
  line-height: 18px;
  letter-spacing: 1.15px;
  @media (max-width: 767px) {
    font-size: 14px;
    line-height: 128.7%;
    margin-bottom: 0px;
  }
`;

export const Title = styled.p`
  color: ${colors.black};
  font-family: ${theme.fonts.DomineRegular};
  font-weight: 700;
  font-size: 50px;
  line-height: 140.79%;
  letter-spacing: 1.15px;
  @media (max-width: 767px) {
    flex-direction: column;
    width: 100%;
    height: 50%;
    margin-top: 2%;
    margin-bottom: 18%;
    align-items: 'center';
    justify-content: center;
  }
`;
