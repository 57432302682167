import React, { useState } from 'react';

import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { saveAs } from 'file-saver';
import { EventAttributes, createEvent } from 'ics';
import { useSelector } from 'react-redux';

import {
  Container,
  Header,
  Title,
  Body,
  BodyText,
  SmileImg,
  ButtonContainer,
  Span,
} from './styles';
import { SmileMore } from '../../assets/images';
import Button from '../../components/Button';
import ErrorModal from '../../components/ErrorModal';
import ProgressBar from '../../components/ProgressBar';
import {
  formatLocationName,
  formatSentence,
  getAppointmentDuration,
} from '../../utils/functions';
import { logGTMEvent } from '../../utils/gtm/gtmHelpers';
import { EventNames, PageNames } from '../../utils/gtm/gtmTypes';
import * as strings from '../../utils/strings';

dayjs.extend(customParseFormat);

const AppointmentBooked = () => {
  const {
    selectedAppointmentDate,
    selectedAppointmentTime,
    selectedApptType,
    selectedRawDate,
    appointmentTypes,
  } = useSelector((state: RootState) => state.appointment);

  const { isNewPatient } = useSelector((state: RootState) => state.session);

  const { selectedLocation, locationCards } = useSelector(
    (state: RootState) => state.locations,
  );

  const [isAddToCalError, setIsAddToCalError] = useState(false);

  const selectedLocationName =
    selectedLocation?.name ||
    formatLocationName(selectedLocation?.locationName) ||
    selectedLocation?.title;

  const getCoords = () => {
    if (selectedLocation?.location) {
      return {
        lat: selectedLocation?.location?.lat,
        lon: selectedLocation?.location?.lng,
      };
    }
    const coords = locationCards.find(
      location => location.id === selectedLocation?.id,
    )?.location;

    return {
      lat: coords?.lat || 0,
      lon: coords?.lng || 0,
    };
  };

  const appointmentDuration = getAppointmentDuration(
    selectedApptType,
    appointmentTypes,
  );

  const handleSaveIcs = () => {
    const date = dayjs(selectedRawDate).format('YYYY-MM-DD');
    const fullDate = dayjs(
      `${date} ${selectedAppointmentTime}`,
      'YYYY-MM-DD H:mmA',
    );

    // This app's state is wack, so I added extra error messages to help debug
    if (fullDate.isValid()) {
      const year = fullDate.format('YYYY');
      const month = fullDate.format('M');
      const day = fullDate.format('D');
      const hour = fullDate.format('H');
      const minute = fullDate.format('m');

      if (!selectedLocationName) {
        setIsAddToCalError(true);
        console.error('Unable to save calendar event: No Location Name');
      } else if (getCoords().lat === 0 || getCoords().lon === 0) {
        setIsAddToCalError(true);
        console.error('Unable to save calendar event: No Location Coordinates');
      } else if (!appointmentDuration[0]?.length) {
        setIsAddToCalError(true);
        console.error('Unable to save calendar event: No Appointment Duration');
      } else {
        const newEvent: EventAttributes = {
          start: [+year, +month, +day, +hour, +minute],
          duration: { minutes: appointmentDuration[0]?.length },
          title: 'Dentologie Appointment',
          location: selectedLocationName,
          geo: getCoords(),
        };

        createEvent(newEvent, (_error, value): any => {
          if (_error) {
            setIsAddToCalError(true);
            console.error(
              'Unable to save calendar event: createEvent() error: ',
              _error,
            );
          } else {
            const blob = new Blob([value], { type: 'text/calendar' });
            saveAs(blob, 'dentologie-appointment.ics');
          }
        });

        logGTMEvent({
          event: EventNames.glAddToCalendar,
          eventData: {
            patientType: isNewPatient ? 'new' : 'existing',
          },
          pageName: PageNames.apptBooked,
        });
      }
    } else {
      setIsAddToCalError(true);
      console.error('Unable to save calendar event: Invalid Date');
    }
  };

  return (
    <Container>
      <Header>
        <Title>{strings.YOUR_APPOINTMENT_IS_BOOKED}</Title>
        <ProgressBar initialValue={100} />
      </Header>
      <Body>
        <BodyText>
          {/* TODO: replace values with dynamic data from BE  */}
          {/* {`We'll see you for your ${selectedApptType} at ${selectedLocation.name} on Januart 22nd at 2:30 PM`} */}
          We’ll see you for your <Span>{formatSentence(selectedApptType)}</Span>{' '}
          {`at `}
          {/* <br /> */}
          <Span>
            {selectedLocation?.name ||
              formatLocationName(selectedLocation?.locationName) ||
              selectedLocation?.title}
          </Span>{' '}
          on <Span>{selectedAppointmentDate}</Span> at{' '}
          <Span>{selectedAppointmentTime}</Span>
        </BodyText>
        <SmileImg src={SmileMore} />
      </Body>
      <ButtonContainer>
        <Button
          label="Add to calendar"
          mode="secondary"
          width="300px"
          mQueryCellphoneWidth="100%"
          onPress={handleSaveIcs}
        />
        <Button
          label="Done"
          width="300px"
          mQueryCellphoneWidth="100%"
          onPress={() => window.open(strings.DENTOLOGIE_URL, '_self')}
        />
      </ButtonContainer>
      <ErrorModal
        open={isAddToCalError}
        setOpen={setIsAddToCalError}
        title="Unable to Add to Calendar"
        details="An error occurred. Please try again."
      />
    </Container>
  );
};

export default AppointmentBooked;
