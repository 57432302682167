import React from 'react';

import Countdown from 'react-countdown';
import { useSelector } from 'react-redux';

import { StyledCountdown } from './styles';
import { logGTMEvent } from '../../utils/gtm/gtmHelpers';
import { EventNames, PageType } from '../../utils/gtm/gtmTypes';

interface CountdownRenderer {
  minutes: number;
  seconds: number;
}

interface CountdownProps {
  date?: number;
}

const DentCountdown = ({ date = Date.now() + 600000 }: CountdownProps) => {
  const { currentLoginFlowScreen } = useSelector(
    (state: RootState) => state.session,
  );
  return (
    <Countdown
      date={date}
      renderer={({ minutes, seconds }: CountdownRenderer) => (
        <StyledCountdown>
          {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
        </StyledCountdown>
      )}
      onComplete={() => {
        logGTMEvent({
          event: EventNames.glApptHoldExpired,
          pageName: currentLoginFlowScreen as PageType,
        });
      }}
    />
  );
};

export default DentCountdown;
