import axios from 'axios';

import { refreshSession } from '../features/Login/operations';
import store from '../redux/store';
import { signOut } from '../slices/loginSlice';

const TIMEOUT = 60000; // milliseconds

export const CONTENT_TYPE = {
  Accept: 'application/json',
};

const apiClient = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: CONTENT_TYPE,
  timeout: TIMEOUT,
});

export const apiClientV2 = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL_V2,
  headers: CONTENT_TYPE,
  timeout: TIMEOUT,
});

export const apiConfigURL = axios.create({
  baseURL: process.env.REACT_APP_CONFIG_URL,
  headers: CONTENT_TYPE,
  timeout: TIMEOUT,
});

// Separate api object that has no interceptors
export const refreshApi = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: CONTENT_TYPE,
  timeout: TIMEOUT,
});

export const s3 = axios.create({
  baseURL: process.env.REACT_APP_S3_URL,
  headers: CONTENT_TYPE,
  timeout: TIMEOUT,
});

apiClient.interceptors.request.use(async (config: any) => {
  if (store.getState().session?.user?.accessToken) {
    const {
      user: { expiresAt, refreshToken, issuedAt, isLogged, accessToken },
    } = store.getState().session;

    if (isLogged && accessToken) {
      // Updates Authorization header with refreshed token
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    //If the token has expired or is about to expire, refresh it
    if (isLogged && refreshToken && +expiresAt < Date.now() / 1000) {
      await refreshSession({ refreshToken });
    }

    // If the user has been logged in for 12 hours, log them out
    if (isLogged && Date.now() / 1000 >= issuedAt + 43200) {
      store.dispatch(signOut());
    }
  }
  return config;
});

export default apiClient;
