import React, { useState } from 'react';

import {
  AsYouType,
  parsePhoneNumber,
  isPossiblePhoneNumber,
  isValidPhoneNumber,
} from 'libphonenumber-js';
import { useDispatch, useSelector } from 'react-redux';
// eslint-disable-next-line import/no-extraneous-dependencies
import isEmail from 'validator/lib/isEmail';

import {
  Header,
  Title,
  Container,
  PhoneDetail,
  StyledBigContainer,
  StyledColumn,
  StyledContainer,
  StyledError,
  StyledLeftColumn,
  TermsAndConditions,
  AppointmentInformation,
  LeftBox,
  LocationBox,
  TitleBox,
  DescriptionBox,
  HeaderIcon,
  Location,
  Description,
  DateAndTimeBox,
  RightBox,
  AppointmentTypeBox,
  AppointmentOnHold,
  AppointmentPlaceholder,
  AppointmentTimer,
  IconAndTitleBox,
  IconAndTitleBoxLocation,
  ProgressBarContainer,
} from './styles';
import { Calendar, MapPin, Tooth } from '../../assets/images';
import AreYouSure from '../../components/AreYouSure';
import BackButton from '../../components/BackButton';
import Button from '../../components/Button';
import DentLink from '../../components/DentLink';
import ProgressBar from '../../components/ProgressBar';
import OneLineTextInput from '../../components/TextInput';
import {
  setCurrentLoginFlowScreen,
  setUserEmailOrPhone,
} from '../../slices/loginSlice';
import {
  setUserName,
  setUserLastName,
  setUserEmail,
  setUserPhone,
  setUserBirthDate,
  setNewUserFormData,
} from '../../slices/userDataSlice';
import {
  formatSentence,
  formatSelectedApptTime,
  transformDateFormat,
  transformApptTypeString,
  isValidBirthdate,
} from '../../utils/functions';
import { logGTMEvent } from '../../utils/gtm/gtmHelpers';
import { EventNames, PageNames } from '../../utils/gtm/gtmTypes';
import * as strings from '../../utils/strings';

interface PatientDataProps {
  returnToLogin: () => void;
  CountdownComponent: () => JSX.Element | null;
}

const PatientData = ({
  returnToLogin,
  CountdownComponent = () => null,
}: PatientDataProps) => {
  const asYouType = new AsYouType('US');

  const { userName, userLastName, userEmail, userBirthDate, userPhone } =
    useSelector((state: RootState) => state.userData);

  const { selectedLocation } = useSelector(
    (state: RootState) => state.locations,
  );

  const {
    selectedApptType,
    selectedRawDate,
    selectedAppointmentTime,
    lockApptData,
  } = useSelector((state: RootState) => state.appointment);

  const [name, setName] = useState<string>(userName || '');
  const [lastName, setLastName] = useState<string>(userLastName || '');
  const [email, setEmail] = useState<string>(userEmail || '');
  const [isEmailValid, setIsEmailValid] = useState<boolean>(true);
  const [birthDate, setBirthDate] = useState<string>(userBirthDate || '');
  const [isBirthDateValid, setIsBirthDateValid] = useState<boolean>(true);
  const [phone, setPhone] = useState<string>(userPhone || '');
  const [isPhoneValid, setIsPhoneValid] = useState<boolean>(true);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const dispatch = useDispatch();

  const onEmailChange = (e: any) => {
    const inputContact = e;
    if (isEmail(inputContact)) {
      const formattedContact = inputContact.toLowerCase();
      setIsEmailValid(true);
      dispatch(setUserEmail(formattedContact));
    } else {
      setIsEmailValid(false);
    }
    setEmail(inputContact);
  };

  const onPhoneChange = (phoneNumber: string) => {
    if (isValidPhoneNumber(phoneNumber, 'US')) {
      setIsPhoneValid(true);
    } else {
      setIsPhoneValid(false);
    }
    if (phoneNumber.length > 4) {
      const formatted = asYouType.input(phoneNumber);
      setPhone(formatted);
    } else {
      setPhone(phoneNumber);
    }
    dispatch(setUserPhone(phoneNumber));
  };

  const onDOBChange = (e: string) => {
    if (e.length === 1 && +e > 1 && +e <= 9) {
      setBirthDate(`0${e}/`);
      dispatch(setUserBirthDate(`0${e}`));
    } else if (e.length === 2 || e.length === 5) {
      setBirthDate(`${e}/`);
      dispatch(setUserBirthDate(e));
    } else {
      setBirthDate(e);
      dispatch(setUserBirthDate(e));
    }
    if (isValidBirthdate(e)) {
      setIsBirthDateValid(true);
    } else {
      setIsBirthDateValid(false);
    }
  };

  const handleBackPress = () => {
    dispatch(setCurrentLoginFlowScreen('chooseAppointmentScreen'));
  };

  const handleContinueButton = () => {
    const phoneNumber = parsePhoneNumber(phone, 'US');
    const phoneNumberFormatted = phoneNumber.format('E.164');
    dispatch(setUserEmailOrPhone(phoneNumberFormatted));
    const newUserData = {
      firstName: name,
      lastName: lastName,
      phoneNumber: phoneNumberFormatted,
      email,
      dob: transformDateFormat(birthDate),
      lockedSlotId: lockApptData.id,
      appointmentType: transformApptTypeString(selectedApptType),
    };

    logGTMEvent({
      event: EventNames.npPersInfoSubmitted,
      pageName: PageNames.patientData,
    });

    dispatch(setNewUserFormData(newUserData));
    dispatch(setCurrentLoginFlowScreen('doYouHaveDentalInsuranceScreen'));
  };

  const subtitle = `${selectedLocation?.fullAddress?.address1}, ${selectedLocation?.fullAddress?.city}, ${selectedLocation?.fullAddress?.state} ${selectedLocation?.fullAddress?.postalCode}`;
  const title = selectedLocation.name;

  const handleKeyDown = (e: any) => {
    if (e.key === 'Backspace') {
      setBirthDate('');
    }
  };

  return (
    <>
      <Container>
        <Header>
          <BackButton onClick={handleBackPress} />
          <AppointmentInformation>
            <LeftBox>
              <LocationBox>
                <TitleBox>{strings.LOCATION}</TitleBox>
                <DescriptionBox>
                  <IconAndTitleBoxLocation>
                    <HeaderIcon src={MapPin} />
                    <Location>{title}</Location>
                  </IconAndTitleBoxLocation>
                  <Description>{subtitle}</Description>
                </DescriptionBox>
              </LocationBox>
              <DateAndTimeBox>
                <TitleBox>{strings.DATE_TIME}</TitleBox>
                <DescriptionBox>
                  <IconAndTitleBox>
                    <HeaderIcon src={Calendar} />
                    <Description>
                      {formatSelectedApptTime(
                        selectedRawDate,
                        selectedAppointmentTime,
                      )}
                    </Description>
                  </IconAndTitleBox>
                </DescriptionBox>
              </DateAndTimeBox>
            </LeftBox>
            <RightBox>
              <AppointmentTypeBox>
                <TitleBox>{strings.APPOINTMENT_TYPE}</TitleBox>
                <DescriptionBox>
                  <IconAndTitleBox>
                    <HeaderIcon src={Tooth} />
                    <Description>
                      {formatSentence(selectedApptType)}
                    </Description>
                  </IconAndTitleBox>
                </DescriptionBox>
              </AppointmentTypeBox>
              <AppointmentOnHold>
                <AppointmentPlaceholder>
                  {strings.APPOINTMENT_ON_HOLD}
                </AppointmentPlaceholder>
                <AppointmentTimer>
                  <CountdownComponent />
                </AppointmentTimer>
              </AppointmentOnHold>
            </RightBox>
          </AppointmentInformation>
          <ProgressBarContainer>
            <ProgressBar initialValue={60} />
          </ProgressBarContainer>
        </Header>
        <Title>{strings.TELL_US_ABOUT_YOURSELF}</Title>
        <StyledBigContainer>
          <StyledLeftColumn>
            <OneLineTextInput
              placeholder="First name"
              value={name}
              setValue={s => {
                setName(s);
                dispatch(setUserName(s));
              }}
              maxLength={25}
            />
            <OneLineTextInput
              placeholder="Last name"
              value={lastName}
              setValue={s => {
                setLastName(s);
                dispatch(setUserLastName(s));
              }}
              maxLength={25}
            />
            <OneLineTextInput
              placeholder="Email"
              value={email}
              setValue={s => {
                onEmailChange(s);
              }}
            />
            {!isEmailValid && <StyledError>{strings.VALID_EMAIL}</StyledError>}
          </StyledLeftColumn>
          <StyledColumn>
            <OneLineTextInput
              placeholder="Phone"
              value={phone}
              setValue={onPhoneChange}
              onBlur={() => {
                dispatch(setUserPhone(phone));
              }}
            />
            <PhoneDetail>{strings.WE_WILL_SEND_A_CODE}</PhoneDetail>
            {!isPhoneValid && <StyledError>{strings.VALID_PHONE}</StyledError>}
            <OneLineTextInput
              placeholder="Date of birth (MM/DD/YYYY)"
              value={birthDate}
              setValue={onDOBChange}
              onKeyDown={handleKeyDown}
            />
            {!isBirthDateValid && (
              <StyledError>{strings.VALID_BIRTH_DATE}</StyledError>
            )}
          </StyledColumn>
        </StyledBigContainer>
        <StyledBigContainer>
          <StyledLeftColumn>
            <DentLink
              text="Do you take my insurance?"
              onPress={() => {
                window.open(strings.DENTOLOGIE_URL_INSURANCE);
                logGTMEvent({
                  event: EventNames.npTakeMyInsurance,
                  pageName: PageNames.patientData,
                });
              }}
            />
            <DentLink
              text="Already a patient? Log in"
              onPress={() => {
                setIsModalOpen(true);
                logGTMEvent({
                  event: EventNames.npLoginBtnClick,
                  pageName: PageNames.patientData,
                });
              }}
            />
          </StyledLeftColumn>
          <StyledColumn>
            <Button
              label="Continue"
              disabled={
                !name ||
                !lastName ||
                !email ||
                !isEmailValid ||
                !phone ||
                !isPhoneValid ||
                !birthDate ||
                !isBirthDateValid
              }
              mQueryCellphoneWidth="100%"
              onPress={handleContinueButton}
            />
            <StyledContainer>
              <PhoneDetail>{strings.BY_CLICKING} </PhoneDetail>
              <TermsAndConditions
                onClick={() => window.open(strings.DENTOLOGIE_URL_TERMS)}
              >
                {strings.TERMS_OF_SERVICE}
              </TermsAndConditions>
            </StyledContainer>
          </StyledColumn>
        </StyledBigContainer>
      </Container>
      {isModalOpen && (
        <AreYouSure
          open={isModalOpen}
          title={strings.ARE_YOU_SURE}
          details={strings.PROGRESS_NOT_SAVED}
          acceptButton={strings.YES}
          setOpen={setIsModalOpen}
          onAccept={returnToLogin}
        />
      )}
    </>
  );
};

export default PatientData;
