import React, { useState, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import {
  Container,
  Header,
  AppointmentInformation,
  LeftBox,
  LocationBox,
  TitleBox,
  DescriptionBox,
  HeaderIcon,
  Location,
  Description,
  DateAndTimeBox,
  RightBox,
  AppointmentTypeBox,
  AppointmentOnHold,
  AppointmentPlaceholder,
  AppointmentTimer,
  ProgressBarContainer,
  Title,
  SeePlans,
  WarningBlueText,
  WarningRedText,
  ButtonContainer,
  BoxStyling,
  IconAndTitleBox,
  IconAndTitleBoxLocation,
  WarningRedText1,
} from './styles';
import { Tooth, MapPin, Calendar } from '../../assets/images';
import AreYouSure from '../../components/AreYouSure';
import BackButton from '../../components/BackButton';
import Button from '../../components/Button';
import ErrorModal from '../../components/ErrorModal';
import ProgressBar from '../../components/ProgressBar';
import RadioButton from '../../components/RadioButton';
import { useApi } from '../../hooks';
import {
  setCurrentLoginFlowScreen,
  setFromNPFlowLocSelected,
} from '../../slices/loginSlice';
import { formatApptType, formatSelectedApptTime } from '../../utils/functions';
import { logGTMEvent } from '../../utils/gtm/gtmHelpers';
import { EventNames, PageNames } from '../../utils/gtm/gtmTypes';
import * as strings from '../../utils/strings';
import operations from '../PatientData/operations';

interface DentalInsuranceProps {
  returnToLogin?: () => void;
  CountdownComponent: () => JSX.Element | null;
}

const DoYouHaveDentalInsurance = ({
  returnToLogin = () => null,
  CountdownComponent = () => null,
}: DentalInsuranceProps) => {
  const [windowSize, setWindowSize] = useState(window.innerWidth);
  const [buttonHasBeenCliked, setButtonHasBeenCliked] = useState(false);

  const [option1Warning, setOption1Warning] = useState(false);
  const [option2Warning, setOption2Warning] = useState(false);
  const [option3Warning, setOption3Warning] = useState(false);
  const [option1Selected, setOption1Selected] = useState(false);
  const [option2Selected, setOption2Selected] = useState(false);
  const [option3Selected, setOption3Selected] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [openErrorModal, setOpenErrorModal] = useState<boolean>(false);
  const [userExists, setUserExists] = useState<boolean>(false);

  const { selectedLocation } = useSelector(
    (state: RootState) => state.locations,
  );

  const { selectedApptType, selectedRawDate, selectedAppointmentTime } =
    useSelector((state: RootState) => state.appointment);

  const { newUserFormData } = useSelector((state: RootState) => state.userData);

  const dispatch = useDispatch();

  const { request: postCreateUser, loading: postCreateUserLoading } = useApi(
    operations.createUser,
  );

  const getInsuranceDesc = () => {
    if (option1Selected) {
      return 'I have dental insurance';
    }
    if (option2Selected) {
      return 'I have Medicare or Medicaid';
    }
    if (option3Selected) {
      return 'I do not have dental insurance and will pay out of pocket';
    }
    return '';
  };

  const logInsSelected = () =>
    logGTMEvent({
      event: EventNames.npInsSelected,
      eventData: {
        insuranceDesc: getInsuranceDesc(),
      },
      pageName: PageNames.dentalInsurance,
    });

  const handleRadioButton1 = () => {
    setOption1Warning(true);
    setOption2Warning(false);
    setOption3Warning(false);
    setOption1Selected(true);
    setOption2Selected(false);
    setOption3Selected(false);
    logInsSelected();
  };
  const handleRadioButton2 = () => {
    setOption2Warning(true);
    setOption3Warning(false);
    setOption1Warning(false);
    setOption1Selected(false);
    setOption2Selected(true);
    setOption3Selected(false);
    logInsSelected();
  };
  const handleRadioButton3 = () => {
    setOption3Warning(true);
    setOption2Warning(false);
    setOption1Warning(false);
    setOption1Selected(false);
    setOption2Selected(false);
    setOption3Selected(true);
    logInsSelected();
  };

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize(window.innerWidth);
    };
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  });

  const handleSeeWhichPlans = () => {
    window.open(strings.DENTOLOGIE_URL_INSURANCE);

    logGTMEvent({
      event: EventNames.npSeeInsurancePlans,
      pageName: PageNames.dentalInsurance,
    });
  };

  const handleContinueButton = async () => {
    if (option2Selected || option3Selected) {
      setButtonHasBeenCliked(true);
    } else {
      try {
        await postCreateUser(newUserFormData);
        dispatch(setCurrentLoginFlowScreen('verifyPhoneScreen'));
      } catch (error: any) {
        if (error?.response?.status === 400) {
          setUserExists(true);
          dispatch(setFromNPFlowLocSelected(true));
        } else {
          setOpenErrorModal(true);
        }
      }
    }

    logGTMEvent({
      event: EventNames.npInsSubmitted,
      eventData: {
        insuranceDesc: getInsuranceDesc(),
      },
      pageName: PageNames.dentalInsurance,
    });
  };

  const handleBackButton = () => {
    dispatch(setCurrentLoginFlowScreen('patientDataScreen'));
  };

  const handleAreYouSure = async () => {
    try {
      await postCreateUser(newUserFormData);
      setButtonHasBeenCliked(false);
      dispatch(setCurrentLoginFlowScreen('verifyPhoneScreen'));
    } catch (error: any) {
      setButtonHasBeenCliked(false);
      if (error?.response?.status === 400) {
        setUserExists(true);
      } else {
        setOpenErrorModal(true);
      }
    }
  };

  return (
    <>
      <Container>
        <Header>
          <BackButton onClick={handleBackButton} />
          <AppointmentInformation>
            <LeftBox>
              <LocationBox>
                <TitleBox>{strings.LOCATION}</TitleBox>
                <DescriptionBox>
                  <IconAndTitleBoxLocation>
                    <HeaderIcon src={MapPin} />
                    {/* TODO: replace with dynamic data from BE */}
                    <Location>
                      {selectedLocation.title || selectedLocation.name}
                    </Location>
                  </IconAndTitleBoxLocation>
                  <Description>
                    {/* TODO: replace with dynamic data from BE */}
                    {`${selectedLocation?.fullAddress?.address1}, ${selectedLocation?.fullAddress?.city}, ${selectedLocation?.fullAddress?.state} ${selectedLocation?.fullAddress?.postalCode}`}
                  </Description>
                </DescriptionBox>
              </LocationBox>
              <DateAndTimeBox>
                <TitleBox>{strings.DATE_TIME}</TitleBox>
                <DescriptionBox>
                  <IconAndTitleBox>
                    <HeaderIcon src={Calendar} />
                    {/* TODO: replace with dynamic data from BE */}
                    <Description>
                      {formatSelectedApptTime(
                        selectedRawDate,
                        selectedAppointmentTime,
                      )}
                    </Description>
                  </IconAndTitleBox>
                </DescriptionBox>
              </DateAndTimeBox>
            </LeftBox>
            <RightBox>
              <AppointmentTypeBox>
                <TitleBox>{strings.APPOINTMENT_TYPE}</TitleBox>
                <DescriptionBox>
                  <IconAndTitleBox>
                    <HeaderIcon src={Tooth} />
                    {/* TODO: replace with dynamic data from BE */}
                    <Description>
                      {formatApptType(selectedApptType)}
                    </Description>
                  </IconAndTitleBox>
                </DescriptionBox>
              </AppointmentTypeBox>
              <AppointmentOnHold>
                <AppointmentPlaceholder>
                  {strings.APPOINTMENT_ON_HOLD}
                </AppointmentPlaceholder>
                {/* TODO: ask how to handle this timer */}
                <AppointmentTimer>
                  <CountdownComponent />
                </AppointmentTimer>
              </AppointmentOnHold>
            </RightBox>
          </AppointmentInformation>
        </Header>
        <ProgressBarContainer>
          <ProgressBar initialValue={60} />
        </ProgressBarContainer>
        <Title>{strings.DO_YOU_HAVE_DENTAL_INSURANCE}</Title>
        <RadioButton
          label="I have dental insurance"
          isSelected={option1Selected}
          onPress={handleRadioButton1}
        />
        {option1Warning && (
          <WarningBlueText>
            {strings.GREAT_WE_ACCEPT_MOST_DENTAL_PLANS}
          </WarningBlueText>
        )}
        <RadioButton
          label="I have Medicare or Medicaid"
          isSelected={option2Selected}
          onPress={handleRadioButton2}
        />
        {option2Warning && (
          <WarningRedText1>
            {windowSize > 767
              ? strings.WE_DONT_ACCEPT_MEDICARE
              : strings.WE_DONT_ACCEPT_MEDICAR_MOBILE}{' '}
          </WarningRedText1>
        )}
        <RadioButton
          label="I don’t have dental insurance and will pay out of pocket"
          isSelected={option3Selected}
          onPress={handleRadioButton3}
        />
        {option3Warning && (
          <WarningRedText>
            {windowSize > 767
              ? strings.IT_IS_ALL_GOOD
              : strings.IT_IS_ALL_GOOD_MOBILE}
          </WarningRedText>
        )}
        <SeePlans onClick={handleSeeWhichPlans}>
          {strings.SEE_WHICH_PLANS_WE_ACCEPT}
        </SeePlans>
        <ButtonContainer>
          <BoxStyling />
          <Button
            label="Continue"
            width="40%"
            mQueryCellphoneWidth="100%"
            disabled={!option1Selected && !option2Selected && !option3Selected}
            isLoading={postCreateUserLoading}
            onPress={handleContinueButton}
          />
        </ButtonContainer>
        <AreYouSure
          open={buttonHasBeenCliked}
          isLoading={postCreateUserLoading}
          onAccept={handleAreYouSure}
          setOpen={() => setButtonHasBeenCliked(false)}
          details="You will have to pay out of pocket for your visit"
        />
      </Container>
      <ErrorModal open={openErrorModal} setOpen={setOpenErrorModal} />
      <ErrorModal
        open={userExists}
        setOpen={setUserExists}
        showContinue
        onContinue={() =>
          dispatch(setCurrentLoginFlowScreen('loginDetailsScreen'))
        }
        buttonText="Go to Login"
        title="That user already exists"
        details="An account with those credentials already exists. Click below to go to the login screen."
      />
      {isModalOpen && (
        <AreYouSure
          open={isModalOpen}
          title={strings.ARE_YOU_SURE}
          details={strings.PROGRESS_NOT_SAVED}
          acceptButton={strings.YES}
          setOpen={setIsModalOpen}
          onAccept={returnToLogin}
        />
      )}
    </>
  );
};

export default DoYouHaveDentalInsurance;
