/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface AppointmentProps {
  selectedAppointmentDate: string;
  selectedAppointmentTime: string;
  selectedApptType: string;
  selectedRawDate: string;
  lockApptData: any;
  appointmentTypes: any;
  apptTypesOptionsBySelLocation: any;
}

export const initialAppointmentState: AppointmentProps = {
  selectedAppointmentDate: '',
  selectedAppointmentTime: '',
  selectedApptType: '',
  selectedRawDate: '',
  appointmentTypes: '',
  lockApptData: '',
  apptTypesOptionsBySelLocation: '',
};

const appointmentSlice = createSlice({
  name: 'appointmentSlice',
  initialState: initialAppointmentState,
  reducers: {
    setSelectedAppoinmentDate: (state, { payload }: PayloadAction<any>) => {
      state.selectedAppointmentDate = payload;
    },
    setSelectedAppoinmentTime: (state, { payload }: PayloadAction<any>) => {
      state.selectedAppointmentTime = payload;
    },
    setSelectedRawDate: (state, { payload }: PayloadAction<any>) => {
      state.selectedRawDate = payload;
    },
    setAppointmentType: (state, { payload }: PayloadAction<any>) => {
      state.appointmentTypes = payload;
    },
    setSelectedAppointmentType: (state, { payload }: PayloadAction<any>) => {
      state.selectedApptType = payload;
    },
    setLockApptResData: (state, { payload }: PayloadAction<any>) => {
      state.lockApptData = payload;
    },
    setApptTypesOptionsBySelLocation: (
      state,
      { payload }: PayloadAction<any>,
    ) => {
      state.apptTypesOptionsBySelLocation = payload;
    },
  },
});
export default appointmentSlice.reducer;

export const {
  setSelectedAppoinmentDate,
  setSelectedAppoinmentTime,
  setSelectedRawDate,
  setAppointmentType,
  setSelectedAppointmentType,
  setLockApptResData,
  setApptTypesOptionsBySelLocation,
} = appointmentSlice.actions;
