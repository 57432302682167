import React from 'react';

import {
  CardContainer,
  FullWidthCardContainer,
  Icon,
  Title,
  TitleContainer,
  TitleSubtitle,
} from './styles';
import { SmallWarningCircle } from '../../assets/images';

interface OrangeCardProps {
  title: string;
  subtitle?: string;
  height?: string;
  positionAbsoluteCss?: boolean;
  margin?: boolean;
  marginTop?: boolean | string;
  marginLeft?: boolean;
  width?: boolean;
  fullWidth?: boolean;
  onClick?: () => void;
}

const OrangeCard: React.FC<OrangeCardProps> = ({
  title,
  subtitle,
  height = '51px',
  positionAbsoluteCss,
  margin,
  marginTop,
  marginLeft,
  width,
  fullWidth,
  onClick = () => null,
}: OrangeCardProps) =>
  !fullWidth ? (
    <CardContainer
      height={height}
      positionAbsoluteCss={positionAbsoluteCss}
      margin={margin}
      marginTop={marginTop}
      marginLeft={marginLeft}
      width={width}
      onClick={onClick}
    >
      <TitleContainer>
        <Icon src={SmallWarningCircle} alt="" />
        <Title>{title}</Title>
      </TitleContainer>
      <TitleSubtitle>{subtitle}</TitleSubtitle>
    </CardContainer>
  ) : (
    <FullWidthCardContainer
      height={height}
      positionAbsoluteCss={positionAbsoluteCss}
      margin={margin}
      marginTop={marginTop}
      marginLeft={marginLeft}
      width={width}
    >
      <TitleContainer>
        <Icon src={SmallWarningCircle} alt="" />
        <Title>{title}</Title>
      </TitleContainer>
      <TitleSubtitle>{subtitle}</TitleSubtitle>
    </FullWidthCardContainer>
  );

export default OrangeCard;
