import styled from 'styled-components';

import theme from '../../utils/theme';

const { colors } = theme;

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
  background: ${colors.cardTan};
  @media (max-width: 767px) {
  }
`;
