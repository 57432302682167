/* eslint-disable import/no-unresolved */
import { TextField } from '@material-ui/core';
import styled from 'styled-components';
import theme from 'utils/theme';

export const StyledContainer = styled.div`
  margin-top: 10px;
  width: 100%;
`;
export const StyledTextField = styled(TextField)`
  & .MuiInput-underline:hover:before {
    border: 'none !important';
  }
  background: transparent;
  & label.Mui-focused {
    color: ${theme.colors.black};
  }
  & .MuiInput-underline {
  }
  & .MuiInputLabel-root {
    font-family: ${theme.fonts.GintoRegular} !important;
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1.15px;
    color: ${theme.colors.black};
  }
  & .Mui-focused {
    border-bottom: none;
  }

  & .MuiInputBase-root {
    font-family: ${theme.fonts.GintoRegular};
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1.15px;
    padding: 4px;
    &:after {
      border-bottom: 0.5px solid ${theme.colors.black};
    }
    &:before {
      border-bottom: 0.5px solid ${theme.colors.black};
      &:hover {
        border-bottom: 0px solid ${theme.colors.black};
      }
    }
  }
`;
