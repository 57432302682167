/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useState } from 'react';

import { isPossiblePhoneNumber } from 'libphonenumber-js';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import isEmail from 'validator/lib/isEmail';

import operations from './operations';
import {
  ProgressBarContainer,
  LoginTitle,
  ButtonsContainer,
  NewPatientButton,
  NewPatientButtonTitle,
  OutsideContainer,
} from './styles';
import ErrorModal from '../../components/ErrorModal';
import ProgressBar from '../../components/ProgressBar';
import { useApi } from '../../hooks/useApi';
import {
  setApptTypesOptionsBySelLocation,
  setSelectedAppointmentType,
} from '../../slices/appointmentSlice';
import { setSelectedLocation } from '../../slices/locationsSlice';
import {
  setCurrentLoginFlowScreen,
  setIsNewPatient,
  setSkipChooseAppointmentType,
} from '../../slices/loginSlice';
import { filterLocationSelected } from '../../utils/functions';
import { logGTMEvent } from '../../utils/gtm/gtmHelpers';
import {
  ApptNames,
  EventNames,
  GTMEvent,
  PageNames,
} from '../../utils/gtm/gtmTypes';
import * as strings from '../../utils/strings';
import operationsApptTypes from '../ChooseAppointmentType/operations';
import { getAllLocations } from '../ChooseLocation/operations';

const Login = () => {
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(true);
  const [contact, setContact] = useState<string>('');
  const [openErrorModal, setOpenErrorModal] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [loginError, setLoginError] = useState<boolean>(false);
  const { allLocations, locationDataWithAppt, locationCards } = useSelector(
    (state: RootState) => state.locations,
  );
  const { loggedIn } = useSelector((state: RootState) => state.session);
  const { error: loginErrorReq, statusCode } = useApi(operations.getOTP);
  const dispatch = useDispatch();

  useEffect(() => {
    setOpenErrorModal(loginErrorReq);
    setLoginError(loginErrorReq);
  }, [loginErrorReq]);

  useEffect(() => {
    if (!openErrorModal) {
      setLoginError(false);
    }
  }, [openErrorModal]);

  const { request: getAllLocationsReq } = useApi(getAllLocations);

  const { request: getAppointmentTypesReq } = useApi(
    operationsApptTypes.getAppointmentTypes,
  );

  useEffect(() => {
    const isContactValid = isEmail(contact) || isPossiblePhoneNumber(contact);
    if (isContactValid) {
      setIsButtonDisabled(false);
    } else {
      setIsButtonDisabled(true);
    }
  }, [contact]);

  const handleNewPatientButton = () => {
    logGTMEvent({
      event: EventNames.npClick,
      pageName: PageNames.login,
    });

    dispatch(setIsNewPatient(true));
    if (searchParams.get('isEmergency') === 'true') {
      // Backfill GTM events from homepage widget
      const eventArray: GTMEvent[] = [
        {
          event: EventNames.npLocationSelected,
          eventData: {
            locationId: `${searchParams.get('locationId')}`,
          },
          pageName: PageNames.homepageWidget,
        },
        {
          event: EventNames.npApptTypeSelected,
          eventData: {
            apptType: ApptNames.npEmergency,
          },
          pageName: PageNames.homepageWidget,
        },
      ];

      eventArray.map(event => logGTMEvent(event));

      dispatch(setCurrentLoginFlowScreen('chooseAppointmentScreen'));
    } else if (searchParams.get('locationId')) {
      // Backfill GTM event from homepage widget
      logGTMEvent({
        event: EventNames.npLocationSelected,
        eventData: {
          locationId: `${searchParams.get('locationId')}`,
        },
        pageName: searchParams.get('locationId')
          ? PageNames.hubSpotLocationPage
          : PageNames.homepageWidget,
      });

      dispatch(setCurrentLoginFlowScreen('chooseAppointmentTypeScreen'));
    } else {
      dispatch(setCurrentLoginFlowScreen('chooseLocationsScreen'));
    }
  };

  const handleInputChange = async (e: any) => {
    let inputContact = e.target.value;
    // restrict maximum length of input to 14 characters (i.e. formatted phone number length)
    let formattedContact = inputContact;
    // check if input is phone number format
    const phoneRegex = /^(\d{3})[- .]?(\d{3})[- .]?(\d{4})$/;
    if (phoneRegex.test(inputContact)) {
      if (inputContact.length > 10) {
        inputContact = inputContact.slice(0, 10);
      }
      formattedContact = inputContact
        .replace(/\D/g, '')
        .replace(/(\d{3})(\d{3})(\d{4})/, '($1)-$2-$3');
    }
    // check if input is email format
    const emailRegex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;
    if (emailRegex.test(inputContact)) {
      formattedContact = inputContact.toLowerCase();
    }
    setContact(formattedContact);
  };

  useEffect(() => {
    const regex =
      /^(?:\d{10}|\(\d{3}\)-\d{3}-\d{4}|[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/;
    const isContactValid = regex.test(contact);
    if (isContactValid) {
      setIsButtonDisabled(false);
    } else {
      setIsButtonDisabled(true);
    }
  }, [contact]);

  useEffect(() => {
    if (statusCode === 500) setOpenErrorModal(true);
  }, [statusCode]);

  useEffect(() => {
    getAppointmentTypesReq();
    getAllLocationsReq();
  }, []);

  useEffect(() => {
    if (searchParams) {
      const locationId = searchParams.get('locationId');
      const isEmergency = searchParams.get('isEmergency');
      if (locationId && locationCards) {
        const selectedLocation = locationCards.filter(
          (item: any) => item.id === locationId,
        );
        if (selectedLocation) {
          const filteredAppts = filterLocationSelected(
            selectedLocation[0]?.name,
            locationDataWithAppt,
          );
          dispatch(setSelectedLocation(selectedLocation[0]));
          dispatch(setApptTypesOptionsBySelLocation(filteredAppts));
        }
        if (isEmergency === 'true') {
          dispatch(setSelectedAppointmentType('EMERGENCY'));
          dispatch(setSkipChooseAppointmentType(true));
        }
      }
    }
  }, [searchParams, allLocations, locationDataWithAppt]);

  useEffect(() => {
    if (loggedIn) {
      dispatch(setCurrentLoginFlowScreen('chooseLocationsScreen'));
    }
  }, [loggedIn]);

  return (
    <OutsideContainer>
      <ProgressBarContainer>
        <ProgressBar initialValue={2} />
      </ProgressBarContainer>
      <LoginTitle>{strings.HAVE_YOU_VISITED}</LoginTitle>
      <ButtonsContainer>
        <NewPatientButton onClick={handleNewPatientButton}>
          <NewPatientButtonTitle>
            {strings.IM_A_NEW_PATIENT}
          </NewPatientButtonTitle>
        </NewPatientButton>
        <NewPatientButton
          onClick={() => {
            dispatch(setCurrentLoginFlowScreen('loginDetailsScreen'));
            logGTMEvent({
              event: EventNames.epClick,
              pageName: PageNames.login,
            });
          }}
        >
          <NewPatientButtonTitle>
            {strings.IM_AN_EXISTING_PATIENT}
          </NewPatientButtonTitle>
        </NewPatientButton>
      </ButtonsContainer>
      <ErrorModal
        open={openErrorModal}
        setOpen={setOpenErrorModal}
        title={loginError ? strings.INVALID_LOGIN : undefined}
        details={loginError ? strings.LOGIN_ERROR : undefined}
      />
    </OutsideContainer>
  );
};

export default Login;
