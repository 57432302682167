import React from 'react';

import {
  BackPageButton,
  BackButtonWrapper,
  BackArrow,
  ButtonName,
} from './styles';
import { ArrowLeftIcon } from '../../assets/images';
import * as strings from '../../utils/strings';

interface BackButtonProps {
  onClick?: () => void;
}

const BackButton: React.FC<BackButtonProps> = ({ onClick }) => (
  <BackButtonWrapper>
    <BackPageButton onClick={onClick}>
      <BackArrow src={ArrowLeftIcon} />
      <ButtonName>{strings.BACK}</ButtonName>
    </BackPageButton>
  </BackButtonWrapper>
);

export default BackButton;
