import api from '../../api/methods';
import store from '../../redux/store';

export interface PaymentIntentRequest {
  email: string;
  phoneNumber: string;
}

export interface PaymentIntentResponse {
  paymentIntent: string;
  ephemeralKey: string;
  customer: string;
  publishableKey: string;
}

// FIXME: Refactor operations into a top-level resource.

export const getPaymentIntent = async (requestBody: PaymentIntentRequest) => {
  try {
    const { accessToken } = store.getState().session.user;
    const headers = {
      Authorization: `Bearer ${accessToken}`,
    };
    const response = await api.post('/user/paymentIntent', requestBody, {
      headers,
    });
    return response;
  } catch (error) {
    return error as any;
  }
};
