import { AxiosResponse } from 'axios';
import dayjs from 'dayjs';

import api from '../../api/methods';
import store from '../../redux/store';
import { setCurrentLoginFlowScreen } from '../../slices/loginSlice';
import { setUser } from '../../slices/userDataSlice';

export const getProfile = async () => {
  const token = store.getState().session.user.accessToken;
  await api
    .get('/user/profile', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res: AxiosResponse) => {
      const profile = res.data?.with;
      store.dispatch(setUser(profile));
      return res.data.with;
    })
    .catch(error => error);
};

const patientMatch = async (params: any) => {
  try {
    const { lastName, year, month, day } = params;
    const dataToSend = {
      lastName: lastName,
      dateOfBirth: dayjs(`${year}-${month}-${day}`, 'YYYY-M-D').format(
        'YYYY-MM-DD',
      ),
    };
    const res = await api.post('/user/otp/patientmatch', dataToSend);
    if (res.data.status === 'succeeded') {
      await getProfile();
      // TODO: send user to home screen
      store.dispatch(setCurrentLoginFlowScreen('existingPatientScreen'));
    }
    // failed status is being handle inside existingPatientScreen component

    return res as any;
  } catch (err: any) {
    return err?.response;
  }
};

export default { patientMatch };
