import React, { useRef } from 'react';

import { useSelector } from 'react-redux';

import {
  CardWrapper,
  CardTitle,
  CardDescription,
  CardSubTitle,
  TitlesWrapper,
  QuestionRigthIcon,
  TitlesContainer,
  DescriptionWrapper,
  DescriptionIcon,
  DivisonLine,
  StyledWarningIcon,
  TitleAndIconContainer,
  QuestionIconWrapper,
  HomepageCardWrapper,
} from './styles';
import { QuestionIcon, ArrowRightIcon, WarningIcon } from '../../assets/images';
import { logGTMEvent } from '../../utils/gtm/gtmHelpers';
import { EventNames, PageType } from '../../utils/gtm/gtmTypes';
import * as strings from '../../utils/strings';

export interface CardProps {
  id?: string;
  title: string;
  homePage?: boolean;
  description: string;
  subtitle?: string;
  timeZoneString?: string;
  fullAddress?: any;
  displayWarningIcon: boolean;
  onClick?: () => void;
  positionAbsoluteCss?: boolean;
  margin?: boolean;
  marginTop?: boolean;
  marginLeft?: boolean;
  width?: boolean | string;
  timeZone?: string;
  showQuestionIcon?: boolean;
  showHoverState?: boolean;
  previousScreen?: string;
  nextEmergency?: string;
}

const Card: React.FC<CardProps> = ({
  title,
  description,
  subtitle,
  fullAddress,
  displayWarningIcon,
  onClick,
  positionAbsoluteCss,
  margin,
  marginTop,
  marginLeft,
  width,
  showQuestionIcon,
  showHoverState,
  previousScreen,
  homePage,
}) => {
  const cardRef = useRef<any>(null);

  const { currentLoginFlowScreen } = useSelector(
    (state: RootState) => state.session,
  );

  const AppointmentTypeToLink = (appointmentType: string) => {
    switch (appointmentType) {
      case 'TEETH CLEANING (ADULT)':
        return 'http://dentologie.com/new-patient-exam';
      case 'EMERGENCY':
        return 'http://dentologie.com/emergency-dental-care';
      case 'INVISALIGN CONSULT':
        return 'http://dentologie.com/invisalign';
      case 'TEETH CLEANING (CHILD)':
        return 'http://dentologie.com/new-patient-exam';
      case 'TEETH WHITENING':
        return 'http://dentologie.com/teeth-whitening';
      default:
        return 'https://dentologie.com/';
    }
  };

  const addressSubtitle = `${fullAddress?.address1} ${fullAddress?.city}, ${fullAddress?.state} ${fullAddress?.postalCode}`;

  return !homePage ? (
    <CardWrapper
      onClick={onClick}
      positionAbsoluteCss={positionAbsoluteCss}
      margin={margin}
      marginTop={marginTop}
      marginLeft={marginLeft}
      width={width}
      showHoverState={showHoverState}
      previousScreen={previousScreen}
      ref={cardRef}
    >
      <TitlesContainer>
        <TitlesWrapper>
          <TitleAndIconContainer>
            {displayWarningIcon && <StyledWarningIcon src={WarningIcon} />}
            <CardTitle>{title}</CardTitle>
          </TitleAndIconContainer>
          {showQuestionIcon && (
            <QuestionIconWrapper
              onClick={event => {
                event.stopPropagation();
                window.open(AppointmentTypeToLink(title), '_blank');
                logGTMEvent({
                  event: EventNames.glApptTypeInfoClick,
                  pageName: currentLoginFlowScreen as PageType,
                });
              }}
            >
              <QuestionRigthIcon src={QuestionIcon} />
            </QuestionIconWrapper>
          )}
        </TitlesWrapper>
        {fullAddress ? (
          <>
            <CardSubTitle style={{ marginBottom: 0 }}>
              {fullAddress?.address1}{' '}
              {previousScreen === 'chooseApptType' && 'min'}
            </CardSubTitle>
            <CardSubTitle style={{ marginTop: 0 }}>
              {`${fullAddress?.city}, ${fullAddress?.state} ${fullAddress?.postalCode}`}
              {previousScreen === 'chooseApptType' && 'min'}
            </CardSubTitle>
          </>
        ) : (
          <CardSubTitle>
            {subtitle} {previousScreen === 'chooseApptType' && 'min'}
          </CardSubTitle>
        )}
      </TitlesContainer>

      <DivisonLine />
      <DescriptionWrapper>
        <div style={{ minHeight: '38px' }}>
          <CardDescription>{`${description}`}</CardDescription>
        </div>
        <DescriptionIcon src={ArrowRightIcon} />
      </DescriptionWrapper>
    </CardWrapper>
  ) : (
    <HomepageCardWrapper
      onClick={onClick}
      positionAbsoluteCss={positionAbsoluteCss}
      margin={margin}
      marginTop={marginTop}
      marginLeft={marginLeft}
      width={width}
      showHoverState={showHoverState}
      previousScreen={previousScreen}
      ref={cardRef}
      homepage={homePage}
    >
      <TitlesContainer>
        <TitlesWrapper>
          <TitleAndIconContainer>
            {displayWarningIcon && <StyledWarningIcon src={WarningIcon} />}
            <CardTitle>{title}</CardTitle>
          </TitleAndIconContainer>
          {showQuestionIcon && (
            <QuestionIconWrapper
              href={strings.DENTOLOGIE_URL}
              target="_blank"
              rel="noreferrer noopener"
            >
              <QuestionRigthIcon src={QuestionIcon} />
            </QuestionIconWrapper>
          )}
        </TitlesWrapper>
        <CardSubTitle homepage>
          {addressSubtitle} {previousScreen === 'chooseApptType' && 'min'}
        </CardSubTitle>
      </TitlesContainer>

      <DivisonLine />
      <DescriptionWrapper>
        <CardDescription>{description}</CardDescription>
        <DescriptionIcon src={ArrowRightIcon} />
      </DescriptionWrapper>
    </HomepageCardWrapper>
  );
};

export default Card;
