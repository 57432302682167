import React, { useState, useEffect } from 'react';

import { Elements } from '@stripe/react-stripe-js';
import { Stripe, loadStripe } from '@stripe/stripe-js';
import { parsePhoneNumber } from 'libphonenumber-js';
import { useDispatch, useSelector } from 'react-redux';

import CheckoutForm from './CheckoutForm';
import { PaymentIntentRequest, getPaymentIntent } from './operations';
import {
  Container,
  Header,
  AppointmentInformation,
  LeftBox,
  LocationBox,
  TitleBox,
  DescriptionBox,
  HeaderIcon,
  Location,
  Description,
  DateAndTimeBox,
  RightBox,
  AppointmentTypeBox,
  AppointmentOnHold,
  AppointmentPlaceholder,
  AppointmentTimer,
  ProgressBarContainer,
  Title,
  Subtitle,
  IconAndTitleBox,
  IconAndTitleBoxLocation,
  PaymentHeader,
  PaymentTitleText,
  Link,
  ButtonContainer,
} from './styles';
import { Tooth, MapPin, Calendar } from '../../assets/images';
import BackButton from '../../components/BackButton';
import Button from '../../components/Button';
import ProgressBar from '../../components/ProgressBar';
import { useApi } from '../../hooks';
import { setCurrentLoginFlowScreen } from '../../slices/loginSlice';
import { formatSelectedApptTime, formatSentence } from '../../utils/functions';
import { logGTMEvent } from '../../utils/gtm/gtmHelpers';
import { EventNames, PageNames } from '../../utils/gtm/gtmTypes';
import * as strings from '../../utils/strings';
import theme from '../../utils/theme';

const { colors, fonts } = theme;

interface PaymentScreenProps {
  CountdownComponent: () => JSX.Element | null;
}

const AddPaymentMethod = ({
  CountdownComponent = () => null,
}: PaymentScreenProps) => {
  const dispatch = useDispatch();

  const [, setWindowSize] = useState(window.innerWidth);
  const [stripePromise, setStripePromise] =
    useState<Promise<Stripe | null> | null>(null);
  const [clientSecret, setClientSecret] = useState<string | undefined>('');

  const { selectedLocation, allLocations } = useSelector(
    (state: RootState) => state.locations,
  );
  const { id: selectedLocationId } = selectedLocation;
  const {
    config: { cofDescription },
  } = allLocations.find((location: any) => location.id === selectedLocationId);
  const { userEmail, userPhone } = useSelector(
    (state: RootState) => state.userData,
  );
  const { selectedApptType, selectedRawDate, selectedAppointmentTime } =
    useSelector((state: RootState) => state.appointment);
  const { isNewPatient } = useSelector((state: RootState) => state.session);

  const {
    request: getPaymentIntentRequest,
    data: paymentIntentData,
    loading: getPaymentIntentLoading,
  } = useApi(getPaymentIntent);

  const {
    fullAddress: { address1, city, state, postalCode },
  } = selectedLocation;

  const address = `${address1}, ${city}, ${state} ${postalCode}`;

  const handleClickAddPayment = () => {
    if (!paymentIntentData && !getPaymentIntentLoading) {
      const phoneNumber = parsePhoneNumber(userPhone, 'US');
      const phoneNumberFormatted = phoneNumber.format('E.164');

      const requestBody: PaymentIntentRequest = {
        email: userEmail,
        phoneNumber: phoneNumberFormatted,
      };

      getPaymentIntentRequest(requestBody);
    }
  };

  useEffect(() => {
    if (paymentIntentData && !getPaymentIntentLoading) {
      const { paymentIntent, publishableKey } = paymentIntentData?.with || '';
      setStripePromise(loadStripe(publishableKey));
      setClientSecret(paymentIntent);
    }
  }, [paymentIntentData, getPaymentIntentLoading]);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize(window.innerWidth);
    };
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  });

  const handleBackButton = () => {
    if (isNewPatient) {
      dispatch(setCurrentLoginFlowScreen('patientDataScreen'));
    } else {
      dispatch(setCurrentLoginFlowScreen('chooseAppointmentScreen'));
    }
  };

  const appearance = {
    variables: {
      borderRadius: '6',
      colorBackground: colors.backgroundTan,
      colorDanger: colors.pink2,
      colorText: colors.black,
      colorTextPlaceholder: colors.boxShadow,
      fontFamily: fonts.GintoRegular,
      fontSizeBase: '14',
    },
  };

  const options = {
    clientSecret,
    appearance,
  };

  return (
    <Container>
      {/**
       * FIXME: Refactor into discrete components. Perhaps a matter of taste, but usage of styled React components also impairs readability.
       */}
      <Header>
        <BackButton onClick={handleBackButton} />
        <AppointmentInformation>
          <LeftBox>
            <LocationBox>
              <TitleBox>{strings.LOCATION}</TitleBox>
              <DescriptionBox>
                <IconAndTitleBoxLocation>
                  <HeaderIcon src={MapPin} />
                  <Location>
                    {selectedLocation.title || selectedLocation.name}
                  </Location>
                </IconAndTitleBoxLocation>
                <Description>{address}</Description>
              </DescriptionBox>
            </LocationBox>
            <DateAndTimeBox>
              <TitleBox>{strings.DATE_TIME}</TitleBox>
              <DescriptionBox>
                <IconAndTitleBox>
                  <HeaderIcon src={Calendar} />
                  <Description>
                    {formatSelectedApptTime(
                      selectedRawDate,
                      selectedAppointmentTime,
                    )}
                  </Description>
                </IconAndTitleBox>
              </DescriptionBox>
            </DateAndTimeBox>
          </LeftBox>
          <RightBox>
            <AppointmentTypeBox>
              <TitleBox>{strings.APPOINTMENT_TYPE}</TitleBox>
              <DescriptionBox>
                <IconAndTitleBox>
                  <HeaderIcon src={Tooth} />
                  <Description>{formatSentence(selectedApptType)}</Description>
                </IconAndTitleBox>
              </DescriptionBox>
            </AppointmentTypeBox>
            {isNewPatient && (
              <AppointmentOnHold>
                <AppointmentPlaceholder>
                  {strings.APPOINTMENT_ON_HOLD}
                </AppointmentPlaceholder>
                <AppointmentTimer>
                  <CountdownComponent />
                </AppointmentTimer>
              </AppointmentOnHold>
            )}
          </RightBox>
        </AppointmentInformation>
      </Header>
      <ProgressBarContainer>
        <ProgressBar initialValue={60} />
      </ProgressBarContainer>
      <Title>{strings.NEED_COF}</Title>
      <Subtitle>{cofDescription || strings.COF_NO_CHARGE}</Subtitle>
      {!paymentIntentData && (
        <ButtonContainer>
          <Button
            label={strings.ADD_A_CARD}
            isLoading={getPaymentIntentLoading}
            onPress={handleClickAddPayment}
            mQueryCellphoneWidth="339px"
            width="339px"
          />
        </ButtonContainer>
      )}
      {!!paymentIntentData && (
        <PaymentHeader>
          <PaymentTitleText>{strings.ADD_PAYMENT_METHOD}</PaymentTitleText>
          <Link
            href={`${strings.DENTOLOGIE_URL}/cancellation-policy`}
            onClick={() => {
              logGTMEvent({
                event: EventNames.glCancelPolicyClick,
                pageName: PageNames.payment,
              });
            }}
            target="_blank"
            rel="noreferrer"
          >
            {strings.SEE_CANCELLATION}
          </Link>
        </PaymentHeader>
      )}

      {clientSecret && stripePromise && (
        <Elements options={options} stripe={stripePromise}>
          <CheckoutForm />
        </Elements>
      )}
    </Container>
  );
};

export default AddPaymentMethod;
