/* eslint-disable no-debugger */
import { useState } from 'react';

import { AxiosResponse } from 'axios';

export const useApi = (
  apiRequest: (...args: any) => Promise<AxiosResponse>,
) => {
  const [statusMessage, setStatusMessage] = useState<any>();
  const [successResponse, setSuccessResponse] = useState<any>();
  const [responseMessage, setResponseMessage] = useState<any>();
  const [data, setData] = useState<any>();
  const [statusCode, setStatusCode] = useState<any>();
  const [errorString, setErrorString] = useState<string>('');
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const request = async (...args: any) => {
    setLoading(true);
    setError(false);
    setErrorString('');
    setData(null);
    setStatusMessage(null);
    setResponseMessage(null);
    const response = await apiRequest(...args);
    try {
      if (response?.data?.status === 'failed') {
        setError(true);
        setStatusMessage(response?.data?.status);
        setErrorString(response?.data?.because);
        setLoading(false);
        setSuccessResponse(false);
        return null;
      }
      setError(false);
      setData(response.data);
      setSuccessResponse(response?.data.status === 'succeeded');
    } catch (err: any) {
      setError(true);
      setStatusMessage(err?.response?.data?.status);
      setLoading(false);
      setErrorString(err?.response?.data?.because);
      setStatusCode(err?.response?.status);
      setSuccessResponse(false);
      return null;
    }
    setStatusMessage(response.data.status);
    setLoading(false);
    setData(response.data);
    setResponseMessage(response.data);
    setSuccessResponse(response.data.status === 'succeeded');
    return response?.data;
  };

  return {
    data,
    statusMessage,
    error,
    loading,
    request,
    statusCode,
    errorString,
    successResponse,
    responseMessage,
  };
};
