import styled from 'styled-components';

import theme from '../../utils/theme';

export const AppointmentInformation = styled.div`
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  @media (max-width: 900px) {
    flex-direction: column;
  }
`;

export const AppointmentOnHold = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  align-content: center;
  border: 0.75px solid #d3d2be;
  border-radius: 26px;
  height: 40px;
  max-width: 270px;
  min-width: 250px;
`;

export const AppointmentPlaceholder = styled.p`
  margin-right: 10px;
  font-family: ${theme.fonts.GintoRegular};
  font-weight: 400;
  font-size: 14px;
  line-height: 140.79%;
  letter-spacing: 1.15px;
  margin-bottom: 0px;
`;

export const AppointmentTimer = styled.p`
  font-family: ${theme.fonts.GintoRegular};
  font-weight: 400;
  font-size: 14px;
  line-height: 140.79%;
  letter-spacing: 1.15px;
  color: ${theme.templateColors.pink2};
  margin-bottom: 0px;
`;

export const AppointmentTypeBox = styled.div`
  align-content: center;
  justify-content: center;
  margin-bottom: 10%;

  @media (max-width: 900px) {
    margin-top: 6%;
  }
`;
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
  height: 100%;
  padding: 5% 0px 10% 3%;
  align-content: center;
  justify-content: center;
  @media (max-width: 900px) {
    height: fit-content;
    padding: 0% 20px 15% 20px;
    width: 100%;
  }
`;
export const DateAndTimeBox = styled.div`
  display: flex;
  flex-direction: column;
`;
export const Description = styled.div`
  font-family: ${theme.fonts.GintoRegular};
  font-weight: 400;
  font-size: 14px;
  line-height: 140.79%;
  letter-spacing: 1.15px;
  margin-bottom: 0px;
  margin-top: 2px;

  @media (max-width: 900px) {
    font-size: 14px;
    margin-left: -4px;
  }
`;
export const DescriptionBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 2px;
  @media (max-width: 900px) {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }
`;
export const Header = styled.div``;

export const HeaderIcon = styled.img`
  width: 22px;
  height: 22px;
  margin-right: 10px;
  @media (max-width: 900px) {
    width: 18px;
    height: 18px;
    margin-right: 18px;
  }
`;

export const IconAndTitleBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  @media (max-width: 900px) {
    width: 100%;
  }
`;

export const IconAndTitleBoxLocation = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  @media (max-width: 900px) {
    margin-bottom: 3%;
  }
`;

export const LeftBox = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  width: 60%;
  height: 100%;
  @media (max-width: 900px) {
    flex-direction: column;
    width: 100%;
  }
`;

export const Location = styled.p`
  margin-right: 7px;
  margin-bottom: 0px;
  font-family: ${theme.fonts.GintoBold};
  font-weight: 800;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 1.15px;

  @media (max-width: 900px) {
    font-size: 16px;
    margin-left: -4%;
  }
`;

export const LocationBox = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 4%;

  @media (max-width: 900px) {
    margin-bottom: 8%;
    align-items: left;
  }
`;

export const PhoneDetail = styled.div`
  font-family: ${theme.fonts.GintoLight};
  font-size: 12px;
  line-height: 17px;
  letter-spacing: 1.15px;
  color: ${theme.colors.black};
  margin-top: 11px;
`;

export const ProgressBarContainer = styled.div`
  margin-top: 4%;
  margin-bottom: 4%;
`;

export const RightBox = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  width: 40%;
  height: 100%;

  @media (max-width: 900px) {
    flex-direction: column;
    width: 100%;
    margin-top: 13px;
    margin-bottom: 13px;
  }
`;

export const StyledBigContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 30px;
  @media (max-width: 900px) {
    flex-direction: column;
  }
`;

export const StyledColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  margin-left: 45px;
  @media (max-width: 900px) {
    width: 100%;
    margin-left: 0;
  }
`;

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  @media (max-width: 1578px) {
    flex-direction: column;
  }
`;

export const StyledError = styled.div`
  background-color: ${theme.templateColors.pink2};
  border-radius: 4px;
  font-family: ${theme.fonts.GintoLight};
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 1.15px;
  color: ${theme.colors.black};
  margin-top: 11px;
  padding: 6px 9px 6px 9px;
`;

export const StyledLeftColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  margin-right: 45px;
  @media (max-width: 900px) {
    width: 100%;
    margin-right: 0;
  }
`;

export const TermsAndConditions = styled.div`
  font-family: ${theme.fonts.GintoLight};
  font-size: 12px;
  line-height: 17px;
  letter-spacing: 1.15px;
  color: ${theme.colors.black};
  margin-top: 11px;
  text-decoration: underline;
  margin-left: 5px;
  cursor: pointer;
  @media (max-width: 1578px) {
    margin-left: 0;
  }
`;

export const Title = styled.div`
  font-family: ${theme.fonts.GintoBold};
  font-size: 18px;
  line-height: 18px;
  color: ${theme.colors.black};
  letter-spacing: 1.15px;
  width: 100%;
`;

export const TitleBox = styled.p`
  margin-bottom: 13px;
  font-size: 12px;
  line-height: 140.79%;
  letter-spacing: 1.15px;
  text-transform: uppercase;
  margin-bottom: 2%;
  font-family: ${theme.fonts.GothamThin};
  font-weight: 700;
`;
