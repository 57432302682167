import React from 'react';

import { StyledSlider } from './styles';

interface ProgressBarProps {
  initialValue: number;
  onChange?: (v: number | Array<number>) => void;
  disabled?: boolean;
  minimumValue?: number;
  maximumValue?: number;
}

const ProgressBar = ({
  initialValue,
  onChange = () => null,
  disabled = true,
  minimumValue = 0,
  maximumValue = 80,
}: ProgressBarProps) => (
  <StyledSlider
    defaultValue={initialValue}
    disabled={disabled}
    onChange={(event, value) => onChange(value)}
    min={minimumValue}
    max={maximumValue}
  />
);

export default ProgressBar;
