import { apiClientV2 } from '../../api/client';
import api from '../../api/methods';
import store from '../../redux/store';
import { setLockApptResData } from '../../slices/appointmentSlice';
import {
  setAvailablesDates,
  setScheduleData,
} from '../../slices/scheduleSlice';
import { reshapeCalendarData, isIsoDate } from '../../utils/functions';

export const getScheduleData = async (params: any) => {
  try {
    const res = await apiClientV2.get('/schedule', { params });
    const scheduleData = res?.data?.with;
    const formattedForCalendar = reshapeCalendarData(scheduleData);
    store.dispatch(setScheduleData(scheduleData));
    store.dispatch(setAvailablesDates(formattedForCalendar));
    return res as any;
  } catch (err) {
    return err;
  }
};

export const lockAppointment = async (params: any) => {
  try {
    const res = await api.post('/appointments/lock', { ...params });
    const lockApptResponse = res?.data?.with;
    store.dispatch(setLockApptResData(lockApptResponse));
    return res as any;
  } catch (err) {
    console.error('lockAppointment() error: ', { err });
    throw err;
  }
};

export interface BookAppointmentParams {
  locationId: string; // e.g. '7000000000214';
  appointmentType: string; // e.g. 'NEW_PATIENT_ADULT';
  appointmentTime: string; // e.g. '2023-05-04T17:00:00.000Z'
  lockedAppointmentId?: string; // e.g. '7000000000214';
}
export interface BookAppointmentResponse {
  status: string;
  by: string;
  the: string;
  with: BookAppointmentResponseData;
}
export interface BookAppointmentResponseData {
  type: string;
  lastModified: string;
  duration: number;
  statusId: number;
  id: string;
  title: string;
  start: string;
  created: string;
  end: string;
  needsFollowUp: boolean;
  needsPremedicate: boolean;
  status: string;
  other: string;
  bookedOnline: boolean;
  bookingType: string;
  patient: Location;
  location: Location;
  provider: Location;
  operatory: Location;
}

export interface Location {
  id: string;
  type: string;
  url: string;
}

export const bookAppointment = async (params: BookAppointmentParams) => {
  try {
    const { accessToken } = store.getState().session.user;
    const headers = {
      Authorization: `Bearer ${accessToken}`,
    };
    const { appointmentTime } = params;
    if (!isIsoDate(appointmentTime))
      throw new Error('Invalid date provided to func: bookAppointment');
    const res = await api.post(
      '/appointments/book',
      {
        ...params,
      },
      { headers },
    );
    return res as any;
  } catch (err) {
    console.error('bookAppointment() error: ', { err });
    throw err;
  }
};
