import api from '../../api/methods';
import store from '../../redux/store';
import { setOTPToken } from '../../slices/loginSlice';

const { dispatch } = store;

const createVerify = async (params: any) => {
  try {
    const objToSend = {
      username: params?.phone,
      passcode: params?.enteredCode,
    };

    const res = await api.post('/user/create/verify', { ...objToSend });

    if (res?.data?.with) {
      const {
        issued_at: issuedAt,
        expires_in: expiresIn,
        access_token: accessToken,
        refresh_token: refreshToken,
      } = res.data.with;

      const tempUserTokenData = {
        issuedAt,
        expiresAt: Date.now() / 1000 + expiresIn,
        accessToken,
        refreshToken,
      };

      dispatch(setOTPToken(tempUserTokenData));
    }
    return res;
  } catch (error) {
    console.error('createVerify() error: ', { error });
    throw error;
  }
};

export default { createVerify };
