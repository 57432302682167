import styled from 'styled-components';

import theme from '../../utils/theme';

const { colors, templateColors } = theme;

export const BoldTitle = styled.p`
  font-family: ${theme.fonts.GintoBlack};
  letter-spacing: 1.15px;
  color: ${colors.black};
  font-size: 30px;
  display: flex;
  line-height: 140.79%;
  padding: 0px;
  @media (max-width: 767px) {
    font-size: 22px;
    width: 350px;
    margin: 0px;
  }
`;

export const ButtonsContainer = styled.div`
  width: 80%;
  height: 57px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 767px) {
    flex-direction: column;
    width: 100%;
    margin-top: 70px;
    margin-bottom: 43px;
  }
`;

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 148px 15% 170px 14%;
  @media (max-width: 767px) {
    padding: 55px 7% 115px 8%;
  }
`;

export const DateInputStyled = styled.input`
  width: 104px;
  text-align: left;
  color: ${colors.black};
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
  border-bottom: 1px solid ${colors.black};
  font-family: ${theme.fonts.DomineRegular};
  font-size: 14px;
  line-height: 20px;
  display: flex;
  letter-spacing: 1.15px;
  background: ${colors.cardTan};
  outline: none;
  margin: 10px 10px 0px 10px;
  @media (max-width: 767px) {
    width: 73%;
    margin-top: 100px;
    justify-self: center;
  }
`;

export const DateOfBirthForm = styled.div`
  display: flex;
  width: 426px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  @media (max-width: 767px) {
    justify-content: center;
    align-items: start;
    width: 300px;
    align-self: center;
  }
`;

export const DateOfBirthFormWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 72px;
  @media (max-width: 767px) {
    justify-content: center;
    align-items: start;
  }
`;

export const ErrorBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: 767px) {
    flex-direction: column;
    height: 100px;
    width: 200px;
  }
`;

export const ErrorString = styled.p`
  font-family: ${theme.fonts.DomineRegular};
  letter-spacing: 1.15px;
  color: ${templateColors.rfRed};
  font-weight: 500;
  font-size: 14px;
  line-height: 140.79%;
  position: absolute;
  margin-top: 70px;
  @media only screen and (min-width: 768px) and (max-width: 1024px) {
    /* CSS rules for tablet devices */
    margin-top: 15%;
  }

  @media (max-width: 767px) {
    font-size: 8px;
    margin-top: 160px;
    margin-left: 3%;
  }
`;

export const Header = styled.p`
  font-family: ${theme.fonts.GintoBold};
  text-align: center;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 1.15px;
  @media (max-width: 767px) {
    width: 100%;
    margin-bottom: 30px;
    font-size: 13px;
  }
`;

export const InputStyled = styled.input`
  width: 50%;
  color: ${colors.black};
  align-items: center;
  text-align: left;
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
  height: 30px;
  border-bottom: 1px solid ${colors.black};
  font-family: ${theme.fonts.DomineRegular};
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 1.15px;
  background: ${colors.cardTan};
  outline: none;
  @media (max-width: 767px) {
    width: 100%;
  }
`;

export const LastNameForm = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  margin-bottom: 58px;
  @media (max-width: 767px) {
    justify-content: center;
    align-items: start;
    margin-bottom: 25%;
  }
`;

export const ProblemsBox = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (max-width: 767px) {
    width: 100%;
    height: 100%;
    padding: 2%;
  }
`;

export const ProblemsBoxTitle = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  text-align: center;
  justify-content: center;
  margin-top: -10px;
  margin-bottom: 30px;
  @media (max-width: 767px) {
    justify-content: 'center';
    align-items: left;
    flex-direction: column;
  }
`;

export const ProblemsSubTitle = styled.h2`
  font-family: ${theme.fonts.GintoRegular};
  font-weight: 400;
  font-size: 18px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 1.15px;
  margin-top: 6%;
  &:after {
    content: ' 312-846-6752 ';
    text-decoration: underline;
    text-decoration-thickness: 1px;
  }
  @media (max-width: 767px) {
    font-size: 15px;
    margin: 0px;
  }
`;

export const ProblemsTitle = styled.p`
  font-family: ${theme.fonts.DomaineTextMedium};
  letter-spacing: 1.15px;
  color: ${colors.black};
  font-weight: 500;
  font-size: 30px;
  line-height: 42px;
  &:after {
    content: ' But no worries! ';
    font-family: ${theme.fonts.GintoBlack};
  }

  @media (max-width: 767px) {
    font-size: 20px;
    text-align: start;
    justify-content: center;
    margin: 0px;
  }
`;

export const ProblemsTitle2 = styled.p`
  font-family: ${theme.fonts.DomaineTextMedium};
  letter-spacing: 1.15px;
  color: ${colors.black};
  font-weight: 500;
  font-size: 30px;
  line-height: 42px;
  @media (max-width: 767px) {
    font-size: 20px;
    text-align: start;
    align-self: start;
    margin: 0px;
  }
`;

export const ProblemsTitlesWrapper = styled.div`
  display: flex;
  width: 85%;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 5%;

  @media (max-width: 767px) {
    width: 100%;
    padding: 2%;
    margin-bottom: 0px;
    justify-content: center;
    align-items: center;
  }
`;

export const Title = styled.p`
  font-family: ${theme.fonts.DomineRegular};
  letter-spacing: 1.15px;
  color: ${colors.black};
  font-size: 30px;
  line-height: 140.79%;
  margin-right: 10px;
  margin-top: 5px;
  padding-bottom: 10px;
  display: flex;
  @media (max-width: 767px) {
    font-size: 23px;
    width: 100%;
  }
  @media (max-width: 896px) {
    margin: 0;
    padding-bottom: 0;
  }
`;

export const TitleBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
  justify-content: center;
  @media (max-width: 767px) {
    justify-content: 'center';
    align-items: start;
    width: 335px;
    margin-top: 15px;
    margin-bottom: 0px;
  }
  @media (max-width: 896px) {
    flex-direction: column;
  }
`;
